import React from 'react';
import {bodyClick} from "../app/app";

const KyongKiClub = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">

                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Наш клуб</span>
                                            <span className="ru">Наш клуб</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src="client/src/components/pages/img/kyongki.png" alt="" style={{height: "200px"}}/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>Клуб KYONG-KI</h5><br/>
                                        История клуба "Kyong-Ki" начинается с 1 марта 2005 года. В городе Марганце в
                                        школе 11
                                        был сформирован клуб
                                        Таэквон-До "Kyong-Ki" (Познание энергии).<br/><br/>
                                        Миссия клуба: "Познание энергии - познание себя и Вселенной."<br/><br/>
                                        Цели: <br/>"Научиться самообороне без оружия; <br/>овладеть техникой
                                        Таэквон-до; <br/>занимаясь
                                        самопознанием, освоить работу с энергией,
                                        тем самым доводя свою технику Таэквон-До до новых уровней мастерства. <br/>Укрепив
                                        свой
                                        Дух, развиваться во всех направлениях
                                        своей жизни: здоровое тело, хорошие отношения с людьми, хорошее состояние
                                        финансов,
                                        благотворительность."<br/><br/>
                                        Слоган клуба: <br/>"Укрепляя тело - закаляем Дух".<br/><br/>
                                        Президентом клуба является <a target="_blank"
                                                                      href="http://itf-ua.org/UA/taekwon-do/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8--%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C--%D0%A3%D1%87%D0%B0%D1%81%D0%BD%D0%B8%D0%BA--4785--%D0%90%D1%80%D0%B1%D1%83%D0%B7%D0%BE%D0%B2.%D0%90%D0%BD%D0%B4%D1%80%D1%96%D0%B9.%D0%92%D0%BE%D0%BB%D0%BE%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87.php">Арбузов
                                        Андрей Владимирович</a> - обладатель черного пояса Таэквон-До ИТФ 3 дан,
                                        тренер национального класса, член президиума областной организации Таэквон-До
                                        ИТФ г. Днепр.<br/>
                                        Знания Андрей Владимирович перенимал от сердца к сердцу на семинарах у Гранд
                                        Мастера Хан Чоля. Хан Чоль был одним из первых учеников генерала Чой Хонг Хи.
                                        Старший Мастер-Инструктор Международного класса, судья Международной категории
                                        «A».<br/>
                                        Также, Андрей Владимирович проходил многолетнее обучение у Макарова Владислава
                                        Леонидовича - президента федерации Таеквон-до ИТФ в Днепровской области,
                                        обладателя черного пояса 7 дан,
                                        мастера-инструктора международного класса, судьи Международной категории
                                        «A».<br/><br/>
                                        <img className="img-content"
                                             src="client/src/components/pages/img/team/36045959_1676321195784627_4942721537594621952_n.jpg"/><br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default KyongKiClub;