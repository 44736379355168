import React from 'react';
import {bodyClick} from "../app/app";

const Blog = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page"></section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Цікава інформація Таеквон-До</span>
                                            <span className="ru">Интересная информация Таэквон-До</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-инь-янь-48.png")} alt=""/>
                                        <img src={require("./img/icons/icons8-украина-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>Интересная информация</h5><br/><br/><br/>

                                        <h5>Pro Taekwondo</h5><br/>
                                        <p><b>Pro Taekwondo</b></p>
                                        <iframe width="800" height="auto"
                                                src="https://player.vimeo.com/video/429219586"
                                                frameBorder="0" allowFullScreen mozallowfullscreen="true"
                                                webkitallowfullscreen="true"></iframe>
                                        <br/><br/>

                                        <h5>Гимн Taekwon-Do ITF</h5><br/>
                                        <div className="audio-holder">
                                            <audio className="audio" controls>
                                                <source src={require("./Audio/Taekwon-do ITF Hymne.mp3")}
                                                        type="audio/mp3"/>
                                            </audio>
                                        </div>

                                        <h5>Получение спортивных разрядов в Украине</h5><br/>

                                        Таеквондо (ІТФ)<br/><br/>
                                        Чоловіки та жінки<br/>
                                        Вікові категорії:<br/>
                                        юнаки: 11 - 13 років;<br/>
                                        юніори молодші: 14 - 15 років;<br/>
                                        юніори: 16 - 17 років;<br/>
                                        дорослі: 18 - 39 років.<br/>
                                        Посісти місця в одному з перерахованих змагань з урахуванням умов присвоєння
                                        спортивних
                                        звань:<br/>
                                        Майстер спорту України міжнародного класу<br/>
                                        1 - 2 - на чемпіонаті світу;<br/>
                                        1 - на чемпіонаті Європи;<br/>
                                        1 - у Кубку Європи.<br/>
                                        Майстер спорту України<br/>
                                        1 - 2 - на чемпіонаті світу серед юніорів;<br/>
                                        3 - на чемпіонаті світу;<br/>
                                        2 - на чемпіонаті Європи;<br/>
                                        1 - на чемпіонаті Європи серед юніорів;<br/>
                                        1 - 2 - на чемпіонаті України;<br/>
                                        1 - у Кубку України.<br/>
                                        Кандидат у майстри спорту України<br/>
                                        3 - 4 - на чемпіонаті України;<br/>
                                        2 - 3 - у Кубку України;<br/>
                                        1 - 2 - на чемпіонаті України серед юніорів.<br/>
                                        Перший розряд<br/>
                                        3 - на чемпіонаті України серед юніорів;<br/>
                                        1 - 2 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя;<br/>
                                        1 - 2 - на чемпіонаті України серед молодших юніорів.<br/>
                                        Другий розряд<br/>
                                        4 - на чемпіонаті України серед юніорів;<br/>
                                        1 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя
                                        серед
                                        юніорів;<br/>
                                        3 - на чемпіонаті України серед молодших юніорів;<br/>
                                        3 - 4 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя.<br/>
                                        Третій розряд<br/>
                                        2 - 3 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя
                                        серед юніорів;<br/>
                                        1 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя
                                        серед
                                        молодших юніорів.<br/>
                                        Перший юнацький розряд<br/>
                                        1 - 2 - на чемпіонаті України серед юнаків;<br/>
                                        2 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя
                                        серед
                                        молодших юніорів;<br/>
                                        3 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя
                                        серед
                                        юніорів.<br/>
                                        Другий юнацький розряд<br/>
                                        3 - 4 - на чемпіонатах областей, Автономної Республіки Крим, міст Києва та
                                        Севастополя
                                        серед молодших юніорів;<br/>
                                        3 - на чемпіонаті України серед юнаків.<br/>
                                        Третій юнацький розряд<br/>
                                        1 - на чемпіонатах районів серед юнаків.<br/>
                                        Умови присвоєння спортивних звань:<br/>
                                        1. Спортивне звання "Майстер спорту України міжнародного класу" присвоюється за
                                        умови
                                        участі:<br/>
                                        в індивідуальних видах програми спортсменів не менше ніж з 10 країн, у найважчій
                                        та
                                        найлегшій вагових категоріях - не менше ніж з 8 країн;<br/>
                                        у командних видах програми команд не менше ніж з 10 країн за умови одержання
                                        спортсменом
                                        не менше 2 перемог.<br/>
                                        2. Спортивне звання "Майстер спорту України" присвоюється за умови участі у виді
                                        програми на офіційних міжнародних змаганнях спортсменів:<br/>
                                        в індивідуальних видах програми спортсменів не менше ніж з 10 країн, у найважчій
                                        та
                                        найлегшій вагових категоріях - не менше ніж з 8 країн;<br/>
                                        у командних видах програми команд не менше ніж з 10 країн.<br/>
                                        2. Спортивне звання "Майстер спорту України" присвоюється за умови участі у виді
                                        програми в офіційних всеукраїнських змаганнях спортсменів:<br/>
                                        в індивідуальних видах програми спортсменів не менше ніж з 10 регіонів, у
                                        найважчій
                                        та
                                        найлегшій вагових категоріях - не менше ніж з 8 регіонів;<br/>
                                        у командних видах програми команд не менше ніж з 10 регіонів.
                                        <br/><br/><img src={require("./img/interesting_info/img_9487.jpg")}
                                                       className="img-content"/><br/><br/>
                                        <br/><br/>

                                        <h5>Тхыккон мусуль" (특공무술)</h5><br/>

                                        Тхыккон мусуль" (특공무술) - "Боевое искусство сил специального назначения".<br/>


                                        Инициатором реформирования подразделений южно-корейского спецназа в конце 1970-х
                                        годов стал непосредственно сам тогдашний президент Республики Корея Пак Чонхи.
                                        Обычно считается, что причиной создания стиля "Тхыккон мусуль" стало активное
                                        послевоенное противостояние диверсионных отрядов и спецслужб двух стран - КНДР и
                                        Южной Кореи. Во многом это действительно так - северяне на протяжении многих лет
                                        не оставляли своих попыток путём покушений и диверсий уничтожить
                                        высокопоставленных представителей Южной Кореи, в том числе и президента. К тому
                                        же Северная Корея на 10 с лишним лет раньше создала свой собственный
                                        специализированный стиль рукопашного боя для нужд армейского спецназа -
                                        "Кёксуль" (격술, "Искусство ударов"), который был весьма эффективным.<br/>


                                        Однако в корейских публикациях отмечается, что непосредственным поводом, из-за
                                        которого президент Пак Чонхи отдал приказ о реформировании спецподразделений,
                                        послужило событие октября 1977-го года, которое произошло весьма далеко от
                                        территории корейского полуострова. 13-го октября 1977-года группа вооружённых
                                        палестинских террористов захватила немецкий пассажирский самолёт авиакомпании
                                        Люфтганза, убила капитана лайнера, и вместе с пассажирами угнала его в Сомали, в
                                        город Могадишо. 86 пассажиров, пилоты и стюардессы были взяты в заложники.
                                        Операцию по освобождению заложников проводило немецкое спецподразделение по
                                        борьбе с терроризмом "ГСГ-9". Эта операция прошла почти идеально - террористы
                                        были уничтожены, одна террористка была захвачена живой. Никто из заложников и
                                        спецназовцев не погиб (легкие ранения получили один из спецназовцев, стюардесса
                                        и три пассажира).<br/>


                                        Это событие имело большой резонанс во всём мире, в том числе и в Южной Корее.
                                        Корейское правительство под влиянием этого случая поставило себе цель
                                        реформировать свои силы специального назначения и вывести их на качественно
                                        новый уровень, чтобы они соответствовали лучшим мировым стандартам того времени,
                                        в частности, уровню немецкого спецназа. Для этого было решено создать новое
                                        спецподразделение, внедрить в него новейшее техническое оснащение и вооружение,
                                        а также значительно улучшить специальную подготовку сотрудников. Естественно,
                                        рукопашной бой в этой реформе занимал не самое главное место, так как чаще всего
                                        не он определяет эффективность антитеррористических и антидиверсионных
                                        мероприятий. Но тем не менее, помимо прочих действий, была начата и разработка
                                        новой системы рукопашного боя - "Тхыккон мусуль" ("Боевого искусства сил
                                        специального назначения").<br/>


                                        Создание этой системы рукопашного боя началось в рамках образованного в июне
                                        1978-го года первого в истории Кореи специального антитеррористического отряда -
                                        "Подразделения № 606" (제606 부대), которое предполагалось использовать в том числе
                                        и для борьбы с терроризмом на воздушном транспорте. Возглавил это подразделение
                                        подполковник южно-корейской армии Ким Тхэксу (김택수), 1939-го года рождения,
                                        выпускник Военной академии Республики Корея. С июня 1978-го по май 1980-го года
                                        Ким Тхэксу непосредственно курировал разработку будущего стиля "Тхыккон мусуль"
                                        (тогда, на первоначальном этапе, этот стиль носил несколько иное название:
                                        "Тхыкчон мусуль" - "Специальное боевое искусство для военных"). Именно
                                        подполковнику Ким Тхэксу принадлежала идея привлечь к этой работе различных
                                        инструкторов из других стилей рукопашного боя - хапкидо, тхэквондо, дзюдо и т.
                                        д., чтобы затем на основе их коллективного опыта создать новую систему боя.<br/>


                                        Архивное фото: 1978-й год, бойцы первого в истории Кореи специального
                                        антитеррористического "Подразделения № 606", в котором была создана и
                                        апробирована система рукопашного боя "Тхыккон мусуль":

                                        <br/><br/><img
                                        src={require("./img/interesting_info/4443a15c011b1f4f3489736938792f78.jpg")}
                                        className="img-content"/><br/><br/>


                                        Первым из таких приглашённых инструкторов стал мастер хапкидо Чан Суок
                                        (장수옥), который уже с июля 1978-го года приступил к обучению сотрудников
                                        нового "Подразделения № 606". Поэтому сейчас в Южной Корее создателем стиля
                                        "Тхыккон мусуль" чаще всего считают именно его. Правда, на это же звание
                                        претендуют и ещё сразу несколько других корейских мастеров боевых искусств,
                                        которые действительно внесли весомый вклад в развитие этого стиля, но все
                                        они подключились к работе над "Тхыккон мусуль" только с начала 1980-х годов,
                                        то есть на два года позднее мастера Чан Суока. Об этом свидетельствуют и
                                        документальные источники тех лет, опубликованные в Корее недавно, и устные
                                        воспоминания непосредственных участников тех событий, в частности,
                                        воспоминания Ким Тхэксу. Так что первоначальные основы этого стиля заложил
                                        именно Чан Суок.<br/>


                                        Значительную помощь в создании стиля "Тхыккон мусуль" мастеру Чан Суоку
                                        оказывала его жена - госпожа Ким Танхва (김단화), которая с молодых лет также
                                        занималась боевыми искусствами, и стала широко известна в Корее под своим
                                        прозвищем "Девушка с железным веером" (Чхольсон Нё). После нескольких
                                        месяцев интенсивных тренировок, 16-го ноября 1978-го года бойцы
                                        "Подразделения № 606" были приглашены в резиденцию президента Южной Кореи (в
                                        так называемый "Синий Дом"), чтобы продемонстрировать некоторые достигнутые
                                        ими результаты. Считается, что это событие было первой публичной
                                        демонстрацией стиля "Тхыккон мусуль". Впоследствии президент Кореи Пак Чонхи
                                        лично встретился с мастером Чан Суоком и его супругой Ким Танхва и
                                        поблагодарил их за работу.<br/>


                                        Архивное фото: июнь 1979-го года, встреча президента Южной Кореи Пак Чонхи с
                                        мастером Чан Суоком и его супругой Ким Танхва, заложившими основы стиля
                                        "Тхыккон мусуль":<br/>

                                        <br/><br/><img
                                        src={require("./img/interesting_info/a6f2cdf977d99b3fd8e85c3869fef652.jpg")}
                                        className="img-content"/><br/><br/>


                                        В ноябре 1980-го года мастер Чан Суок был переведён на работу в Службу
                                        охраны президента, чтобы преподавать там среди сотрудников рукопашный
                                        бой. Здесь, в Службе охраны он проработал больше 20-ти лет, вплоть до
                                        ухода в отставку в марте 2002-го года.<br/>
                                        <br/><br/><img
                                        src={require("./img/interesting_info/67a99bac7b0186ea830091a12f92435f.jpg")}
                                        className="img-content"/><br/><br/>

                                        <br/>
                                        <br/>
                                        <p><b>TeukGong MooSool</b></p>
                                        <iframe width="800" height="auto"
                                                src="https://player.vimeo.com/video/366770850"
                                                frameBorder="0" allowFullScreen mozallowfullscreen="true"
                                                webkitallowfullscreen="true"></iframe>
                                        <br/>
                                        <br/>
                                        На волне быстрого роста популярности корейских
                                        боевых искусств во всём мире, этот стиль постепенно
                                        стал превращаться в очередной бизнес-проект. Но в
                                        конце 70-х - первой половине 80-х годов "Тхыккон
                                        мусуль" - это был практически закрытый стиль,
                                        которым занимались настоящие южно-корейские
                                        спецназовцы и военнослужащие воздушно-десантных
                                        войск. В последние годы в Южной Корее были
                                        опубликованы серьёзные исторические исследования на
                                        эту тему, основанные на значительном количестве
                                        документов (ранее засекреченных), свидетельствах
                                        высокопоставленных военных и других материалах.

                                        Многие люди, имевшие прямое отношение к созданию
                                        стиля "Тхыккон мусуль", были профессиональными
                                        кадровыми военными, офицерами. Например, известный
                                        мастер боевых искусств Лим Унхван (임웅환), который
                                        считается одним из соавторов этого стиля (наряду с
                                        Чан Суоком) был капитаном южно-корейской армии
                                        (сейчас полковник в отставке). С 1980-го года он
                                        служил в "27-м отряде специального назначения" (제27
                                        특공부대), где принимал непосредственное участие в
                                        работе над дальнейшим развитием и совершенствованием
                                        стиля "Тхыккон мусуль".

                                        Распространение "Тхыккон мусуль" в южно-корейских
                                        войсках ВДВ связано с именем известного ученика
                                        мастера Чан Суока - Пак Новона (박노원). В августе
                                        1979-го года, участвуя в одном из военных смотров,
                                        Пак Новон выступил там с демонстрацией недавно
                                        созданного стиля "Тхыккон мусуль" перед
                                        высокопоставленными представителями южно-корейской
                                        армии. Эта демонстрация произвела весьма большое
                                        впечатление на многих присутствующих, в том числе на
                                        командующего 5-й бригадой Воздушно-десантных войск
                                        Республики Корея генерала Чан Кио (장기오). По просьбе
                                        генерала Чан Кио, с 1980-го года Пак Новон начал
                                        проводить инструктажи по этому стилю среди
                                        десантников его бригады. А с января 1981-го года
                                        данный стиль с одобрения начальника штаба армии был
                                        официально принят в качестве основного метода
                                        физической тренировки личного состава 5-й бригады
                                        южно-корейских воздушно-десантных войск.

                                        Но с середины 1980-х годов отчётливо прослеживается
                                        тенденция к популяризации и оспортивливанию этого
                                        стиля, к стремлению сделать его как можно более
                                        массовым и коммерчески выгодным занятием. В мае
                                        1986-го года была создана первая в Корее спортивная
                                        "Федерация стиля Тхыккон мусуль", затем стали
                                        появляться и другие аналогичные организации, в том
                                        числе и международные. Были введены разделения
                                        спортсменов по данам и поясам, чего в изначальном,
                                        "спецназовском" варианте не было абсолютно. В 1988-м
                                        году массовое развитие этого стиля было официально
                                        одобрено и поддержано Министерством культуры и
                                        спорта Южной Кореи. Произошли существенные изменения
                                        и в технике стиля. Вместо ставки на простоту и
                                        эффективность в реальном поединке, акцент всё больше
                                        и больше стал смещаться на акробатическую сложность
                                        и эстетическую красоту движений (низкие стойки и т.
                                        п.). И в результате, мы сейчас имеем те "показушные"
                                        демонстрации, которые во всей красе можно наблюдать
                                        на множестве роликов Ютьюба. По всей видимости, к
                                        реальному боевому искусству южно-корейского спецназа
                                        нынешний массовый спортивно-коммерческий "Тхыккон
                                        мусуль" имеет весьма косвенное отношение.
                                        <br/><br/>
                                        <br/><br/>

                                        <br/><br/><h5>Кёксуль До (격술도)</h5><br/>

                                        <br/>
                                        <p><b>Kyoksuldo (격술도)</b></p>

                                        Кёксульдо (кор. 격술도, Gyeoksul,
                                        Kyuksuldo) — боевое искусство, созданное
                                        в Северной Корее, которое практикуется
                                        прежде всего в Корейской народной армии.
                                        Кексульдо было распространена также в
                                        Восточной Европе в государствах бывшего
                                        Варшавского пакта. В Корее кёксульдо
                                        стоит на вооружении спецподразделений и
                                        армии. Мировая федерация кёксульдо
                                        (세계실전격술도총본관) состоит из двух гражданских
                                        (не военизированных) тоджанов в Южной
                                        Корее. Один из тоджанов находится в
                                        Инчхон, второй — в городе Чхонан. В
                                        отличие от остальных
                                        коммерциализированных школ кёксуля,
                                        основной упор в этих школах делается на
                                        увеличение физической силы и
                                        выносливости тела. Современная форма
                                        одежды — военный камуфляж с нашивками
                                        школы кёксульдо или чёрная форма.<br/>
                                        Боевое искусство кёксульдо произошло от
                                        тхэккён и более старой, возникшей в 420
                                        г. боевой системы Субак. Боевое
                                        искусство разрабатывалось в 1926 при
                                        содействии Ким Ир Сена для борьбы против
                                        японцев.

                                        Кёксульдо совершенствовался для борьбы
                                        против более мускулистых, больших
                                        европейцев на войне Кореи 1950—1953.
                                        Дальнейшее развитие кёксульдо произошло
                                        в течение конфликта с Южной Кореей.
                                        Тогда появилась и усовершенствовалась
                                        одна из ручных техник — техника
                                        грунтовой воды.<br/>
                                        <iframe width="800" height="auto"
                                                src="https://player.vimeo.com/video/366770531"
                                                frameBorder="0" allowFullScreen
                                                mozallowfullscreen="true"
                                                webkitallowfullscreen="true"></iframe>
                                        <br/><br/><br/><br/>
                                        Гранд Мастер Хан Чоль<br/><br/>
                                        <br/><br/><img
                                        src={require("./img/interesting_info/x_efef92c8.jpg")}
                                        className="img-content"/><br/><br/>
                                        Родился 31-го мая 1959 года в
                                        КНДР.
                                        В 1974 году, в возрасте 15-и
                                        лет, начал изучать корейское
                                        традиционное воинское искусство
                                        КекСуль стоявшее в то время на
                                        вооружении северокорейской
                                        армии, оттачивая технику
                                        КекСуль, занимался им в армии до
                                        21 года до приезда в Северную
                                        Корею Генерала Чой Хонг Хи.
                                        В 1976 году закончил
                                        общеобразовательную школу.
                                        Окончил военно-политический
                                        институт имени Ким Чен Ира.
                                        Служил в засекреченных спец
                                        отрядах Северокорейской разведки
                                        «Ел Лак Со».
                                        Участвовал во многих
                                        засекреченных боевых операциях.
                                        Прослужил до звания
                                        подполковник.
                                        Офицер в запасе, подполковник.
                                        Трижды удостоился получить
                                        государственный Орден Первого
                                        уровня за боевые заслуги (этот
                                        Орден второй по значимости после
                                        медали Героя Северной Кореи и
                                        сравним с Орденом Славы в
                                        Советском Союзе).
                                        Когда в 1981 году приехал
                                        Основатель Таэквон-До ИТФ
                                        генерал Чой Хонг Хи в Северную
                                        Корею, стал одним из первых его
                                        учеников . Первыми учителями
                                        были ген. Чой Хонг Хи,
                                        гранд-мастера Лим Вон Суп и Пак
                                        Джунг Тэ. Участвовал во многих
                                        соревнованиях по различным видам
                                        единоборств. Является двукратным
                                        чемпионом Северной Кореи по
                                        Таэквон-До ИТФ.
                                        В 1983 году в составе лучших
                                        мастеров, участвовал в съёмках
                                        первого 15-и томного издания и
                                        первой видео-энциклопедии
                                        Таэквон-До ИТФ под руководством
                                        основателя Таэквон-До ген.Чой
                                        Хонг Хи.
                                        В составе демонстрационной
                                        команды, состоявшей из лучших
                                        инструкторов, участвовал в
                                        показательных выступлениях в
                                        Европе, Азии и на Африканском
                                        континенте. В 1989 году впервые
                                        демонстрировал Таэквон-До ИТФ в
                                        CCCР и в частности, в Украине,
                                        на стадионе “Динамо” в г.Киеве.
                                        После, был назначен
                                        руководителем Корейского
                                        инструкторского состава в
                                        странах СНГ, и непосредственно
                                        был закреплён за Украиной, где с
                                        1991 по 1996 и с 2002 по 2011
                                        год преподавал Таэквон-До ИТФ.
                                        Заслуженый тренер (Почётный
                                        инструктор Кореи). Обладатель
                                        VIII дана ИТФ. Тренировал
                                        Корейский инструкторский состав,
                                        а также был руководителем
                                        инструкторского отдела в
                                        Корейском Комитете Таэквон-До. В
                                        2009-ом, по возвращению домой,
                                        хотели выдвинуть в кандидаты на
                                        пост вице-президента Комитета
                                        Таэквон-До Кореи. Работал в
                                        таких странах как Китай,
                                        Пакистан, Россия, Испания,
                                        Югославия, Эстония, Белорусия,
                                        Украина и т.д. В 2010г.
                                        Комитетом ИТФ КНДР был
                                        представлен к высшему
                                        Мастерскому титулу Гранд Мастера
                                        IX Дана, но скоропостижная
                                        смерть не дала осуществится
                                        этому великому событию.

                                        25 августа 2011 года, внезапная
                                        смерть оборвала жизнь Гранд
                                        Мастера Хан Чоля.
                                        Мир Таэквон-До понёс большую
                                        невосполнимую утрату Великого
                                        учителя и наставника многих
                                        ведущих инструкторов и мастеров
                                        Таэквон-До ИТФ.


                                        {/*Grandmaster Han Chol

                                                                                        Born May 31, 1959 in DPRK.
                                                                                        In 1974, being 15 years old, he started to practice Kuk Sool, a traditional Korean martial art.
                                                                                        Graduated from public secondary school in 1976.
                                                                                        Graduated from Kim Il-Sung State Military and Politics Institute.
                                                                                        Served in North Korean special intelligence division “El Lak So”.
                                                                                        Took part in many secret military missions.
                                                                                        Reached the rank of Lieutenant Colonel before being retired.
                                                                                        Was 3 times awarded by the 1st Level State Order for Military Merit. This Order is a second most important award after the DPRK Hero Medal and can be compared to the USSR’s Order of Honour.
                                                                                        When Founder of Taekwondo ITF General Choi Hong Hi came to DPRK in 1981, Han Chol became one of his first apprentices. Amongst his first teachers were Grand Masters Lim Won Sup and Pak Jung Te.
                                                                                        Took part in numerous martial art competitions and twice became a Champion of DBRK in ITF Taekwondo.
                                                                                        In 1983, with other best Masters, participated in the creation of first 15-volume issue of Encyclopaedia of Taekwondo and first Video Encyclopaedia of Taekwondo.
                                                                                        As member of a demo team which consisted of best instructors, took part in demonstrations of Taekwondo in Europe, Asia and Africa. In 1989, presented Taekwondo ITF in Soviet Union and, in particular, in Kiev, Ukraine, on Dinamo stadium. After that has been assigned to a position of a Head of Korean coach team in CIS and was responsible for Ukraine. Taught Taekwondo to Ukraine’s leading ITF instructors from 1991 to 1996 and from 2002 to 2011. Created a world-level ITF school there.
                                                                                        Master Han Chol was a Honorable Instructor of Korea. He coached Korean instructors team and was a Chairman of Instructor Department in Korean Taekwondo Committee. In 2009 was proposed to a post of Vice-President of Korean Taekwondo Committee. Worked also in such countries as China, Pakistan, Russia, and Spain. Had VIII Dan of ITF.

                                                                                        August 25, 2011, the sudden death cut short the life of Grand Master Han Chol.
                                                                                        World Taekwondo has suffered a great loss a great teacher and mentor of many leading trainers and masters of Taekwondo ITF.*/}


                                        <br/><br/>Гранд Мастер Хан Чоль
                                        1983год.(Видео энциклопедия
                                        Таэквон-До. Демонстрация
                                        Туля на 3rd Dan —
                                        Yoo-Sin)<br/><br/>
                                        <iframe width="800"
                                                height="auto"
                                                src="https://player.vimeo.com/video/309509876"
                                                frameBorder="0"
                                                allowFullScreen
                                                mozallowfullscreen="true"
                                                webkitallowfullscreen="true"></iframe>
                                        <br/><br/>
                                        <iframe width="800"
                                                height="auto"
                                                src="https://player.vimeo.com/video/309509888"
                                                frameBorder="0"
                                                allowFullScreen
                                                mozallowfullscreen="true"
                                                webkitallowfullscreen="true"></iframe>
                                        <br/><br/>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Blog;