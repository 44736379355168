import React from 'react';
import {bodyClick} from "../app/app";

const Reabilitation = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Реабілітація</span>
                                            <span className="ru">Реабилитация</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-саквояж-врача-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                        {/*<h5>Енергетична та фізична реабілитація. Масаж. Обгортання.</h5>*/}
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>
                                        </h5>
                                        Приглашаем Вас на различные виды массажей для детей и взрослых. Виды массажа: лечебный
                                        массаж, общий массаж, массаж спины, тайский, тибетский, точечный, спортивный,
                                        антицеллюлитный, лимфодренажный, стоун терапия (массаж камнями), массаж лица, головы, а
                                        также спа процедуры - шоколадное обертывание и другие виды обертываний. Мастер массажа:
                                        Арбузов Андрей Владимирович, практикующий мастер по работе с энергией (цигун) с 2002
                                        года.
                                        <br/>
                                        <br/>
                                        <img className="img-content"
                                             src={require("./img/22405961_1185206651623925_142181738273199006_n.jpg")}
                                             style={{width: "50%"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Reabilitation;