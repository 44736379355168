import React, { Component } from 'react';
import { Link } from 'react-scroll';
import { bodyClick } from "../app/app";
import VideoFrame from './video-frame';

export default class TulsTaekwonDo extends Component {
    render() {
        return (
            <div onClick={bodyClick}>
                <allow-navigation href="https://*youtube.com/*"/>
                <section id="intro" className="intro menu-page">
                </section>
                <section id="service" className="home-section text-center bg-transparent">
                    <div className="heading-about">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-lg-offset-2">
                                    <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                        <div className="section-heading">
                                            <h2>
                                                <span className="ua">Тулі Таеквон-До</span>
                                                <span className="ru">Тули Таэквон-До</span>
                                            </h2>
                                            <i className="fa fa-2x fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-lg-offset-5">
                                <hr className="marginbot-50"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-main">
                                <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                    <div className="service-box">
                                        <div className="service-icon">
                                            <img src={require("./img/icons/icons8-тхэквондо-48.png")} alt=""/>
                                        </div>
                                        <div className="service-desc ua">
                                        </div>
                                        <div className="service-desc ru">
                                            <h5>ТУЛИ ТАЭКВОН-ДО</h5>

                                            <p className="tuls-list">
                                                <b>Ученические тули:</b>
                                                <b><Link to="Saju-Jirugi" smooth={true} offset={0} duration={1000}>Saju-Jirugi,</Link></b>
                                                <b><Link to="Saju-Makgi" smooth={true} offset={0} duration={1000}>Saju-Makgi</Link></b><br/>
                                                <b>Белый пояс с желтой нашивкой:</b>
                                                <b><Link to="Chon-Ji" smooth={true} offset={0} duration={1000}>Chon-Ji</Link></b><br/>
                                                <b>Желтый пояс:</b>
                                                <b><Link to="Dan-Gun" smooth={true} offset={0} duration={1000}>Dan-Gun</Link></b><br/>
                                                <b>Желтый пояс с зеленой нашивкой:</b>
                                                <b><Link to="Do-San" smooth={true} offset={0} duration={1000}>Do-San</Link></b><br/>
                                                <b>Зеленый пояс:</b>
                                                <b><Link to="Won-Hyo" smooth={true} offset={0} duration={1000}>Won-Hyo</Link></b><br/>
                                                <b>Зеленый пояс с синей нашивкой:</b>
                                                <b><Link to="Yul-Gok" smooth={true} offset={0} duration={1000}>Yul-Gok</Link></b><br/>
                                                <b>Синий пояс:</b>
                                                <b><Link to="Joong-Gun" smooth={true} offset={0} duration={1000}>Joong-Gun</Link></b><br/>
                                                <b>Синий пояс с красной нашивкой:</b>
                                                <b><Link to="Toi-Gye" smooth={true} offset={0} duration={1000}>Toi-Gye</Link></b><br/>
                                                <b>Красный пояс:</b>
                                                <b><Link to="Hwa-Rang" smooth={true} offset={0} duration={1000}>Hwa-Rang</Link></b><br/>
                                                <b>Красный пояс с черной нашивкой:</b>
                                                <b><Link to="Choong-Moo" smooth={true} offset={0} duration={1000}>Choong-Moo</Link></b><br/>
                                                <b>Черный пояс 1 Дан:</b>
                                                <b><Link to="Kwan-Gae" smooth={true} offset={0} duration={1000}>Kwan-Gae,</Link></b>
                                                <b><Link to="Po-Eun" smooth={true} offset={0} duration={1000}>Po-Eun,</Link></b>
                                                <b><Link to="Ge-Baek" smooth={true} offset={0} duration={1000}>Ge-Baek</Link></b><br/>
                                                <b>Черный пояс 2 Дан:</b>
                                                <b><Link to="Eui-Am" smooth={true} offset={0} duration={1000}>Eui-Am,</Link></b>
                                                <b><Link to="Choong-Jang" smooth={true} offset={0} duration={1000}>Choong-Jang,</Link></b>
                                                <b><Link to="Juche" smooth={true} offset={0} duration={1000}>Juche</Link></b><br/>
                                                <b>Черный пояс 3 Дан:</b>
                                                <b><Link to="Sam-Il" smooth={true} offset={0} duration={1000}>Sam-Il,</Link></b>
                                                <b><Link to="Yoo-Sin" smooth={true} offset={0} duration={1000}>Yoo-Sin,</Link></b>
                                                <b><Link to="Choi-Yong" smooth={true} offset={0} duration={1000}>Choi-Yong</Link></b><br/>
                                                <b>Черный пояс 4 Дан:</b>
                                                <b><Link to="Yong-Gae" smooth={true} offset={0} duration={1000}>Yong-Gae,</Link></b>
                                                <b><Link to="Ul-Ji" smooth={true} offset={0} duration={1000}>Ul-Ji,</Link></b>
                                                <b><Link to="Moon-Moo" smooth={true} offset={0} duration={1000}>Moon-Moo</Link></b><br/>
                                                <b>Черный пояс 5 Дан:</b>
                                                <b><Link to="So-San" smooth={true} offset={0} duration={1000}>So-San,</Link></b>
                                                <b><Link to="Se-Jong" smooth={true} offset={0} duration={1000}>Se-Jong</Link></b><br/>
                                                <b>Черный пояс 6 Дан:</b>
                                                <b><Link to="Tong-Il" smooth={true} offset={0} duration={1000}>Tong-Il</Link></b>
                                            </p>
                                            <br/>
                                            <p><a className="tul-with-margin" name="Saju-Jirugi"></a><b>Saju-Jirugi</b></p>
                                            <p>Первый ученический туль</p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/g4KvGfdJf7Q"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461857"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419249"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin" name="Saju-Makgi"></a><b>Saju-Makgi</b></p>
                                            <p>Второй ученический туль</p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/04E99-8Z_gI"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285305"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419261"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin" name="Chon-Ji"></a><b>Chon-Ji</b></p>
                                            <p>Именно с этого туля новичок начинает познание всех остальных.
                                                Чон-Чжи состоит из двух частей — символических Земли и Небес, что
                                                означает создание Земли и появление людей, их истории.
                                                С корейского языка переводится как Рай Земной.
                                                Туль состоит из 19 движений.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/9PTYSrCa74s"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461888"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419213"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin" name="Dan-Gun"></a><b>Dan-Gun</b></p>
                                            <p>Этот туль носит имя легендарного основателя Кореи Дан-Гуна.
                                                Согласно мифологии, в 2333 г. до н.э. Дан-Гун, сын медведицы,
                                                которая обернулась женщиной, и небожителя, основал государство
                                                Чосон, которое позже стали называть Древним Чосоном.
                                                Туль Дан-Гун состоит из 21 движения.
                                            </p>
                                            {/*<VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/-qfceax_kGk"}/>*/}
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/372069441"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461910"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419219"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin" name="Do-San"></a><b>Do-San</b>
                                            </p>
                                            <p>До-Сан — псевдоним просветителя, патриота, борца Ахн Чанг-Хо
                                                (1876-1938). 24 года из 62-х прожитых лет он посвятил своей
                                                стране.
                                                Сначала До-Сан занимался просвещением корейского народа. В это
                                                время Корея находилась под колониальным правлением Японии, что
                                                не устраивало корейцев. Многие примыкали к движению за
                                                освобождение страны от такого несправедливого правления. В их
                                                числе был и До-Сан.
                                                Туль До-Сан состоит из 24 движений — именно столько лет посвятил
                                                Ахи Чанг-Хо своей стране.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/sgXtxB4Npp4"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461846"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419227"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Won-Hyo"></a><b>Won-Hyo</b></p>
                                            <p>Туль получил своё название в честь Вонхё (617-686) —
                                                аристократа, который стал монахом, наставником. Он простым
                                                языком объяснял буддизм обычным людям.
                                                В этот период три корейских царства объединились в единое
                                                государство Силла. Именно Вонхё помог народу будущей Кореи
                                                понять и принять различные ветви новой религии. Вонхё описал
                                                все существовавшие в Силле буддистские течения, сделал
                                                комментарии ко многим текстам и сутрам буддизма.
                                                Туль Вон-Хё состоит из 28 движений.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/393253984"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285328"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419270"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Yul-Gok"></a><b>Yul-Gok</b></p>
                                            <p>Этот туль получил своё название в честь писателя и
                                                философа, наставника и последователя идей конфуцианства
                                                Йула (в другой транскрипции И-и), который жил в
                                                1536-1584 гг.
                                                Известен он под псевдонимом Юль-Гок, также его называли
                                                Корейским Конфуцием.
                                                Туль Юль-Гок состоит из 38 движений, что является
                                                символом места, где родился философ — географическая
                                                параллель 38.
                                                Перемещения в туле составляют иероглиф, который в
                                                корейском языке соответствует слову «учитель».
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/R98FzFD-6KU"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461667"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419281"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Joong-Gun"></a><b>Joong-Gun</b></p>
                                            <p>Этот туль получил своё название в честь корейского
                                                националиста, борца за свободу Кореи от Японии,
                                                которого наградили посмертно спустя 50 лет после его
                                                казни, Ан Чжун Гуна (Джунгуна, Чунгына, Чжунгына,
                                                Ынчхиля) (1879-1910). Он убил Ито Хиробуми — первого
                                                человека в Тайном Совете Японии, бывшего
                                                генерал-резидента.
                                                Туль Чжун-Гун состоит из 32 движений. Количество
                                                движений символизирует возраст патриота во время его
                                                казни (1910 год).
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/JW1LLK1PFqQ"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461684"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419238"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Toi-Gye"></a><b>Toi-Gye</b></p>
                                            <p>Туль получил своё название в честь Ли Хвана
                                                (1501-1570) — корейского философа, писателя,
                                                поэта.
                                                Ли Хван занимался серьёзной разработкой теории
                                                сунского конфуцианства. Он творил под
                                                псевдонимом Тхвеге и Тхведоон, упрощённо Тэ-Ге
                                                (Тхе-Ге).
                                                Схема передвижений представляет собой
                                                символическое обозначение корейского иероглифа,
                                                который переводится как «учитель».
                                                Туль Тэ-Ге состоит из 37 движений, что является
                                                намёком на место рождения Тхвеге —
                                                географическую параллель 37.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/J_m55ETkcwU"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285318"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Hwa-Rang"></a><b>Hwa-Rang</b></p>
                                            <p>Этот туль получил своё название в честь
                                                молодёжного движения хварангов.
                                                В VI в. на территории государства Силла
                                                появились учебно-тренировочные лагеря, куда
                                                поступали, в основном, юноши от 15 до 18
                                                лет. Они учились воинской доблести, изучали
                                                конфуцианство и литературу, путешествовали
                                                по стране в поисках живописных мест для
                                                медитаций и гармонии, также хваранги
                                                помогали простым людям.
                                                Хваранги были своеобразной гвардией, во
                                                время войн они всегда первыми встречали
                                                врагов.
                                                Туль Хва-Ранг состоит из 29 движений. Именно
                                                в 29-м воинском подразделении таэквондо
                                                превратилось в современное боевое искусство.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/dGFJxnKXqwg"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285594"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Choong-Moo"></a><b>Choong-Moo</b>
                                            </p>
                                            <p>Название этого туля означает «верный
                                                военачальник» - Чхунмугон — таким
                                                образом Корелевский двор Кореи оценил
                                                службу Ли Сунсина (1545-1598) —
                                                величайшего адмирала, который не
                                                проиграл ни одного водного сражения.
                                                Ли Сунсин изобрёл броненосный
                                                кораль-черепаху — кобуксон. Именно
                                                кобуксон стал прообразом подводных
                                                лодок. Ли Сунсин был не только
                                                талантливым адмиралом, но и народным
                                                героем, который заботился о людях и
                                                боролся с коррупцией.
                                                Туль Чунг-Му состоит из 30 движений.
                                                Заканчивается он резким ударом левой
                                                рукой — символом внезапной смерти
                                                адмирала от шальной пули.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/vdgHjG0fDL8"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461897"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Kwan-Gae"></a><b>Kwan-Gae</b>
                                            </p>
                                            <p>Туль носит имя Квангэтхо из династии
                                                Когурё (374-413 гг.). Он был 19-м
                                                королём. Эта династия правила
                                                одноимённым государством на
                                                Корейском полуострове, где было ещё
                                                две страны.
                                                Имя переводится так: «Ван (король),
                                                расширяющий земли», часто его
                                                называют Великим. Именно Квангэтхо
                                                вернул своему государству ранее
                                                утраченные земли, таким образом
                                                расширив его.
                                                На последнем году жизни Квангэтхо
                                                Когурё включало в себя: современную
                                                Северную Корею, Маньчжурию, часть
                                                Внутренней Монголии и Приморского
                                                края.
                                                Туль Кван-Ге состоит из 39 движений,
                                                что символизирует возраст Квангэтхо
                                                и первые две из трёх цифер даты,
                                                когда он начал править своим народом
                                                (391 г.).
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/AxouiM4sVLo"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285625"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a className="tul-with-margin"
                                                  name="Po-Eun"></a><b>Po-Eun</b>
                                            </p>
                                            <p>Этот туль назван в честь
                                                философа, приверженца
                                                неоконфуцианства (школа
                                                саллимхакпха — школа гор и
                                                лесов), и поэта Чон Мон Чжу
                                                (1337-1392), который творил под
                                                псевдонимом Пхоын.
                                                Ему принадлежат слова, известные
                                                каждому жителю Кореи: «Я не буду
                                                служить новому хозяину, пусть
                                                даже меня казнят сто раз».
                                                Пхоын был против того, чтобы
                                                крупные землевладельцы
                                                захватывали крестьянские наделы.
                                                Чон Мон Чжу был учёным (доктор
                                                наук в области физики) и
                                                политиком (высокая должность при
                                                династии Корё).
                                                Количество движений в туле По-Ун
                                                - 36. Узор туля символизирует
                                                патриотизм и преданность королю.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/poePnNUJ3zo"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/308461920"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Ge-Baek"></a><b>Ge-Baek</b>
                                            </p>
                                            <p>Туль носит имя генерала
                                                Хэбека (Гебека), который жил
                                                во времена династии Пэкче
                                                (18 г.до н.э. - 660 г.
                                                н.э.). Пэкче — одно из трёх
                                                государств на территории
                                                Кореи, которое существовало
                                                в древности. Два других —
                                                Силла и Когурё.
                                                Рисунок туля символизирует
                                                суровость военной
                                                дисциплины, строгость и
                                                взыскательность к воинам.
                                                Туль Ге-Бек состоит из 44-х
                                                движений.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/ty1sqfddTL4"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285579"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Eui-Am"></a><b>Eui-Am</b>
                                            </p>
                                            <p>Этот туль получил своё
                                                название по псевдониму
                                                Сон Бёнхи (1861-1922).
                                                Он являлся одним из
                                                вождей Тонхака (учение
                                                против японцев), одним
                                                из организаторов
                                                революционного движения
                                                1-го марта (1 марта 1919
                                                г.), разрабатывал
                                                положения декларации
                                                независимости.
                                                В 1905 г. он сменил
                                                наименование учения
                                                Тонхак на Чхондогё
                                                (учение Небесного пути).
                                                Это религиозное учение
                                                распространилось по всей
                                                Корее, приобретя
                                                масштабы национального.
                                                В Чхондогё гармонично
                                                соединились буддизм,
                                                христианство, даосизм и
                                                конфуцианство.
                                                Туль Ый-Ам состоит из 45
                                                движений. Количество
                                                движений соответствует
                                                его возрасту, когда он в
                                                1905 году сменил свое
                                                имя с Данг Хэк
                                                («Восточная культура»)
                                                на имя Чандо Кйо
                                                («Небесная религия»).
                                                Рисунок туля является
                                                символом несгибаемости
                                                духа Сона Бёнхи. Вся его
                                                жизнь была посвящена
                                                борьбе за независимость
                                                и процветание Кореи.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/ecBmaOfrQmg"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309286145"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Choong-Jang"></a><b>Choong-Jang</b>
                                            </p>
                                            <p>Этот туль получил
                                                своё наименование в
                                                честь генерала Ким
                                                Док Рёна, который
                                                жил в XIV веке.
                                                Генерал был известен
                                                под псевдонимом
                                                Чун-Чжан.
                                                Ким Док Рён был
                                                весьма талантливым
                                                полководцем, но он
                                                рано ушёл из этой
                                                жизни — в 27 лет.
                                                Ранняя смерть
                                                помешала ему достичь
                                                высочайшей точки
                                                развития.
                                                Туль Чун-Чжан
                                                состоит из 52-х
                                                движений.
                                                Завершающий удар
                                                левой рукой является
                                                символом
                                                скоропостижной
                                                смерти генерала.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/33SAL7Kg1wY"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309286124"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Juche"></a><b>Juche</b>
                                            </p>
                                            <p>Чучхе — слово,
                                                относящееся к
                                                ханмунным (то
                                                есть китайские
                                                иероглифы,
                                                прочитанные
                                                корейцами в
                                                контексте их
                                                письменности).
                                                Переводится так:
                                                «чу» - хозяин,
                                                «чхе» - природа,
                                                тело. Целиком
                                                это слово
                                                заключает такую
                                                философскую
                                                идею: человек и
                                                есть хозяин не
                                                только
                                                себя и своей
                                                судьбы, но и
                                                всего, что его
                                                окружает, в том
                                                числе и природы.
                                                Диаграмма
                                                передвижений
                                                символизирует
                                                гору Баэкду, на
                                                которой возникла
                                                эта идея. 45
                                                движений.
                                            </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/P4FaDqito_0"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309286162"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Sam-Il"></a><b>Sam-Il</b>
                                            </p>
                                            <p>Туль назван в
                                                честь
                                                исторической
                                                даты -
                                                начала
                                                движения за
                                                независимость
                                                Кореи (1
                                                марта 1919
                                                г.).
                                                Включение в
                                                туль 33
                                                двигательных
                                                действия
                                                символизирует
                                                память о 33
                                                патриотах,
                                                возглавивших
                                                это
                                                движение.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/dYY9wirk9yY"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/316419321"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285885"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Yoo-Sin"></a><b>Yoo-Sin</b>
                                            </p>
                                            <p>Туль
                                                назван в
                                                честь
                                                жившего
                                                во
                                                времена
                                                династии
                                                Силла
                                                генерала
                                                Ким Ю
                                                Сина.
                                                Включение
                                                в туль
                                                68
                                                двигательных
                                                действий
                                                символизирует
                                                последние
                                                две
                                                цифры из
                                                года
                                                объединенной
                                                Кореи
                                                (668 г.
                                                до
                                                н.э.).
                                                Исходное
                                                положение
                                                имитирует
                                                удержание
                                                рукой
                                                меча,
                                                располагающегося
                                                на
                                                правом,
                                                а не
                                                на
                                                левом,
                                                как это
                                                принято,
                                                боку -
                                                символ
                                                того,
                                                что
                                                генерал
                                                подчинился
                                                приказу
                                                короля и
                                                сражался
                                                против
                                                своего
                                                народа.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/wy-smRRpIYM"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285978"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p><a
                                                className="tul-with-margin"
                                                name="Choi-Yong"></a><b>Choi-Yong</b>
                                            </p>
                                            <p>Туль
                                                назван
                                                в
                                                честь
                                                жившего
                                                во
                                                времена
                                                династии
                                                Корйо
                                                главнокомандующего
                                                армией
                                                генерала
                                                Чой
                                                Йонга,
                                                снискавшего
                                                уважение
                                                за
                                                преданность,
                                                патриотизм
                                                и
                                                гуманизм.
                                                Генерал
                                                был
                                                убит
                                                членом
                                                своего
                                                ближайшего
                                                окружения
                                                -
                                                генералом
                                                Юи
                                                Сует
                                                Гэ,
                                                позднее
                                                ставшим
                                                первым
                                                королем
                                                династии
                                                Ли.
                                                46
                                                движений. </p>
                                            <VideoFrame className={'video_youtube'} src={"https://www.youtube.com/embed/GihM-SVniJ0"}/>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309286112"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p>
                                                <a className="tul-with-margin"
                                                   name="Yong-Gae"></a><b>Yong-Gae</b>
                                            </p>
                                            <p>Туль
                                                назван
                                                в
                                                честь
                                                жившего
                                                во
                                                времена
                                                правления
                                                династии
                                                Когурйо
                                                генерала
                                                Йэн
                                                Гэ
                                                Со-Мун.
                                                Включение
                                                в
                                                туль
                                                49
                                                двигательных
                                                действий
                                                символизирует
                                                последние
                                                две
                                                цифры
                                                из
                                                года,
                                                когда
                                                династия
                                                Танг
                                                была
                                                вынуждена
                                                покинуть
                                                Корею
                                                после
                                                того,
                                                как
                                                в
                                                битве
                                                под
                                                Лиси
                                                Санг
                                                (649
                                                г.)
                                                потери
                                                составили
                                                около
                                                300
                                                тысяч
                                                солдат.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285967"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p>
                                                <a className="tul-with-margin"
                                                   name="Ul-Ji"></a><b>Ul-Ji</b>
                                            </p>
                                            <p>Туль
                                                назван
                                                в
                                                честь
                                                генерала
                                                Ул
                                                Зи
                                                Мук
                                                Дока,
                                                успешно
                                                защитившего
                                                в
                                                612
                                                г.
                                                Корею
                                                от
                                                вторжения
                                                миллионной
                                                армии
                                                династии
                                                Танг.
                                                Генералом
                                                использовалась
                                                тактика
                                                нанесения
                                                ударов
                                                с
                                                последующим
                                                быстрым
                                                отходом.
                                                Эта
                                                тактика
                                                позволила
                                                рассеять
                                                огромные
                                                силы
                                                врага.
                                                Диаграмма
                                                движений
                                                в
                                                туль
                                                символизирует
                                                подпись
                                                генерала.
                                                Включение
                                                в
                                                туль
                                                42
                                                двигательных
                                                действия
                                                символизирует
                                                возраст
                                                автора
                                                -
                                                создателя
                                                современного
                                                таэквон-до
                                                Чой
                                                Хонг
                                                Хи,
                                                в
                                                котором
                                                он
                                                изобрел
                                                этот
                                                туль.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285951"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p>
                                                <a className="tul-with-margin"
                                                   name="Moon-Moo"></a><b>Moon-Moo</b>
                                            </p>
                                            <p>Туль
                                                назван
                                                в
                                                честь
                                                ХХХ-го
                                                короля
                                                династии
                                                Силла.
                                                Его
                                                тело
                                                похоронено
                                                у
                                                подножия
                                                Великой
                                                Королевской
                                                Скалы.
                                                Король
                                                завещал
                                                похоронить
                                                его
                                                в
                                                море,
                                                чтобы
                                                его
                                                душа
                                                защищала
                                                Корею
                                                от
                                                морских
                                                вторжений
                                                японцев.
                                                Специальный
                                                каменный
                                                саркофаг,
                                                являющийся
                                                и
                                                по
                                                сей
                                                день
                                                памятником
                                                культуры,
                                                был
                                                сделан,
                                                чтобы
                                                защитить
                                                его
                                                гроб.
                                                Включение
                                                в
                                                туль
                                                61
                                                двигательного
                                                действия
                                                символизирует
                                                последние
                                                две
                                                цифры
                                                из
                                                года
                                                начала
                                                царствования
                                                короля
                                                (661
                                                г.).</p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285867"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p>
                                                <a className="tul-with-margin"
                                                   name="So-San"></a><b>So-San</b>
                                            </p>
                                            <p>Название
                                                туль
                                                является
                                                псевдонимом
                                                монаха
                                                Чой
                                                Йонг
                                                Унга
                                                (1520-1604
                                                гг.).
                                                Включенные
                                                в
                                                туль
                                                72
                                                двигательных
                                                действия
                                                символизируют
                                                возраст
                                                монаха,
                                                в
                                                котором
                                                он
                                                совместно
                                                со
                                                своими
                                                сподвижниками
                                                из
                                                Са
                                                Мюнг
                                                Данга
                                                организовал
                                                корпус
                                                монахов-воинов.
                                                Эти
                                                воины
                                                противостояли
                                                японским
                                                пиратам,
                                                часто
                                                совершавшим
                                                набеги
                                                на
                                                Корейский
                                                полуостров.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285909"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p>
                                                <a className="tul-with-margin"
                                                   name="Se-Jong"></a><b>Se-Jong</b>
                                            </p>
                                            <p>Туль
                                                назван
                                                в
                                                честь
                                                величайшего
                                                из
                                                корейских
                                                королей
                                                Сэ
                                                Зонга
                                                -
                                                изобретателя
                                                корейского
                                                алфавита
                                                (в
                                                1443
                                                г.),
                                                известного
                                                метеоролога.
                                                Диаграмма
                                                движений
                                                символизирует
                                                слово
                                                "король".
                                                Включение
                                                в
                                                туль
                                                24
                                                двигательных
                                                действия
                                                символизирует
                                                число
                                                букв
                                                в
                                                корейском
                                                алфавите.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309286183"}/>
                                            <br/><br/><br/><br/><br/>
                                            <p>
                                                <a className="tul-with-margin"
                                                   name="Tong-Il"></a><b>Tong-Il</b>
                                            </p>
                                            <p>56
                                                движений.
                                                Туль
                                                назван
                                                в
                                                честь
                                                идеи
                                                объединения
                                                Северной
                                                и
                                                Южной
                                                Кореи
                                                в
                                                единое
                                                государство,
                                                разделение
                                                которого
                                                произошло
                                                в
                                                1945
                                                г.
                                                Диаграмма
                                                движений
                                                в
                                                туль
                                                символизирует
                                                гармоничную
                                                расу.</p>
                                            <VideoFrame className={'video_youtube'} src={"https://player.vimeo.com/video/309285927"}/>
                                            <br/><br/><br/><br/><br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
};