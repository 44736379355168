import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bodyClick, dropdownOpen, handlerClick, handleScroll, mobileMenuToggle } from '../app/app';

export default class Navigation extends Component {
    componentDidMount = () => {
        window.addEventListener('scroll', handleScroll);
    };
    componentWillUnmount = () => {
        window.removeEventListener('scroll', handleScroll);
    };
    render() {
        return (
            <div className="navbar-wrapper" onClick={bodyClick}>
                <nav className="navbar navbar-custom navbar-fixed-top" role="navigation">
                    <div className="container">
                        <div className="navbar-header page-scroll" >
                            <button onClick={() => mobileMenuToggle()} type="button" className="navbar-toggle" data-toggle="collapse"
                                    data-target=".navbar-main-collapse">
                                <i className="fa fa-bars"/>
                            </button>
                            <Link to='/' data-state="page-top" className="navbar-brand" onClick={handlerClick}>
                                <div className="navbar-logo"/>
                                <h1>Kyong-Ki club<br/>태권도</h1>
                            </Link>
                        </div>
                        <div className="collapse navbar-collapse navbar-right navbar-main-collapse">
                            <ul className="nav navbar-nav">
                                <li className="active" onClick={dropdownOpen}>
                                    <Link to="/" data-state="page-top" onClick={handlerClick}>
                                        <span className="ua">Головна</span>
                                        <span className="ru">Главная</span>
                                    </Link>
                                </li>
                                <li className="dropdown">
                                    <a onClick={dropdownOpen} className="dropdown-toggle"
                                       data-toggle="dropdown"><span
                                        className="ua">Kyong-Ki club</span><span
                                        className="ru">Kyong-Ki club</span> <b className="caret"/></a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to='about-club' data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Наш клуб</span>
                                                <span className="ru">Наш клуб</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/' data-state="about" onClick={handlerClick}>
                                            <span className="ua">Склад</span>
                                            <span className="ru">Состав</span>
                                        </Link>
                                        </li>
                                        <li>
                                            <Link to='/' data-state="contact" onClick={handlerClick}>
                                                <span
                                            className="ua">Контакти</span><span
                                            className="ru">Контакты</span></Link>
                                        </li>
                                        <li>
                                            <Link to='our-dojangs' data-state="page-top" onClick={handlerClick}>
                                            <span className="ua">Наші зали</span><span
                                            className="ru">Наши залы</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="friends" data-state="page-top" onClick={handlerClick}>
                                            <span className="ua">Наші друзі</span><span
                                            className="ru">Наши друзья</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <a onClick={dropdownOpen} className="dropdown-toggle"
                                       data-toggle="dropdown"><span
                                        className="ua">Taekwon-Do</span><span
                                        className="ru">Taekwon-Do</span> <b className="caret"/></a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="tuls-taekwon-do" data-state="page-top" onClick={handlerClick}>
                                            <span className="ua">Тулі Таеквон-До</span><span
                                            className="ru">Тули Таэквон-До</span></Link>
                                        </li>
                                        <li>
                                            <Link to='attestation' data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Атестація Таеквон-До</span><span
                                                className="ru">Атестация Таэквон-До</span></Link></li>
                                        <li>
                                            <Link to="terminologic" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Термінологія Таеквон-До</span><span
                                                className="ru">Терминология Таэквон-До</span></Link></li>
                                        <li>
                                            <Link to="history" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Історія Таеквон-До</span><span
                                                className="ru">История Таэквон-До</span></Link>
                                        </li>
                                        <li>
                                            <Link to="blog" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Цікава інформація</span><span
                                                className="ru">Интересная информация</span></Link>
                                        </li>
                                        <li>
                                            <Link to="news" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Новини Таеквон-До</span><span
                                                className="ru">Новости Таэквон-До</span></Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <a onClick={dropdownOpen} className="dropdown-toggle"
                                       data-toggle="dropdown"><span
                                        className="ua">Послуги</span><span
                                        className="ru">Услуги</span> <b className="caret"/></a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/" data-state="service" onClick={handlerClick}>
                                            <span className="ua">Перелiк послуг</span><span
                                            className="ru">Перечень услуг</span></Link></li>
                                        <li>
                                            <Link to="tsigun" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Цигун (Таньчжон хоуп)</span><span
                                                className="ru">Цигун (Таньчжон хоуп)</span></Link>
                                        </li>
                                        <li>
                                            <Link to="reabilitation" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Реабілітація</span><span
                                                className="ru">Реабилитация</span></Link>
                                        </li>
                                        <li>
                                            <Link to="trainings" data-state="page-top" onClick={handlerClick}>
                                                <span className="ua">Семінари та тренінги</span><span
                                                className="ru">Семинары и тренинги</span></Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}