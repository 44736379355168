import React from 'react';
import { Link } from "react-scroll";
import { bodyClick } from '../app/app';

const Footer = () => {
    return(
        <footer>
            <div className="container" onClick={bodyClick}>
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="shake">
                            <div className="page-scroll marginbot-30 shake animated">
                                <Link to="page-top" smooth={true} offset={0} duration={1000} id="totop" className="btn btn-circle" onClick={bodyClick}>
                                    <i className="fa fa-angle-double-up animated"/>
                                </Link>
                            </div>
                        </div>
                        <p>&copy; 2020 Kyong-Ki. All rights reserved.</p>
                        <div className="credits">
                            Designed by AbsentSilence. Kyong-Ki club.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
