import React from 'react';
import {TaekwondoServiceConsumer} from '../taekwondo-service-context';

const withTaekwondoServiceContext = (Wrapped) => {

    return (props) => {
        return (
            <TaekwondoServiceConsumer>
                {
                    (bodyClick) => {
                        return (<Wrapped { ...props } bodyClick={ bodyClick }/>);
                    }
                }
            </TaekwondoServiceConsumer>
        );
    }
};

export default withTaekwondoServiceContext;