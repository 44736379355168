import React from 'react';
import {bodyClick} from "../app/app";

const Tsigun = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Цигун (Таньчжон хоуп)</span>
                                            <span className="ru">Цигун (Таньчжон хоуп)</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-летающий-гуру-48.png")}/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>Цигун (Таньчжон хоуп)</h5>
                                        <br/>
                                        <blockquote><p>Ничего не создаю,</p><p>а только передаю</p>(Конфуций)</blockquote>
                                        <a name="metkadoc2">
                                            <h1><p>Что такое таньчжон хоуп?</p></h1>
                                        </a><p>Таньчжон хоуп – древнекорейская оздоровительная гимнастика, которая формировалась
                                        на протяжении столетий и используется до настоящего времени как средство укрепления
                                        здоровья и профилактики различных заболеваний, а также как система комплексной регуляции
                                        организма. Основу этой системы составляют различные комплексы упражнений, а также
                                        определенные способы дыхания и психоконцентрации.</p><p>Основная цель таньчжон хоуп –
                                        наладить правильную циркуляцию Ки внутри организма и способствовать ее накоплению в
                                        области нижнего энергетического центра – таньчжон.</p><b>Простота и эффективность</b>
                                        <p>Простота предлагаемых упражнений из системы таньчжон хоуп заключается в том, что в
                                            отличие от других систем они чрезвычайно доступны по своим движениям, требуют
                                            небольшой площадки для занятий (их можно практиковать даже в небольшой городской
                                            комнате), и, самое главное, на первых этапах не требуют изучения сложных методов
                                            концентрации внимания.</p>
                                        Практикуя цигун ( работа с энергией ), мы запускаем процесс исцеления и восстановления
                                        организма. Мы учимся находиться в настоящем моменте и наслаждаться спокойствием ,
                                        гармонией. Выбираем полную реализацию своего потенциала в гармонии с природой и
                                        Вселенной.
                                        В нашей школе "Единое Сердце" клуба "Kyong-Ki" мы используем лучшие практики,
                                        проверенные тысячелетиями: даосский цигун (вайдань-цигун и нейдань-цигун);
                                        тайцзи-цюань(разнообразные комплексы,в том числе Паочуй - "взрывающиеся удары"); Ба
                                        дуань цзин; И цзинь цзин; У цин си; столбовое стояние и разные модификации чжанчжуан;
                                        мексиканский цигун и еще много проверенных и интересных практик по работе с энергией.
                                        Упражнения цигун помогают поддерживать здоровье и активность клеток, активизировать
                                        выработку организмом гормонов, стимулировать меридианы и циркуляцию крови, регулировать
                                        внутренние функции тела, усиливать сопротивляемость болезням, сбалансировать обмен
                                        веществ. Все это помогает восстановить жизненные силы и продлить молодость.
                                        Люди с избыточным весом, восстанавливают свой нормальный вес, а те кому необходимо
                                        набрать вес - приводят свой вес к норме. Упражнения выполняются легко и свободно,
                                        главное – суметь выработать верные движения, для того чтобы в организме осуществлялась
                                        правильная циркуляция энергии. Женщинам это поможет быть стройными и гибкими, а мужчинам
                                        быть уверенными в своей мужской силе. Во многих упражнениях движения выполняются не
                                        спеша, сопровождая движения медленным, плавным и глубоким дыханием. Также на занятиях мы
                                        изучаем основы медитации. Учимся работать со своими эмоциями. Эмоциональная
                                        уравновешенность способствует снижению мышечного напряжения, а следовательно, и
                                        полноценному отдыху, быстрому восстановлению физических сил.
                                        Тренировки и <a href="trainings.html#seminars">семинары</a> проводит президент нашего
                                        клуба, мастер-цигун Арбузов Андрей Владимирович, практикующий цигун с 2002
                                        года.<br/><br/>
                                        <img className="img-content" src={require("./img/image_2018-12-29_10-56-40.png")}/>
                                        <br/><p>Следует отметить, что правильная позиция, спокойное дыхание и разумное
                                        расслабление тела уже сами по себе обеспечивают правильную циркуляцию Ки и мощный
                                        оздоровительный эффект. Регулярная практика таньчжон хоуп заметно повышает тонус
                                        организма, снимает усталость, уменьшает потребность во сне. Занятия таньчжон хоуп
                                        способствуют регуляции обмена веществ в организме, что сказывается на уменьшении
                                        избыточного веса. Таньчжон хоуп широко используется как восстановительный комплекс для
                                        спортсменов после соревнований, а также людей, чей труд связан с высокими психическими,
                                        интеллектуальными и физическими нагрузками. Многие деятели науки и искусства изучают
                                        таньчжон хоуп, чтобы придать себе еще больший импульс к творчеству.</p><p>В психическом
                                        плане таньчжон хоуп позволяет выработать удивительную чистоту и спокойствие сознания –
                                        это крайне необходимо как для саморазвития, так и в повседневной жизни.</p><b>Оздоровительный
                                        эффект</b><p>Оздоровительный эффект – вот основная причина высокой оценки людьми системы
                                        таньчжон хоуп и желания ее изучать. Основным принципом таньчжон хоуп является «спокойное
                                        безмятежное сердце плюс сосредоточенный ум», что дает нервной системе возможность отдыха
                                        и, как следствие, улучшается координация функций различных органов тела. Расслабление
                                        всего тела, глубокое и естественное дыхание, плавные движения, которые управляются
                                        мыслью – все это приводит к гармонии «внешнего» и «внутреннего», прочищает каналы, по
                                        которым движется энергия Ки, кровеносные и лимфатические сосуды, улучшает состояние
                                        костно-мышечной и пищеварительной систем.</p><p>Основным содержанием системы таньчжон
                                        хоуп являются физические и дыхательные упражнения в сочетании с концентрацией внимания.
                                        Оздоровительное воздействие этих упражнений базируется на единстве физических и
                                        психических функций организма человека, на связи сознания и воли с работой мышц и
                                        внутренних органов. Темп выполнения упражнений – медленный и плавный, амплитуда движений
                                        варьируется в зависимости от индивидуальных возможностей занимающегося, что дает
                                        возможность заниматься людям широкого возрастного диапазона, в том числе с очень низким
                                        уровнем физической подготовленности.</p><p>Адекватная физическая нагрузка, регулируемая
                                        в зависимости от уровня подготовленности занимающихся амплитудой и темпом выполнения
                                        упражнений, создает ощущение «мышечной радости», стимулирует к активным занятиям,
                                        совершенствует функциональную подготовленность. При систематических занятиях отмечается
                                        значительное снижение деформации позвоночного столба, улучшение его подвижности,
                                        укрепление связок, костей, увеличение силы мышц, амплитуды движений в суставах, что
                                        является хорошим средством профилактики остеохондроза, радикулита, артритов.
                                        Исследования корейских ученых показали, что у занимающихся наблюдается улучшение
                                        сердечной деятельности по данным ЭКГ, стабилизируется артериальное давление, повышается
                                        выносливость к физическим нагрузкам, быстрее протекают восстановительные процессы после
                                        них.</p><p>Такие изменения в состоянии здоровья поклонников системы таньчжон хоуп ученые
                                        объясняют использованием в данной системе ритмичного диафрагмального дыхания, гармонично
                                        связанного с плавным чередованием напряжения и расслабления скелетной мускулатуры, что в
                                        свою очередь способствует более адекватному кровоснабжению мышц и внутренних органов,
                                        нормализации сосудистого тонуса. Абдоминальный тип дыхания, который используется при
                                        выполнении упражнений, способствует более эффективной вентиляции легких.
                                        Экспериментальные исследования показали, что при регулярных занятиях увеличивается
                                        амплитуда движения грудной клетки, эластичность легочной ткани, функциональное состояние
                                        диафрагмы, и, как следствие,&nbsp;– жизненная емкость легких, легочный кровоток,
                                        улучшается газообмен в легких. Упражнения оказывают положительное влияние на метаболизм.
                                        При занятиях в крови занимающихся снижается уровень холестерина, что обеспечивает
                                        профилактику атеросклероза.</p><p>Плавные движения требуют высокой концентрации
                                        внимания. Это является хорошей тренировкой центральной нервной системы. Движения
                                        построены таким образом, что ритмичные нервные импульсы работающих мышц рефлекторно
                                        способствуют установлению баланса процессов возбуждения и торможения в центральной
                                        нервной системе и таким образом оказывают дополнительное успокаивающее воздействие.
                                        Ритмичные чередования напряжения и расслабления скелетных мышц и диафрагмы улучшают
                                        кровоток в брюшной полости, нормализуя перистальтику кишечника, ликвидируя застойные
                                        явления в печени.</p><p>Существенной особенностью данной системы упражнений является
                                        гармоничная тренировка всех систем организма, включая центральную нервную систему. Она
                                        способствует концентрации внимания, развитию памяти, умению управлять своим физическим и
                                        психическим состоянием, что позволяет отнести таньчжон хоуп к одному из оптимальных
                                        вариантов двигательной психорегуляции.</p><b>Концепция Ки и практика таньчжон хоуп</b>
                                        <p>Концепция Ки является базовой для всей восточной культуры. Не затрагивая подробно ее
                                            смысл, здесь мы скажем лишь о нескольких моментах, которые необходимо знать при
                                            занятиях таньчжон хоуп.</p><p>Ки не имеет точного соответствия в русском языке и
                                        предполагает несколько значений: внутренняя энергия, воздух, дыхание и даже настроение.
                                        Так или иначе, речь идет об энергетической субстанции, которая наполняет наш организм.
                                        Важнейшим качеством Ки является то, что она может растрачиваться (например, при тяжелой
                                        работе, больших физических нагрузках или болезнях), восполняться (через дыхательные и
                                        медитативные упражнения, правильный образ жизни, определенное питание), приходить в
                                        состояние дисбаланса, вредить организму (при гневе, любых сильных чувствах и
                                        переживаниях), угнетаться (при долгом сне или наоборот, отсутствии сна, при переедании)
                                        и т.&nbsp;д.</p><p>Когда человек чувствует то, что обычно именуется «упадком сил», в
                                        восточной традиции может быть объяснено через термин «недостатка Ки» или «угнетения Ки».
                                        Любая болезнь, таким образом, вызывается дисбалансом Ки в той или иной части
                                        человеческого организма. Следовательно, важнейшей задачей любой восточной системы
                                        оздоровления является приведение Ки в состояние покоя и гармонии.</p><p>По традиционным
                                        представлениям восточной медицины Ки циркулирует в теле (и наполняет его) по
                                        определенным энергетическим каналам или меридианам. В здоровом организме Ки свободно
                                        циркулирует в теле без малейших задержек, однако, в случае болезни или какого-то
                                        недомогания возникают так называемые «задержки» в свободной циркуляции Ки. Это может
                                        быть обусловлено как любым заболеванием, так, например, и излишним психическим
                                        напряжением, переутомлением и другими факторами.</p><p>Занятия таньчжон хоуп на первом
                                        этапе призваны устранить дисбалансы различного типа в циркуляции Ки, а на следующих
                                        этапах способствовать накоплению Ки в организме.</p><p>Однако это невозможно сделать раз
                                        и навсегда. Обычно в течение дня человеку приходится испытывать различного рода стрессы:
                                        необходимость выполнять работу, связанную с принятием сложных решений, физические
                                        нагрузки; даже неправильное или излишнее питание могут надолго вызвать закупорку Ки. Так
                                        возникает чувство внутреннего дискомфорта, неоправданной тревоги, нервозность, потеря
                                        внимания. Дисбаланс может быть вызван также и общением с некоторыми людьми, способными
                                        оказывать негативное воздействие на ваше состояние. Таким образом, необходимо регулярно
                                        повторять «восстановительные» сеансы таньчжон хоуп. Например, утром занятия «заряжают»
                                        организм перед рабочим днем, вечером вновь приводят внутреннее Ки в состояние покоя.</p>
                                        <p>Для людей западной традиции не всегда просто представить циркуляцию Ки в своем теле.
                                            Более того, порой сами носители восточной традиции не способны сделать это. Таньчжон
                                            хоуп налаживает правильную циркуляцию Ки не столько за счет визуального
                                            представления потока Ки в организме, сколько за счет правильных, расслабленных
                                            позиций и строго определенной последовательности движений. В этой простоте огромное
                                            преимущество таньчжон хоуп перед другими системами.</p><p>Чувство Ки, а затем и
                                        ощущение циркуляции Ки придет само собой в процессе выполнения упражнений, его не
                                        следует искусственно стимулировать, поскольку можно вызвать неправильное ощущение, никак
                                        не связанное непосредственно с циркуляцией Ки и подсознательно закрепить его.</p><b>Для
                                        кого предназначен таньчжон хоуп</b><p>Таньчжон хоуп – одна из редких систем, для занятий
                                        которой нет ограничений ни по возрасту, ни по физическому состоянию. Разумеется в жизни
                                        не бывает универсальных рецептов, поэтому в случае стойких заболеваний и в пожилом
                                        возрасте желательно предварительно проконсультироваться с врачом.</p><p>В любом случае,
                                        этот комплекс следует ввести в ежедневное расписание жизни, что позволит поддерживать
                                        здоровое тело и бодрость духа до глубокой старости.</p><p>Характерной особенностью
                                        таньчжон хоуп является то, что начав практиковать его в молодом возрасте и затрачивая в
                                        день по 20 минут, можно до глубокой старости сохранять подвижность суставов, бодрость
                                        тела и спокойствие духа. Эта система хороша также тем, что позволяет начать занятия в
                                        любом возрасте, в частности, известны случаи, когда к эффективным занятиям приступали на
                                        седьмом десятке лет.</p><b>Методы изучения таньчжон хоуп</b><p>При изучении упражнений
                                        таньчжон хоуп лучше следовать следующему плану:</p><p>1.&nbsp;Изучать одно упражнение в
                                        три-четыре дня. Если вы чувствуете, что какое-то упражнение не запомнилось, или его
                                        выполнение связано с каким-либо дискомфортом, сначала добейтесь того, чтобы оно
                                        выполнялось абсолютно естественно. Ни в коем случае не переходите к следующему движению,
                                        пока не изучите старое.</p><p>2.&nbsp;Первоначально необходимо изучить кинематику
                                        движений, понять внутреннюю логику каждого упражнения. На первом этапе старайтесь просто
                                        медленно и плавно выполнить комплекс с ровным естественным дыханием.</p>
                                        <p>3.&nbsp;Переходите к сочетанию движений с дыханием и проработке методов концентрации,
                                            не раньше, чем будете уверены, что целиком выучили практикуемую последовательность
                                            упражнений и не задумываетесь над последовательностью движений – это может помешать
                                            концентрации внимания.</p><b>Как практиковать таньчжон хоуп</b><p>Существует
                                        несколько способов практики таньчжон хоуп:</p><p>1.&nbsp;Как отдельный комплекс:</p>
                                        <p>•&nbsp;Первый раз упражнения таньчжон хоуп лучше всего выполнять сразу же после
                                            пробуждения, встав с кровати. Утром их лучше сделать минимум два раза: первый раз
                                            предназначен для пробуждения организма и для небольшой разминки мышц. Второй раз
                                            упражнения следует выполнять с соблюдением всех правил, когда сознание уже
                                            пробудилось – это стимулирует циркуляцию Ки в организме.</p><p>•&nbsp;Второй раз
                                        упражнения выполняются вечером, непосредственно перед сном и обязательно после захода
                                        солнца, с целью очистить сознание после дневных забот, снять накопившуюся усталость.</p>
                                        <p>•&nbsp;В середине дня, в 15–16 или 18–19 часов, полезно также сделать еще раз
                                            упражнения таньчжон хоуп для стимулирования активности мозга и релаксации всего
                                            организма.</p><p>2.&nbsp;В составе тренировки в боевых искусствах или каком-либо
                                        виде спорта.</p><p>Упражнения таньчжон хоуп могут являться частью тренировки в боевых
                                        искусствах или каком-либо виде спорта. В этом случае упражнения таньчжон хоуп должны
                                        быть интегрированы в каждодневную тренировку. В течение тренировки они выполняются
                                        несколько раз. В самом начале, после традиционного приветствия (для концентрации
                                        внимания и устранения отвлекающих мыслей), и в конце тренировки (для восстановления
                                        организма). Упражнения таньчжон хоуп следует выполнять после упражнений большой
                                        интенсивности: поединков, сложных комплексов и т.&nbsp;д. В этом случае для практики
                                        достаточно двух-трех упражнений.</p><b>Важнейшие принципы занятий</b><p>Таньчжон хоуп
                                        будет эффективен лишь в том случае, если досконально следовать всем принципам. В
                                        большинстве своем они крайне просты, поэтому занимающиеся забывают их или не обращают на
                                        них должного внимания. Однако без этих принципов таньчжон хоуп превращается лишь в
                                        несложное гимнастическое упражнение, не дающее должного результата. Поэтому старайтесь
                                        соблюдать каждое правило.</p><b>Что необходимо соблюдать</b><p>1.&nbsp;Спокойное
                                        состояние духа. Не начинайте заниматься, пока не избавитесь от любых раздражающих или
                                        отвлекающих мыслей – занятия в этом случае не принесут должного эффекта. Если никак не
                                        удается уйти от мыслей о «дне насущном», выполните упражнения таньчжон хоуп просто как
                                        гимнастику медленных упражнений два-три раза. Это позволит вам успокоиться, и уже после
                                        этого сделайте «настоящий» таньчжон хоуп в спокойном и светлом состоянии духа.</p>
                                        <p>2.&nbsp;Правильные позиции тела. Позиции тела должны быть, прежде всего,
                                            естественными, это значит, что даже в тех упражнениях, где требуется небольшое
                                            растяжение мышц и сухожилий, соблюдайте естественную позицию, не старайтесь через
                                            силу выгнуться вперед или назад, неестественным образом развести плечи и т.&nbsp;д.
                                            Всякая позиция должна быть комфортна. Если вы чувствуете боль или неприятные
                                            ощущения в пояснице, ногах, шее после занятий, скорее всего вы излишне напряглись
                                            или не смогли правильно адаптировать позицию под себя. Внимательно анализируйте на
                                            начальных этапах правильность каждой своей позиции и передвижения.</p>
                                        <p>3.&nbsp;Отсутствие напряжения, как в теле, так и в сознании. Важнейшим принципом
                                            таньчжон хоуп является естественное расслабление. Нередко это понятие неправильно
                                            трактуется как абсолютное расслабление всех мышц. В реальности же речь идет о том,
                                            что внутри вас не должно быть никаких мыслей, которые могут привести к излишнему
                                            напряжению сознания. В идеальном варианте занимающийся должен быть свободен от любых
                                            отвлекающих мыслей и концентрироваться даже не на самих движениях (это первый этап
                                            занятий), но пребывать в абсолютно ровном, светлом состоянии духа. Это, в свою
                                            очередь, должно приводить к разумному расслаблению всего тела – должны быть
                                            напряжены лишь те группы мышц, которые необходимы для выполнения движения, и
                                            напряжены ровно на столько, чтобы движение было плавным.</p><p>4.&nbsp;Сочетание
                                        расслабления и напряжения.</p><p>5.&nbsp;Спокойное и ровное дыхание.</p>
                                        <p>6.&nbsp;Регулярность занятий. Таньчжон хоуп может принести эффект лишь при регулярных
                                            занятиях не меньше 20–30 минут в день. Как и восточная медицина, таньчжон хоуп
                                            действует малыми порциями: хотя положительные изменения можно почувствовать уже на
                                            второй-третий день занятий, реальный эффект наступает лишь на второй-третий
                                            месяц.</p><b>Чего следует избегать</b><p>1.&nbsp;Излишнего напряжения внимания.
                                        Нередко занимающиеся стремятся сразу же начать практику концентрации внимания на
                                        определенных точках организма. Не надо на первых этапах стремиться искусственно
                                        стимулировать циркуляцию Ки или какие-то ощущения в организме. Прежде всего, это ведет к
                                        излишнему напряжению сознания и, как следствие, напряженным, неестественным позициям
                                        тела. Правильные позиции уже обеспечивают свободную циркуляцию Ки.</p><p>2.&nbsp;Слишком
                                        замедленных движений. Не стоит искусственно замедлять движения. В упражнениях таньчжон
                                        хоуп нет заранее установленной «скорости выполнения». Правильное расслабление тела и
                                        сознания приведет к возникновению естественного ритма и скорости движений.</p>
                                        <p>3.&nbsp;Внезапных ускорений и рывков. Чаще всего это происходит при переходе из
                                            нижней позиции в верхнюю, шаге вперед или назад. Тем самым вы полностью утрачиваете
                                            эффект от спокойного дыхания и стабилизации Ки.</p><p>4.&nbsp;Задержек дыхания. Не
                                        следует практиковать долгие задержки дыхания, особенно тем, кто практикует этот комплекс
                                        как оздоровительное упражнение. Стремитесь вообще забыть о дыхании – и оно наладится
                                        само собой.</p><p>5.&nbsp;Дыхания грудью. Следите за тем, чтобы дышать нижней частью
                                        живота, но ни в коем случае не грудью. Избегайте глубокого дыхания и гипервентиляции
                                        легких. Дыхание должно быть нитевидным, слегка заметным.</p><p>6.&nbsp;Поднятия плеч. Не
                                        поднимайте плечи – традиционно считается, что тем самым вы создаете препятствие на пути
                                        прохождения Ки. Даже поднимая руки, старайтесь не двигать плечами, не подводите плечи к
                                        шее.</p><b>Где заниматься</b><p>Очевидно, что оптимальным местом является площадка на
                                        природе, в парке, на берегу реки или озера, в роще, где ничего не отвлекало бы вас.
                                        Однако, столь идеальное место не всегда оказывается рядом. Поэтому не стоит
                                        предпринимать каждодневных, долгих походов в поисках идеального места. Заниматься можно
                                        и у себя в квартире, в спортивном зале, предварительно хорошо проветрив помещение.
                                        Значительно важнее то, чтобы вас ничего не отвлекало и вы могли быть уверены, что в
                                        вашем распоряжении есть хотя бы 25–30 минут, когда вас ничего не побеспокоит.</p><a
                                        name="metkadoc3">
                                        <h1><p>Комплекс «18 оздоровительных упражнений таньчжон хоуп»</p></h1>
                                    </a><b>Положение кистей рук, используемые в комплексе </b><b>Кулак</b><p>Пальцы плотно
                                        прижаты друг к другу и собраны вовнутрь. Большой палец лежит поверх указательного и
                                        среднего.</p><img className="img-content"src={require("./img/works/i_033.png")}/><br/><b>Пустой
                                        кулак</b><p>Пальцы прижаты друг к другу. Первые и вторые фаланги пальцев немного
                                        согнуты, пальцы подобраны вовнутрь, образуя форму цилиндра. Большой палец накрывает
                                        указательный и средний пальцы в области ногтей.</p><img className="img-content" src={require("./img/works/i_034.png")}/><br/><b>«Энергичное
                                        движение шеи»</b><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины
                                        плеч. Руки на поясе, большие пальцы рук обращены назад. Корпус естественно выпрямлен, но
                                        ненапряжен. Взгляд направлен вперед (фото 1).</p><p>1.&nbsp;Медленно повернуть голову
                                        влево, взгляд направлен влево (фото 2).</p><p>2.&nbsp;Вернуться в исходное положение
                                        (фото 3).</p><img className="img-content"src={require("./img/works/i_035.jpg")}/><br/>
                                        <p>3.&nbsp;Медленно повернуть голову вправо, взгляд направлен вправо (фото 4).</p>
                                        <p>4.&nbsp;Вернуться в исходное положение(фото 5).</p><p>5.&nbsp;Выполнить медленный
                                        наклон головы назад, взгляд направлен вверх (фото 6).</p><img className="img-content" src={require("./img/works/i_036.jpg")}/><br/>
                                        <p>6.&nbsp;Вернуться в исходное положение (фото 7).</p><p>7.&nbsp;Выполнить медленный
                                        наклон головы вперед, взгляд направлен вниз (фото 8).</p><p>8.&nbsp;Вернуться в исходное
                                        положение (фото 9).</p><img className="img-content"src={require("./img/works/i_037.jpg")}/><br/><p>
                                        <i>Выполнить упражнение 8 раз. (При желании выполнять упражнение большее количество раз,
                                            суммарное количество должно быть кратно 8: 16, 24, 32).</i></p><p><b><i>Упражнение
                                        тренирует мышцы шеи и развивает суставы шейного отдела позвоночника. При повороте головы
                                        влево, вправо, вверх, вниз старайтесь амплитуду каждого движения доводить до максимально
                                        возможной для вас в данное время. Стандартная норма поворота головы влево и вправо
                                        составляет 60<sup>0</sup>, при наклоне головы назад – 45<sup>0</sup>, при наклоне головы
                                        вперед подбородок должен доставать до груди на уровне ключиц. Очень важно следить за
                                        тем, чтобы туловище во время выполнения упражнения находилось в вертикальном положении,
                                        не допускать прогиба в спине при движении головы назад, сгибания спины при движении
                                        головы вперед, поворотов туловища при повороте головы вправо и влево. При правильном
                                        выполнении упражнения вы должны ощущать тепло и легкую ломоту в мышцах шеи, это так
                                        называемое чувство Ки.</i></b></p><b>«Отведение и приведение рук в обе стороны»</b>
                                        <p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч, ладони обеих
                                            рук обращены друг к другу под углом 45<sup>0&nbsp;</sup>на расстоянии 20–30&nbsp;см
                                            от груди, взгляд направлен вперед (фото 10).</p><p>1.&nbsp;Медленно развести руки в
                                        стороны. Кисти обеих рук собрать в «пустой» кулак. Голову повернуть влево. Взгляд
                                        направлен влево. Движения рук и поворот головы выполняются с одинаковой скоростью (фото
                                        11).</p><p>2.&nbsp;Вернуться в исходное положение (фото 12).</p><img
                                        className="img-content"src={require("./img/works/i_038.jpg")}/><br/><p>3.&nbsp;Медленно развести
                                        руки в стороны. Кисти обеих рук собрать в «пустой» кулак. Голову повернуть вправо.
                                        Взгляд направлен вправо. Движения рук и поворот головы выполняются с одинаковой
                                        скоростью (фото 13).</p><p>4.&nbsp;Вернуться в исходное положение (фото 14).</p><img
                                        className="img-content"src={require("./img/works/i_039.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение тренирует мышцы шеи, плеч, спины и развивает
                                        двигательные функции плечевого пояса. Во время выполнения упражнения необходимо следить,
                                        чтобы предплечья были расположены перпендикулярно полу, локти направлены вниз, в фазе 2
                                        и 4 лопатки сведены.</i></b></p><p><b><i>При правильном выполнении упражнения должно
                                        появиться так называемое чувство Ки в области шеи, плеч и верхней части спины.</i></b>
                                    </p><b>«Поднятие обеих рук вверх»</b><p>Исходное положение: ноги врозь, расстояние между
                                        стоп – 1,5 ширины плеч, руки согнуты в локтях, кисти рук собраны в «пустой» кулак, на
                                        уровне линии плеч, ладони обеих рук направлены вперед. Взгляд направлен вперед (фото
                                        15).</p><p>1.&nbsp;Медленно поднять руки вверх, одновременно с движением рук разжать
                                        кулаки, пальцы обеих рук направлены вверх. Голова поднята, взгляд направлен на кисть
                                        левой руки (фото 16).</p><p>2.&nbsp;Вернуться в исходное положение (фото 17).</p><img
                                        className="img-content"src={require("./img/works/i_040.jpg")}/><br/><p>3.&nbsp;Медленно поднять руки
                                        вверх, одновременно с движением рук разжать кулаки, пальцы обеих рук направлены вверх.
                                        Голова поднята, взгляд направлен на кисть правой руки (фото 18).</p><p>4.&nbsp;Вернуться
                                        в исходное положение (фото 19).</p><img className="img-content"
                                                                                src={require("./img/works/i_041.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение тренирует мышцы плеч и спины. Во время
                                        выполнения упражнения старайтесь вытянуть руки вертикально вверх так высоко, насколько
                                        это возможно. Грудь развернута, живот подобран. Стопы не отрывать от пола во всех фазах
                                        движения. При правильном выполнении упражнения должно появиться чувство Ки в области шеи
                                        и спины.</i></b></p><b>«Круговое движение с поднятием рук вверх»</b><p>Исходное
                                        положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч. Руки скрещены перед
                                        животом ладонями внутрь. Взгляд направлен вперед (фото 20).</p><p>1.&nbsp;Медленно
                                        поднять скрещенные руки вверх. Одновременно с движением рук поднять вверх голову. Взгляд
                                        направлен на кисти рук (фото 21).</p><img className="img-content" src={require("./img/works/i_042.jpg")}/><br/>
                                        <p>2.&nbsp;Опустить руки через стороны вниз, одновременно развернуть ладони тыльной
                                            стороной вниз. Голову повернуть влево. Взгляд направлен на ладонь левой руки (фото
                                            22).</p><p>3.&nbsp;Вернуться в исходное положение (фото 23).</p><p>4.&nbsp;Медленно
                                        поднять скрещенные руки вверх. Одновременно с движением рук поднять вверх голову. Взгляд
                                        направлен на кисти рук фото 24).</p><img className="img-content"
                                                                                 src={require("./img/works/i_043.jpg")}/><br/>
                                        <p>5.&nbsp;Опустить руки через стороны вниз, одновременно развернуть ладони тыльной
                                            стороной вниз. Голову повернуть вправо. Взгляд направлен на ладонь правой руки (фото
                                            25).</p><p>6.&nbsp;Вернуться в исходное положение (фото 26).</p><img
                                        className="img-content"src={require("./img/works/i_044.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение тренирует двигательные функции мышц рук и увеличивает
                                        двигательные возможности плечевых суставов. Во время выполнения упражнения необходимо
                                        следить, чтобы руки не сгибались в локтях во всех фазах движения. Грудь развернута,
                                        живот подобран. При правильном выполнении упражнения должно появиться чувство Ки в
                                        области шеи, плеч и поясницы.</i></b></p><b>«Полет рук как крыльев»</b><p>Исходное
                                        положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч. Плечи расслаблены, руки
                                        естественно опущены вдоль тела. Взгляд направлен вперед (фото 27).</p><p>1.&nbsp;Согнуть
                                        руки в локтях и отвести их назад-вверх. Взгляд направлен на левый локоть (фото 28).</p>
                                        <p>2.&nbsp;Не останавливая движение, вывести руки вперед, локти направлены вверх, кисти
                                            рук обращены друг к другу тыльными сторонами. Взгляд направлен вперед (фото 29).</p>
                                        <img className="img-content"src={require("./img/works/i_045.jpg")}/><br/><p>3.&nbsp;Выполнить
                                        движение обеих рук вниз. Взгляд направлен вперед (фото 30).</p><p>4.&nbsp;Вернуться в
                                        исходное положение (фото 31).</p><p>5.&nbsp;Согнуть руки в локтях и отвести их
                                        назад-вверх. Взгляд направлен на правый локоть (фото 32).</p><img
                                        className="img-content"src={require("./img/works/i_046.jpg")}/><br/><p>6.&nbsp;Не останавливая
                                        движение, вывести руки вперед, локти направлены вверх, кисти рук обращены друг к другу
                                        тыльными сторонами. Взгляд направлен вперед (фото 33).</p><p>7.&nbsp;Выполнить движение
                                        обеих рук вниз. Взгляд направлен вперед (фото 34).</p><p>8.&nbsp;Вернуться в исходное
                                        положение (фото 35).</p><img className="img-content"src={require("./img/works/i_047.jpg")}/><br/><p>
                                        <i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение улучшает двигательные
                                        возможности плечевых суставов. Во время выполнения упражнения следите, чтобы плечи и
                                        локти были отведены до упора при движении назад и вверх. В фазе, когда локти находятся
                                        на уровне бровей, плечи отводятся вперед. При правильном выполнении упражнения должно
                                        появиться чувство Ки в плечах.</i></b></p><b>«Подъем рук вверх попеременно»</b>
                                        <p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч. Плечи
                                            расслаблены. Руки свободно опущены вдоль тела. Корпус естественно выпрямлен, но не
                                            напряжен. Взгляд направлен вперед (фото 36).</p><p>1.&nbsp;Поднять левую руку через
                                        сторону вверх. Взгляд направлен на ладонь левой руки. Одновременно правую руку согнуть в
                                        локте, отвести назад и прижать к крестцу тыльной стороной ладони фото 37).</p><img
                                        className="img-content"src={require("./img/works/i_048.jpg")}/><br/><p>2.&nbsp;Опустить левую руку
                                        через сторону вниз. Взгляд направлен на левую ладонь. Согнуть руку в локте, отвести
                                        назад и прижать тыльной стороной ладони к спине на уровне выше правой ладони (фото
                                        38).</p><p>3.&nbsp;Вернуться в исходное положение (фото 39).</p><p>4.&nbsp;Поднять
                                        правую руку через сторону вверх. Взгляд направлен на ладонь правой руки. Одновременно
                                        левую руку согнуть в локте, отвести назад и прижать к крестцу тыльной стороной ладони
                                        (фото 40).</p><img className="img-content"src={require("./img/works/i_049.jpg")}/><br/>
                                        <p>5.&nbsp;Опустить правую руку через сторону вниз. Взгляд направлен на правую ладонь.
                                            Согнуть руку в локте, отвести назад и прижать тыльной стороной ладони к спине на
                                            уровне выше левой ладони (фото 41).</p><p>6.&nbsp;Вернуться в исходное положение
                                        (фото 42).</p><img className="img-content"src={require("./img/works/i_050.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение разрабатывает двигательные функции плечевых
                                        суставов и мышц спины, развивает вращательную функцию плеч.</i></b></p><p><b><i>При
                                        выполнении упражнения необходимо контролировать, чтобы руки не сгибались в локтях при
                                        движении рук вверх и вниз, а положение туловища было строго вертикальным на протяжении
                                        всех фаз выполнения упражнения.</i></b></p><p><b><i>При правильном выполнении упражнения
                                        должно появиться чувство Ки в боковых мышцах шеи и плечах.</i></b></p><b>«Наклоны
                                        корпуса со скрещенными над головой руками»</b><p>Исходное положение: ноги врозь,
                                        расстояние между стоп – 1,5 ширины плеч. Руки сцеплены на уровне живота. Ладони
                                        направлены вверх. Взгляд направлен вперед (фото 43).</p><p>1.&nbsp;Медленно поднять руки
                                        до уровня подбородка (фото 44).</p><p>2.&nbsp;Не прекращая движения, ладони развернуть
                                        на 360<sup>0</sup> и поднять руки вверх. Голова поднята вверх, взгляд направлен на
                                        ладони обеих рук (фото 45).</p><img className="img-content"
                                                                            src={require("./img/works/i_051.jpg")}/><br/><p>3.&nbsp;Выполнить
                                        наклон туловища влево (фото 46).</p><p>4.&nbsp;Вернуться в положение «руки над головой»
                                        (фото 47).</p><p>5.&nbsp;Выполнить наклон туловища влево (фото 48).</p><img
                                        className="img-content"src={require("./img/works/i_052.jpg")}/><br/><p>6.&nbsp;Опустить руки через
                                        стороны вниз (фото 49).</p><p>7.&nbsp;Вернуться в исходное положение (фото 50).</p>
                                        <p>8.&nbsp;Медленно поднять руки до уровня подбородка (фото 51).</p><img
                                        className="img-content"src={require("./img/works/i_053.jpg")}/><br/><p>9.&nbsp;Не прекращая
                                        движения, ладони развернуть на 360<sup>0</sup> и поднять руки вверх. Голова поднята
                                        вверх, взгляд направлен на ладони обеих рук (фото 52).</p><p>10.&nbsp;Выполнить наклон
                                        туловища вправо (фото 53).</p><p>11.&nbsp;Вернуться в положение «руки над головой» (фото
                                        54).</p><img className="img-content"src={require("./img/works/i_054.jpg")}/><br/>
                                        <p>12.&nbsp;Выполнить наклон туловища вправо (фото 55).</p><p>13.&nbsp;Опустить руки
                                        через стороны вниз (фото 56).</p><p>14.&nbsp;Вернуться в исходное положение (фото
                                        57).</p><img className="img-content"src={require("./img/works/i_055.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение тренирует мышцы спины и разрабатывает
                                        подвижность позвоночника. При выполнении упражнения необходимо следить, чтобы поясничные
                                        мышцы были расслаблены в фазе, когда руки вытянуты вверх. Наклоны в стороны должны
                                        выполняться на максимально возможной амплитуде движения, туловище строго вертикально во
                                        всех фазах выполнения упражнения, плечевые и тазобедренные суставы необходимо держать в
                                        зафиксированном положении, ноги – прямыми.</i></b></p><p><b><i>При правильном выполнении
                                        упражнения должно появиться чувство Ки в области шеи, поясницы, плеч и рук.</i></b></p>
                                        <b>«Повороты корпуса с вытянутой рукой»</b><p>Исходное положение: ноги врозь, расстояние
                                        между стоп – 1,5 ширины плеч. Руки сжаты в кулаки у пояса. Взгляд направлен вперед (фото
                                        58).</p><p>1.&nbsp;Выполнить движение правой рукой вперед. Во время выполнения движения
                                        кулак раскрыть, в конечной фазе движения центр ладони направлен вперед, пальцы – вверх.
                                        Одновременно голову и туловище повернуть назад, взгляд направлен влево-назад. Левый
                                        локоть отвести назад, правая рука и локоть левой руки должны располагаться на одной
                                        линии (фото 59).</p><p>2.&nbsp;Вернуться в исходное положение (фото 60).</p><img
                                        className="img-content"src={require("./img/works/i_056.jpg")}/><br/><p>3.&nbsp;Выполнить движение
                                        левой рукой вперед. Во время выполнения движения кулак раскрыть, в конечной фазе
                                        движения центр ладони направлен вперед, пальцы – вверх. Одновременно голову и туловище
                                        повернуть назад, взгляд направлен вправо-назад. Правый локоть отвести назад, левая рука
                                        и локоть правой руки должны располагаться на одной линии (фото 61).</p>
                                        <p>4.&nbsp;Вернуться в исходное положение (фото 62).</p><img className="img-content"
                                                                                                     src={require("./img/works/i_057.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение тренирует поясничные мышцы
                                        и стимулирует их двигательные возможности, укрепляет поясничный связочно-мышечный
                                        аппарат и способствует выправлению искривленного позвоночника.</i></b></p><p><b><i>Во
                                        время выполнения упражнения следите, чтобы корпус был прямым, центр тяжести тела не
                                        смещался вправо или влево.</i></b></p><p><b><i>При правильном выполнении упражнения
                                        должно появиться чувство Ки в области поясницы, плеч и шеи.</i></b></p><b>«Круговые
                                        движения в пояснице»</b><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5
                                        ширины плеч. Руки на поясе, большие пальцы обеих рук направлены назад. Взгляд направлен
                                        вперед (фото 63).</p><p>1.&nbsp;Выполнить полный оборот тазом, направление вращения – по
                                        часовой стрелке. Давлением рук на область поясницы помогайте себе выполнять упражнение
                                        по максимальной амплитуде (фото 64).</p><p>2.&nbsp;Выполнить полный оборот тазом,
                                        направление вращения – против часовой стрелки. Давлением рук на область поясницы
                                        помогайте себе выполнять упражнение по максимальной амплитуде (фото 65).</p><img
                                        className="img-content"src={require("./img/works/i_058.jpg")}/><br/><p>Выполните упражнение 8 раз по
                                        направлению часовой стрелки, затем 8 раз против направления часовой стрелки.</p>
                                        <p>Упражнение разрабатывает суставы поясничных позвонков и тренирует крестцовую мышцу.
                                            Эффективно для выправления физиологического дугового градуса поясничных
                                            позвонков.</p><p>При выполнении упражнения амплитуда вращения таза должна быть
                                        максимальной. Амплитуда движения головы и верхнего плечевого пояса должна быть
                                        минимальной. На протяжении всей фазы выполнения упражнения характер движений должен
                                        оставаться медленным и плавным. Прямые ноги натянуты, но не напряжены.</p><p>При
                                        правильном выполнении упражнения должно появиться чувство Ки в области поясницы.</p><b>«Руки
                                        развести в стороны и выполнить сгибание в пояснице»</b><p>Исходное положение: ноги
                                        врозь, расстояние между стоп – 1,5 ширины плеч. Руки скрещены перед животом, ладони
                                        направлены внутрь. Взгляд направлен вперед (фото 66).</p><p>1.&nbsp;Медленно поднять
                                        руки вверх, взгляд направлен на ладони обеих рук, грудь развернута, живот подобран (фото
                                        67).</p><img className="img-content"src={require("./img/works/i_059.jpg")}/><br/><p>2.&nbsp;Опустить
                                        руки через стороны до горизонтального уровня, ладони направлены вверх (фото 68).</p>
                                        <p>3.&nbsp;Развернуть ладони на 180<sup>0</sup> и выполнить наклон вперед с прогибом в
                                            пояснице (фото 69).</p><img className="img-content"src={require("./img/works/i_060.jpg")}/><br/>
                                        <p>4.&nbsp;Скрестить руки и попытаться достать кончиками пальцев до пола. Коснуться
                                            руками стоп (фото 70).</p><p>5.&nbsp;Вернуться в исходное положение (фото 71).</p>
                                        <img className="img-content"src={require("./img/works/i_061.jpg")}/><br/><p><i>Выполнить упражнение
                                        8 раз.</i></p><p><b><i>Упражнение тренирует мышцы спины и стимулирует двигательные
                                        функции межпозвоночных суставов. При выполнении упражнения необходимо обращать внимание
                                        на положение плеч и рук на одной линии. Во время выполнения наклонов голова должна быть
                                        поднята, ноги натянуты, но не напряжены. При подъеме рук вверх, они должны находиться на
                                        одной вертикальной линии с туловищем.</i></b></p><p><b><i>При правильном выполнении
                                        упражнения вы должны ощущать чувство Ки в области поясницы и в ногах.</i></b></p><b>«Нога
                                        согнута в колене поворот корпуса с вытянутой рукой»</b><p>Исходное положение: ноги
                                        врозь, расстояние между стоп – 1,5 ширины плеч. Руки сжаты в кулаки у пояса. Взгляд
                                        направлен вперед (фото 72).</p><p>1.&nbsp;Повернуть корпус влево, вес тела перенести на
                                        левую ногу, стопу левой ноги развернуть на 90<sup>0</sup>. Одновременно выполнить
                                        движение вперед правой рукой, кулак во время движения разжимается. В конечном положении
                                        пальцы правой руки направлены вперед, ребро ладони – вниз. Левая рука согнута в локте и
                                        отведена назад до упора (фото 73).</p><p>2.&nbsp;Вернуться в исходное положение (фото
                                        74).</p><img className="img-content"src={require("./img/works/i_062.jpg")}/><br/>
                                        <p>3.&nbsp;Повернуть корпус вправо, вес тела перенести на правую ногу, стопу правой ноги
                                            развернуть на 90°. Одновременно выполнить движение вперед левой рукой, кулак во
                                            время движения разжимается. В конечном положении пальцы левой руки направлены
                                            вперед, ребро ладони – вниз. Правая рука согнута в локте и отведена назад до упора
                                            (фото 75).</p><p>4.&nbsp;Вернуться в исходное положение (фото 76).</p><img
                                        className="img-content"src={require("./img/works/i_063.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение тренирует мышцы поясницы, ягодиц и ног, развивает
                                        вращательную способность позвоночника. Во время выполнения упражнения следите за тем,
                                        чтобы спина была ровной, руки и ноги прямыми в конечных фазах упражнения. Сзади стоящая
                                        нога не должна быть согнутой. На впереди стоящую ногу приходится 60–70&nbsp;% веса
                                        тела.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны ощущать чувство
                                        Ки в области поясницы и в ногах.</i></b></p><b>«Наклоны в пояснице руками коснуться
                                        носков»</b><p>Исходное положение: ноги вместе, руки естественно опущены вдоль тела.
                                        Взгляд направлен вперед. Плечи расслаблены. Корпус естественно выпрямлен, но не напряжен
                                        (фото 77).</p><p>1.&nbsp;Сцепить руки между собой на уровне верхней области живота.
                                        Ладони направлены вверх. Поднять руки перед собой вверх, но во время выполнения движения
                                        ладони развернуть на 360°. Голова поднята, взгляд направлен на тыльную сторону кистей
                                        рук (фото 78).</p><img className="img-content"src={require("./img/works/i_064.jpg")}/><br/>
                                        <p>2.&nbsp;Выполнить наклон вперед. Руки вытянуты вперед. Спина прямая. Взгляд направлен
                                            на кисти рук (фото 79).</p><p>3.&nbsp;Выполнить наклон вниз и коснуться ладонями
                                        ступней ног (фото 80).</p><p>4.&nbsp;Вернуться в исходное положение (фото 81).</p><img
                                        className="img-content"src={require("./img/works/i_065.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение растягивает связки в области поясничных позвонков,
                                        укрепляет мышцы спины и ног. Во время выполнения упражнения необходимо следить, чтобы
                                        движения были медленными и плавными во всех фазах упражнения, ноги – прямыми, движения
                                        рук и корпуса – синхронны.</i></b></p><p><b><i>При правильном выполнении упражнения вы
                                        должны ощущать чувство Ки в шее, пояснице и ногах.</i></b></p><b>«Круговые движения в
                                        коленных суставах»</b><p>Исходное положение: ноги вместе, туловище наклонено вперед,
                                        кисти обеих рук находятся на коленях, голова поднята, взгляд направлен вперед (фото
                                        82).</p><p>1.&nbsp;Согнуть ноги в коленях, выполнить вращательные движения коленями по
                                        часовой стрелке (фото 83).</p><p>2.&nbsp;Вернуться в исходное положение (фото 84).</p>
                                        <img className="img-content"src={require("./img/works/i_066.jpg")}/><br/><p>3.&nbsp;Согнуть ноги в
                                        коленях, выполнить вращательные движения коленями против часовой стрелки (фото 85).</p>
                                        <p>4.&nbsp;Вернуться в исходное положение (фото 86).</p><img className="img-content"
                                                                                                     src={require("./img/works/i_067.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение разрабатывает основные
                                        суставы ног, повышает гибкость связок коленных суставов и увеличивает их прочность.</i></b>
                                    </p><p><b><i>При выполнении упражнения следите, чтобы вращения в коленных суставах были
                                        равномерными и плавными, амплитуда движений – максимальной, когда колени отведены назад,
                                        ноги должны быть прямыми, пятки не отрывать от пола.</i></b></p><p><b><i>При правильном
                                        выполнении упражнения вы должны ощущать чувство Ки в коленных и голеностопных
                                        суставах.</i></b></p><b>«Повороты тела с переносом центра тяжести с одной ноги на
                                        другую»</b><p>Исходное положение: ноги врозь, расстояние между стоп – 2,5 ширины плеч,
                                        руки на поясе, большие пальцы рук направлены назад, корпус естественно выпрямлен, но не
                                        напряжен. Плечи расслаблены. Взгляд направлен вперед (фото 87).</p><p>1.&nbsp;Перенести
                                        вес тела на правую ногу, одновременно разворачивая корпус влево на 45° (фото 88).</p>
                                        <p>2.&nbsp;Вернуться в исходное положение (фото 89).</p><img className="img-content" src={require("./img/works/i_068.jpg")}/><br/>
                                        <p>3.&nbsp;Перенести вес тела на левую ногу, одновременно разворачивая корпус вправо на
                                            45° (фото 90).</p><p>4.&nbsp;Вернуться в исходное положение (фото 91).</p><img
                                        className="img-content"src={require("./img/works/i_069.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение тренирует мышцы бедер и повышает прочность тазобедренных
                                        суставов.</i></b></p><p><b><i>При выполнении упражнения следите, чтобы в фазе переноса
                                        центра тяжести тела колено и носок ступни находились на одной вертикальной линии, стопы
                                        ног были направлены вперед параллельно друг другу, корпус – прямой.</i></b></p><p><b><i>При
                                        правильном выполнении упражнения вы должны ощущать чувство Ки в области бедер.</i></b>
                                    </p><b>«Приседания с постепенным вставанием»</b><p>Исходное положение: ноги вместе, плечи
                                        расслаблены, руки свободно опущены вдоль тела, корпус естественно выпрямлен, но не
                                        напряжен, взгляд направлен вперед (фото 92).</p><p>1.&nbsp;Выполнить наклон вперед,
                                        кисти обеих рук на коленях, ноги прямые, голова поднята, взгляд направлен вперед. Руками
                                        выполнить давящие движения на колени (фото 93).</p><p>2.&nbsp;Согнуть ноги в коленях и
                                        выполнить приседание, кисти обеих рук на коленях, пальцы рук обращены друг к другу.
                                        Взгляд направлен вперед (фото 94).</p><img className="img-content" src={require("./img/works/i_070.jpg")}/><br/>
                                        <p>3.&nbsp;Ладони обеих рук прижать к стопам и выпрямить ноги (фото 95). 4. Вернуться в
                                            исходное положение (фото 96).</p><img className="img-content"
                                                                                  src={require("./img/works/i_071.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение тренирует мышцы ног, укрепляет коленные
                                        суставы.</i></b></p><p><b><i>При выполнении упражнения следите, чтобы во время
                                        приседания пятки не отрывать от пола, корпус был прямой, мышцы ягодиц и ног
                                        расслаблены.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны ощущать
                                        чувство Ки в области мышц ног и коленных суставов.</i></b></p><b>«Нога согнута в колене.
                                        Правой рукой достать левое колено, левая рука поднята»</b><p>Исходное положение: ноги
                                        врозь, расстояние между стоп – 2 ширины плеч. Плечи расслаблены, руки свободно опущены
                                        вдоль тела, корпус естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото
                                        97).</p><p>1.&nbsp;Выполнить наклон вперед, правой рукой коснуться левого колена (фото
                                        98).</p><img className="img-content"src={require("./img/works/i_072.jpg")}/><br/>
                                        <p>2.&nbsp;Выпрямить туловище, одновременно согнуть ноги в коленях, левую руку поднять
                                            вверх, ладонь левой руки направлена вперед. Взгляд направлен вверх (фото 99).</p>
                                        <p>3.&nbsp;Выполнить наклон вперед, одновременно выпрямить ноги, левой рукой коснуться
                                            правого колена (фото 100).</p><p>4.&nbsp;Коснуться ладонями стоп (фото 101).</p><img
                                        className="img-content"src={require("./img/works/i_073.jpg")}/><br/><p>5.&nbsp;Вернуться в исходное
                                        положение (фото 102).</p><p>6.&nbsp;Выполнить наклон вперед, левой рукой коснуться
                                        правого колена (фото 103).</p><p>7.&nbsp;Выпрямить туловище, одновременно согнуть ноги в
                                        коленях, правую руку поднять вверх, ладонь правой руки направлена вперед. Взгляд
                                        направлен вверх (фото 104).</p><img className="img-content" src={require("./img/works/i_074.jpg")}/><br/><p>8.&nbsp;Выполнить
                                        наклон вперед, одновременно выпрямить ноги, правой рукой коснуться левого колена (фото
                                        105).</p><p>9.&nbsp;Коснуться ладонями стоп (фото 106).</p><p>10.&nbsp;Вернуться в
                                        исходное положение (фото 107).</p><img className="img-content"
                                                                               src={require("./img/works/i_075.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение тренирует мышцы ног и повышает прочность
                                        суставов ног, а также оказывает благотворное воздействие на мышцы шеи и плеч.</i></b>
                                    </p><p><b><i>При выполнении упражнения следите, чтобы корпус был прямым, при приседании не
                                        допускайте прогиба в пояснице, руки в локтях не сгибать.</i></b></p><p><b><i>При
                                        правильном выполнении упражнения вы должны ощущать чувство Ки в области шеи, плеч,
                                        поясницы и ногах.</i></b></p><b>«Держать руками ногу, согнутую в колене, перед
                                        грудью»</b><p>Исходное положение: ноги вместе, плечи расслаблены, руки свободно опущены
                                        вдоль тела, корпус естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото
                                        108).</p><p>1.&nbsp;Сделать шаг вперед левой ногой, центр тяжести тела перенести на
                                        левую ногу, пятку правой ноги оторвать от пола; одновременно поднять руки перед собой
                                        вверх, ладони обращены друг к другу, голова поднята, взгляд направлен вперед (фото
                                        109).</p><img className="img-content"src={require("./img/works/i_076.jpg")}/><br/>
                                        <p>2.&nbsp;Опустить руки через стороны вниз (фото 110).</p><p>3.&nbsp;Одновременно
                                        поднять вверх правое колено и, плотно обхватив его руками, прижать к груди. Левая нога
                                        выпрямлена (фото 111).</p><p>4.&nbsp;Вернуться в исходное положение (фото 112).</p><img
                                        className="img-content"src={require("./img/works/i_077.jpg")}/><br/><p>5.&nbsp;Сделать шаг вперед
                                        правой ногой, центр тяжести тела перенести на правую ногу, пятку левой ноги оторвать от
                                        пола; одновременно поднять руки перед собой вверх, ладони обращены друг к другу, голова
                                        поднята, взгляд направлен вперед (фото 113).</p><p>6.&nbsp;Опустить руки через стороны
                                        вниз (фото 114).</p><p>7.&nbsp;Одновременно поднять вверх левое колено и, плотно
                                        обхватив его руками, прижать к груди. Правая нога выпрямлена (фото 115).</p><img
                                        className="img-content"src={require("./img/works/i_078.jpg")}/><br/><p>8.&nbsp;Вернуться в исходное
                                        положение (фото 116).</p><img className="img-content"src={require("./img/works/i_079.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение тренирует ягодичные мышцы и
                                        мышцы ног, улучшает подвижность тазобедренных суставов, развивает способность удерживать
                                        равновесие.</i></b></p><p><b><i>При выполнении упражнения следите, чтобы при обхвате
                                        колена опорная нога не сгибалась в колене, одновременно с подъемом рук должна
                                        подниматься вверх и голова.</i></b></p><p><b><i>При правильном выполнении упражнения вы
                                        должны ощущать чувство Ки в задних мышцах ног и в области коленей.</i></b></p><b>«Руки
                                        на поясе. Ходьба на месте»</b><p>Исходное положение: ноги вместе, руки на поясе, большие
                                        пальцы рук направлены назад, плечи расслаблены, корпус естественно выпрямлен, но не
                                        напряжен. Взгляд направлен вперед (фото 117).</p><p>1.&nbsp;Сделать шаг вперед левой
                                        ногой. В момент, когда стопа левой ноги коснется пола, пятку правой ноги оторвать от
                                        пола. Центр тяжести переместить на левую ногу (фото 118).</p><p>2.&nbsp;Опустить пятку
                                        правой ноги на пол, согнуть правое колено, центр тяжести тела переместить на правую
                                        ногу. Левую ногу поставить на пятку, носок левой ноги оттянуть на себя (фото 119).</p>
                                        <img className="img-content"src={require("./img/works/i_080.jpg")}/><br/><p>3.&nbsp;Сделать шаг
                                        вперед правой ногой. В момент, когда стопа правой ноги коснется пола, пятку левой ноги
                                        оторвать от пола. Центр тяжести переместить на правую ногу (фото 120).</p>
                                        <p>4.&nbsp;Опустить пятку левой ноги на пол, согнуть левое колено, центр тяжести тела
                                            переместить на левую ногу. Правую ногу поставить на пятку, носок правой ноги
                                            оттянуть на себя (фото 121).</p><p>5.&nbsp;Переместить центр тяжести на правую ногу,
                                        приподнять пятку левой ноги (фото 122).</p><img className="img-content"
                                                                                        src={require("./img/works/i_081.jpg")}/><br/>
                                        <p>6.&nbsp;Переместить центр тяжести на левую ногу, согнуть левое колено, правая нога
                                            стоит на пятке (фото 123).</p><p>7.&nbsp;Выпрямить левую ногу, правой ногой сделать
                                        шаг назад, согнуть ее в колене и переместить центр тяжести на правую ногу (фото
                                        124).</p><p>8.&nbsp;Вернуться в исходное положение (фото 125).</p><img
                                        className="img-content"src={require("./img/works/i_082.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение тренирует мышцы ног и координацию движений.</i></b></p>
                                        <p><b><i>При выполнении упражнения следите, чтобы корпус был прямым во всех фазах
                                            выполнения упражнения, перемещение центра тяжести должно происходить синхронно с
                                            шагом вперед или назад.</i></b></p><p><b><i>При правильном выполнении упражнения вы
                                        должны ощущать чувство Ки в задних мышцах ног и в области ног.</i></b></p><a
                                        name="metkadoc4">
                                        <h1><p>Специальные упражнения таньчжон хоуп для профилактики различных заболеваний</p>
                                        </h1>
                                    </a><p>Восточная медицина считает, что Ки, циркулируя по энергетическим каналам тела,
                                        способствует движению крови, лимфы и питательных веществ в организме. Движение по телу
                                        крови и питательных веществ поддерживает жизнедеятельность человека в норме, и позволяет
                                        организму функционировать. Если циркуляция Ки в организме на каком-либо участке
                                        останавливается, то и останавливается циркуляция крови в этом участке.</p><p>Таким
                                        образом, все патологические изменения, которые происходят в мышцах и сухожилиях и
                                        являются источником болевых синдромов, происходят вследствие неправильной циркуляции Ки.
                                        Нормализовать движение Ки можно различными методами восточной медицины (массаж,
                                        иглотерапия, прижигание и т.&nbsp;д.). Одним из таких методов являются специально
                                        подобранные упражнения системы таньчжон хоуп.</p><p>Предлагаемые читателю упражнения
                                        предназначены для профилактики и лечения наиболее часто встречающихся заболеваний мышц и
                                        суставов, а также некоторых нарушений функций внутренних органов.</p><b>ПОЗИЦИИ НОГ,
                                        ИСПОЛЬЗУЕМЫЕ В УПРАЖНЕНИЯХ</b><b>Чучхум соги</b><p>Расстояние между стоп – 2 ширины
                                        плеч. Стопы параллельны. Колени согнуты. Спина прямая. Вес тела равномерно распределен
                                        на обе ноги.</p><img className="img-content"src={require("./img/works/i_083.png")}/><br/><b>Коа
                                        соги</b><p>Ноги скрещены и согнуты в коленях. Стопы расположены по отношению друг к
                                        другу под углом 90°. Вес тела перемещен на впереди стоящую ногу. Пятка сзади стоящей
                                        ноги оторвана от пола. Спина прямая.</p><img className="img-content" src={require("./img/works/i_084.png")}/><br/><p><b>Упражнение
                                        1</b></p><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч,
                                        руки сжаты в кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но не
                                        напряжен. Взгляд направлен вперед (фото 1).</p><p>1.&nbsp;Согнуть ноги в коленях и
                                        принять позицию «чучхум соги». Одновременно развернуть кисти тыльными сторонам внутрь и
                                        выполнить толкающее движение вперед. При выполнении движения кулаки разжимаются, в
                                        конечной фазе ладони обеих рук направлены вперед (кончики пальцев рук обращены вниз),
                                        (фото 2).</p><img className="img-content"src={require("./img/works/i_085.jpg")}/><br/>
                                        <p>2.&nbsp;Согнуть руки в локтях (фото 3).</p><p>3.&nbsp;Кисти рук сжать в кулаки (фото
                                        4).</p><p>Вернуться в исходное положение.</p><img className="img-content" src={require("./img/works/i_086.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при болях в суставах ног и
                                        рук, наиболее сильное воздействие оказывает на коленные суставы.</i></b></p><p><b><i>Упражнение
                                        разрабатывает локтевые суставы, кисти и пальцы рук, тазобедренные, коленные и
                                        голеностопные суставы, а также укрепляет связки и сухожилия запястий, локтей и коленей,
                                        увеличивает силу рук и ног.</i></b></p><p><b><i>При выполнении упражнения особое
                                        внимание необходимо уделять правильному выполнению позиции «чучхум соги». При движении
                                        рук вперед локти необходимо полностью выпрямить, при этом характер движений должен быть
                                        плавным и естественным, без рывков и ускорений.</i></b></p><p><b><i>При правильном
                                        выполнении упражнения вы должны ощущать чувство Ки в запястьях и четырехглавых мышцах
                                        бедер.</i></b></p><p><b>Упражнение 2</b></p><p>Исходное положение: ноги врозь,
                                        расстояние между стоп – 1,5 ширины плеч, руки сжаты в кулаки у пояса. Плечи расслаблены,
                                        корпус естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото 5).</p>
                                        <p>1.&nbsp;Развернуть туловище влево назад, правую стопу развернуть внутрь на 45°, а
                                            левую – наружу на 180°.</p><p>Согнуть ноги в коленях и принять позицию «коа
                                        соги».</p><p>Одновременно выполнить толкающее движение правой рукой влево. При
                                        выполнении движения кулак правой руки разжимается, в конечной фазе ладонь правой руки
                                        направлена влево. Взгляд направлен влево (фото 6).</p><p>2.&nbsp;Вернуться в исходное
                                        положение (фото 7).</p><img className="img-content"src={require("./img/works/i_087.jpg")}/><br/>
                                        <p>3.&nbsp;Развернуть туловище вправо назад, левую стопу развернуть внутрь на 45°, а
                                            правую – наружу, на 180°.</p><p>Согнуть ноги в коленях и принять позицию «коа
                                        соги».</p><p>Одновременно выполнить толкающее движение левой рукой вправо. При
                                        выполнении движения кулак левой руки разжимается, в конечной фазе ладонь левой руки
                                        направлена вправо. Взгляд направлен вправо (фото 8).</p><p>4.&nbsp;Вернуться в исходное
                                        положение (фото 9).</p><img className="img-content"src={require("./img/works/i_088.jpg")}/><br/><p>
                                        <i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при болях в шее,
                                        плечах, пояснице, а также при болях в суставах рук и ног.</i></b></p><p><b><i>Упражнение
                                        разрабатывает подвижность тазобедренных, коленных и голеностопных суставов ног.</i></b>
                                    </p><p><b><i>При выполнении особое внимание необходимо уделять правильному выполнению
                                        позиции «коа соги» и следить за центром тяжести тела.</i></b></p><p><b><i>При правильном
                                        выполнении упражнения вы должны ощущать чувство Ки в области трех главных суставов
                                        ног.</i></b></p><b>Упражнение 3</b><p>Исходное положение: ноги вместе, руки сжаты в
                                        кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но не напряжен. Взгляд
                                        направлен вперед (фото 10).</p><p>1.&nbsp;Поднять левую руку вверх, ладонь направлена
                                        вверх. Взгляд направлен на тыльную сторону левой кисти (фото 11).</p>
                                        <p>2.&nbsp;Развернуть туловище на 90° вправо (фото 12).</p><img className="img-content"
                                                                                                        src={require("./img/works/i_089.jpg")}/><br/>
                                        <p>3.&nbsp;Выполнить наклон вперед, ладонью левой руки коснуться внешней стороны правой
                                            стопы (фото 13).</p><p>4.&nbsp;Принять исходное положение (фото 14).</p>
                                        <p>5.&nbsp;Поднять правую руку вверх, ладонь направлена вверх. Взгляд направлен на
                                            тыльную сторону правой кисти (фото 15).</p><img className="img-content" src={require("./img/works/i_090.jpg")}/><br/>
                                        <p>6.&nbsp;Развернуть туловище на 90<sup>0</sup> влево (фото 16).</p>
                                        <p>7.&nbsp;Выполнить наклон вперед, ладонью правой руки коснуться внешней стороны левой
                                            стопы (фото 17).</p><p>8.&nbsp;Принять исходное положение (фото 18).</p><img
                                        className="img-content"src={require("./img/works/i_091.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение рекомендуется при болях в плечах, спине, пояснице и
                                        ногах.</i></b></p><p><b><i>Стимулирует циркуляцию Ки и крови в теле.</i></b></p><p>
                                        <b><i>При выполнении упражнения следите, чтобы корпус был прямым, ноги на протяжении
                                            всего упражнения были вместе.</i></b></p><p><b><i>При правильном выполнении
                                        упражнения вы должны ощущать чувство Ки в плечах, спине, пояснице и ногах.</i></b></p>
                                        <b>Упражнение 4</b><p>Исходное положение: ноги врозь, расстояние между стоп – 2,5 ширины
                                        плеч, руки сжаты в кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но
                                        не напряжен. Взгляд направлен вперед (фото 19).</p><p>1.&nbsp;Развернуть туловище влево,
                                        стопу правой ноги развернуть внутрь на 45°, стопу левой ноги развернуть наружу на
                                        90°.</p><p>Согнуть левое колено, центр тяжести тела переместить на левую ногу.</p>
                                        <p>Выполнить толкающее движение влево-вверх правой рукой так, чтобы правая нога и правая
                                            рука были расположены вдоль одной линии. Кулак правой руки разжимается, в конечной
                                            фазе движения ладонь правой руки направлена влево-вверх. Голову развернуть
                                            влево-назад. Взгляд направлен влево-назад (фото 20).</p><p>2.&nbsp;Вернуться в
                                        исходное положение (фото 21).</p><img className="img-content" src={require("./img/works/i_092.jpg")}/><br/>
                                        <p>3.&nbsp;Развернуть туловище вправо, стопу левой ноги развернуть внутрь на 45°, стопу
                                            правой ноги развернуть наружу на 90°.</p><p>Согнуть правое колено, центр тяжести
                                        тела переместить на правую ногу.</p><p>Выполнить толкающее движение вправо-вверх левой
                                        рукой так, чтобы левая нога и левая рука были расположены вдоль одной линии. Кулак левой
                                        руки разжимается, в конечной фазе движения ладонь левой руки направлена вправо-вверх.
                                        Голову развернуть вправо-назад. Взгляд направлен вправо-назад (фото 22).</p>
                                        <p>4.&nbsp;Вернуться в исходное положение (фото 23).</p><img className="img-content"
                                                                                                     src={require("./img/works/i_093.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при ломящих
                                        болях в суставах рук и ног, а также в области шеи, плеч, поясницы и спины.</i></b></p>
                                        <p><b><i>Упражнение разрабатывает суставы запястий, локтей, плеч и трех главных суставов
                                            ног (тазобедренный, коленный, голеностопный).</i></b></p><p><b><i>При выполнении
                                        упражнения пятку от пола не отрывать, сзади стоящую ногу не сгибать в колене, при
                                        вытягивании рук не допускать сгибания в локтевом суставе, корпус держать прямо.</i></b>
                                    </p><p><b><i>При правильном выполнении упражнения вы должны ощущать чувство Ки в области
                                        шеи, плеч, поясницы и ногах.</i></b></p><b>Упражнение 5</b><p>Исходное положение: ноги
                                        вместе, руки на поясе, большие пальцы рук направлены назад, плечи расслаблены, корпус
                                        естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото 24).</p>
                                        <p>1.&nbsp;Согнуть левую ногу в колене, поднять вверх (фото 25).</p><p>2.&nbsp;Выпрямить
                                        ногу под углом 45° в направлении вправо-вперед-вниз (фото 26).</p><img
                                        className="img-content"src={require("./img/works/i_094.jpg")}/><br/><p>3.&nbsp;Вернуться в исходное
                                        положение (фото 27).</p><p>4.&nbsp;Согнуть правую ногу в колене, поднять вверх (фото
                                        28).</p><p>5.&nbsp;Выпрямить ногу под углом 45° в направлении влево-вперед-вниз (фото
                                        29).</p><img className="img-content"src={require("./img/works/i_095.jpg")}/><br/>
                                        <p>6.&nbsp;Вернуться в исходное положение (фото 30).</p><img className="img-content" src={require("./img/works/i_096.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при
                                        заболеваниях ног, в частности, при болях в коленных суставах, повреждении жировой
                                        прослойки под чашечкой, слабости мышц ног.</i></b></p><p><b><i>Упражнение разрабатывает
                                        суставы коленей, укрепляет мышцы ног.</i></b></p><p><b><i>При выполнении упражнения
                                        сохраняйте равновесие, следите, чтобы движения ног были медленными и плавными, корпус
                                        прямым.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны ощущать
                                        чувство Ки в ногах.</i></b></p><b>Упражнение 6</b><p>Исходное положение: ноги вместе,
                                        руки на поясе. Плечи расслаблены, корпус естественно выпрямлен, но не напряжен. Взгляд
                                        направлен вперед (фото 31).</p><p>1.&nbsp;Согнуть левую ногу в колене, поднять вверх
                                        так, чтобы внутренняя сторона левой стопы была направлена вверх (фото 32).</p>
                                        <p>2.&nbsp;Вернуться в исходное положение (фото 33).</p><img className="img-content"
                                                                                                     src={require("./img/works/i_097.jpg")}/><br/>
                                        <p>3.&nbsp;Согнуть правую ногу в колене, поднять вверх так, чтобы внутренняя сторона
                                            правой стопы была направлена вверх (фото 34).</p><p>4.&nbsp;Вернуться в исходное
                                        положение (фото 35).</p><p>5.&nbsp;Согнуть левую ногу в колене, поднять вверх, стопу
                                        левой ноги отвести влево, насколько позволит коленный сустав левой ноги (фото 36).</p>
                                        <img className="img-content"src={require("./img/works/i_098.jpg")}/><br/><p>6.&nbsp;Вернуться в
                                        исходное положение (фото 37).</p><p>7.&nbsp;Согнуть правую ногу в колене, поднять вверх,
                                        стопу правой ноги отвести вправо, насколько позволит коленный сустав правой ноги (фото
                                        38).</p><p>8.&nbsp;Вернуться в исходное положение (фото 39).</p><img
                                        className="img-content"src={require("./img/works/i_099.jpg")}/><br/><p>9.&nbsp;Поднять прямую левую
                                        ногу перед собой вверх до уровня нижней части живота (фото 40).</p><p>10.&nbsp;Вернуться
                                        в исходное положение (фото 41).</p><p>11.&nbsp;Поднять прямую правую ногу перед собой
                                        вверх до уровня нижней части живота (фото 42).</p><img className="img-content" src={require("./img/works/i_100.jpg")}/><br/>
                                        <p>12.&nbsp;Вернуться в исходное положение (фото 43).</p><p>13.&nbsp;Согнуть правую ногу
                                        в колене, пяткой стараясь коснуться ягодицы (фото 44).</p><p>14.&nbsp;Вернуться в
                                        исходное положение (фото 45).</p><img className="img-content"
                                                                              src={require("./img/works/i_101.jpg")}/><br/>
                                        <p>15.&nbsp;Согнуть левую ногу в колене, пяткой стараясь коснуться ягодицы (фото
                                            46).</p><p>16.&nbsp;Вернуться в исходное положение (фото 47).</p><img
                                        className="img-content"src={require("./img/works/i_102.jpg")}/><br/><p><i>Выполнить упражнение 8
                                        раз.</i></p><p><b><i>Упражнение рекомендуется при ощущении слабости в ногах, болях в
                                        коленных и тазобедренных суставах.</i></b></p><p><b><i>Основная цель этого упражнения –
                                        тренировка способности тазобедренных, коленных и голеностопных суставов совершать
                                        движения вперед, назад, влево, вправо. Упражнение также повышает прочность и
                                        эластичность суставов ног.</i></b></p><p><b><i>При выполнении упражнения следите за
                                        соблюдением равновесия и прямым положением корпуса.</i></b></p><p><b><i>При правильном
                                        выполнении упражнения вы должны ощущать чувство Ки в ногах.</i></b></p><b>Упражнение
                                        7</b><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч, руки
                                        сжаты в кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но не напряжен.
                                        Взгляд направлен вперед (фото 48).</p><p>1.&nbsp;Поднять руки перед собой вверх, кулаки
                                        разжать, большой и указательный пальцы каждой руки максимально развести, четыре пальца
                                        каждой руки прижаты друг к другу, голова поднята вверх, взгляд направлен на тыльные
                                        стороны кистей рук (фото 49).</p><p>2.&nbsp;Развести руки в стороны, пальцы обеих рук
                                        оттянуть на себя, одновременно с движением рук корпус развернуть влево, взгляд направлен
                                        на тыльную сторону кисти левой руки (фото 50).</p><img className="img-content"
                                                                                               src={require("./img/works/i_103.jpg")}/><br/>
                                        <p>3.&nbsp;Вернуться в исходное положение (фото 51).</p><p>4.&nbsp;Поднять руки перед
                                        собой вверх, кулаки разжать, большой и указательный пальцы каждой руки максимально
                                        развести, четыре пальца каждой руки прижаты друг к другу, голова поднята вверх, взгляд
                                        направлен на тыльные стороны кистей рук (фото 52).</p><p>5.&nbsp;Развести руки в
                                        стороны, пальцы обеих рук оттянуть на себя, одновременно с движением рук корпус
                                        развернуть вправо, взгляд направлен на тыльную сторону кисти правой руки (фото 53).</p>
                                        <img className="img-content"src={require("./img/works/i_104.jpg")}/><br/><p>6.&nbsp;Вернуться в
                                        исходное положение (фото 54).</p><img className="img-content"
                                                                              src={require("./img/works/i_105.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при заболевании сухожилий
                                        запястий и пальцев рук, способствует излечиванию асептического воспаления сухожильных
                                        влагалищ.</i></b></p><p><b><i>Упражнение тренирует мышцы и суставы рук, укрепляет
                                        структуру сухожилий запястий и пальцев рук. При выполнении упражнения следите, чтобы в
                                        фазе вытягивания рук они были расположены вдоль одной линии, спина должна быть прямая,
                                        ладони вертикальны.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны
                                        ощущать сильно выраженное чувство Ки в области запястий и пальцев рук.</i></b></p><b>Упражнение
                                        8</b><p>Исходное положение: ноги вместе, руки свободно опущены. Плечи расслаблены,
                                        корпус естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото 55).</p>
                                        <p>1.&nbsp;Отставить левую ногу в сторону, одновременно руки скрестить перед грудью
                                            (фото 56).</p><p>2.&nbsp;Принять позицию «чучхум соги». Одновременно левую руку
                                        вытянуть влево, кисть левой руки расположена вертикально, ладонь направлена влево.
                                        Правую руку согнуть в локте, кисть сжать в кулак и выполнить толкающее движение локтем
                                        правой руки вправо. Взгляд направлен на кисть левой руки (фото 57).</p><img
                                        className="img-content"src={require("./img/works/i_106.jpg")}/><br/><p>3.&nbsp;Опустить руки через
                                        стороны вниз, одновременно выпрямляя ноги (фото 58).</p><p>4.&nbsp;Принять исходное
                                        положение (фото 59).</p><p>5.&nbsp;Отставить правую ногу в сторону, одновременно руки
                                        скрестить перед грудью (фото 60).</p><img className="img-content"
                                                                                  src={require("./img/works/i_107.jpg")}/><br/>
                                        <p>6.&nbsp;Принять позицию «чучхум соги». Одновременно правую руку вытянуть вправо,
                                            кисть правой руки расположена вертикально, ладонь направлена влево. Левую руку
                                            согнуть в локте, кисть сжать в кулак и выполнить толкающее движение локтем левой
                                            руки влево. Взгляд направлен на кисть правой руки (фото 61).</p><p>7.&nbsp;Опустить
                                        руки через стороны вниз, одновременно выпрямляя ноги (фото 62).</p><p>8.&nbsp;Принять
                                        исходное положение (фото 63).</p><img className="img-content" src={require("./img/works/i_108.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при заболеваниях сухожилий
                                        рук. Тренирует сгибательно-разгибательную функцию рук, улучшает деятельность связок
                                        запястий.</i></b></p><p><b><i>При выполнении упражнения следите за горизонтальным
                                        расположением рук и вертикальным расположением кистей рук, при опускании рук вниз не
                                        сгибайте руки в локтях, спину держите прямо.</i></b></p><p><b><i>При правильном
                                        выполнении упражнения вы должны ощущать чувство Ки в руках, особенно в запястьях и
                                        пальцах рук.</i></b></p><b>Упражнение 9</b><p>Исходное положение: ноги врозь, расстояние
                                        между стоп – 1,5 ширины плеч, руки сжаты в кулаки у пояса. Плечи расслаблены, корпус
                                        естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото 64).</p>
                                        <p>1.&nbsp;Раскрыть ладони, руки подняты вверх, ладони обеих рук обращены друг к другу.
                                            Голову поднять вверх, взгляд направлен вверх (фото 65).</p><p>2.&nbsp;Кисти рук
                                        сжать в кулаки, кулаки развернуть тыльной стороной друг к другу (фото 66).</p><img
                                        className="img-content"src={require("./img/works/i_109.jpg")}/><br/><p>3.&nbsp;Опустить руки через
                                        стороны вниз (фото 67).</p><p>4.&nbsp;Принять исходное положение (фото 68).</p>
                                        <p>4.&nbsp;Разжать кулаки, руки опустить вниз, ладони направлены наружу (фото 69).</p>
                                        <img className="img-content"src={require("./img/works/i_110.jpg")}/><br/><p>5.&nbsp;Не прекращая
                                        движения, поднять руки через стороны вверх, ладони обращены друг к другу (фото 70).</p>
                                        <p>6.&nbsp;Кисти рук сжать в кулаки, согнуть в запястьях так, чтобы тыльные стороны
                                            кистей рук были обращены друг к другу и перед собой опустить руки вниз (фото
                                            71).</p><p>7.&nbsp;Развести руки в стороны под углом 45° (фото 72).</p><img
                                        className="img-content"src={require("./img/works/i_111.jpg")}/><br/><p>8.&nbsp;Вернуться в исходное
                                        положение (фото 73).</p><img className="img-content"src={require("./img/works/i_112.jpg")}/><br/><p>
                                        <i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при воспалении
                                        сухожилий в запястьях.</i></b></p><p><b><i>Упражнение улучшает вращательные способности
                                        запястий и локтей.</i></b></p><p><b><i>При выполнении упражнений не сгибайте руки в
                                        локтях.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны ощущать
                                        сильно выраженное чувство Ки в плечах, локтях и запястьях.</i></b></p><b>Упражнение
                                        10</b><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч, руки
                                        сжаты в кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но не напряжен.
                                        Взгляд направлен вперед (фото 74).</p><p>1.&nbsp;Разжать кулак правой руки и выпрямить
                                        руку вперед-вверх, ладонь правой руки направлена вперед. Одновременно левую руку
                                        назад-вниз, кисть левой руки, сжатую в кулак, согнуть в запястье (фото 75).</p>
                                        <p>2.&nbsp;Вернуться в исходное положение (фото 76).</p><img className="img-content"
                                                                                                     src={require("./img/works/i_113.jpg")}/><br/>
                                        <p>3.&nbsp;Разжать кулак левой руки и выпрямить руку вперед-вверх, ладонь левой руки
                                            направлена вперед. Одновременно правую руку назад-вниз, кисть правой руки, сжатую в
                                            кулак, согнуть в запястье (фото 77).</p><p>4.&nbsp;Вернуться в исходное положение
                                        (фото 78).</p><img className="img-content"src={require("./img/works/i_114.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при воспалении сухожилий
                                        запястий и болях в области поясницы.</i></b></p><p><b><i>Упражнение оказывает
                                        благотворное воздействие на сухожилия локтей, запястий и пальцев рук.</i></b></p><p>
                                        <b><i>При правильном выполнении упражнения вы должны ощущать сильно выраженное чувство
                                            Ки в области локтей, запястий и пальцев рук.</i></b></p><b>Упражнение 11</b>
                                        <p>Исходное положение: ноги врозь, расстояние между стоп – 2 ширины плеч, руки сжаты в
                                            кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но не напряжен.
                                            Взгляд направлен вперед (фото 79).</p><p>1.&nbsp;Согните ноги в коленях и примите
                                        позицию «чучхум соги». Одновременно выполните левой рукой прямой удар вперед. Тыльная
                                        сторона кулака обращена вверх (фото 80).</p><p>2.&nbsp;Кисть развернуть ладонью вверх и
                                        вернуть руку в положение у пояса по траектории удара (фото 81).</p><img
                                        className="img-content"src={require("./img/works/i_115.jpg")}/><br/><p>3.&nbsp;Принять исходное
                                        положение (фото 82).</p><p>4.&nbsp;Согните ноги в коленях и примите позицию «чучхум
                                        соги». Одновременно выполните правой рукой прямой удар вперед. Тыльная сторона кулака
                                        обращена вверх (фото 83).</p><p>5.&nbsp;Кисть развернуть ладонью вверх и вернуть руку в
                                        положение у пояса по траектории удара (фото 84).</p><img className="img-content" src={require("./img/works/i_116.jpg")}/><br/>
                                        <p>6.&nbsp;Принять исходное положение (фото 85).</p><img className="img-content"
                                                                                                 src={require("./img/works/i_117.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Это упражнение широко используется во
                                        многих видах корейских боевых искусств. Оно оказывает комплексное оздоровительное
                                        воздействие на организм, развивает способность сжимать руки в кулаки и бить ими, а также
                                        увеличивает силу мышц рук и ног.</i></b></p><p><b><i>При выполнении упражнения следите,
                                        чтобы движения рук были плавными и ровными, без рывков и ускорений, корпус
                                        прямым.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны ощущать
                                        сильно выраженное чувство Ки в области ног, плеч и запястий.</i></b></p><b>Упражнение
                                        12</b><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч, руки
                                        свободно опущены вдоль тела. Плечи расслаблены, корпус естественно выпрямлен, но не
                                        напряжен. Взгляд направлен вперед (фото 86).</p><p>1.&nbsp;Поднять руки через стороны до
                                        уровня плеч, одновременно развернуть корпус влево (фото 87).</p><img
                                        className="img-content"src={require("./img/works/i_118.jpg")}/><br/><p>2.&nbsp;Ладонью правой руки в
                                        области соединения большого и указательного пальцев коснуться левого плеча, левую руку
                                        согнуть в локте и прижать в области крестца тыльной стороной. Взгляд направлен влево
                                        (фото 88).</p><p>3.&nbsp;Выпрямить руки в стороны и развернуть корпус вправо (фото
                                        89).</p><img className="img-content"src={require("./img/works/i_119.jpg")}/><br/><p>4.&nbsp;Ладонью
                                        левой руки в области соединения большого и указательного пальцев коснуться правого
                                        плеча, правую руку согнуть в локте и прижать в области крестца тыльной стороной. Взгляд
                                        направлен вправо (фото 90).</p><p>5.&nbsp;Вернуться в исходное положение (фото 91).</p>
                                        <img className="img-content"src={require("./img/works/i_120.jpg")}/><br/><p><i>Выполнить упражнение
                                        8 раз.</i></p><p><b><i>Упражнение рекомендуется для стимулирования защитных функций
                                        организма при ослабленном состоянии, эффективно при плечевом периартрите и болях в спине
                                        и пояснице.</i></b></p><p><b><i>При выполнении упражнения следите за расслаблением рук и
                                        прямым положением корпуса.</i></b></p><p><b><i>При правильном выполнении упражнения вы
                                        должны ощущать сильно выраженное чувство Ки в области шеи, плечах, локтях, запястьях и
                                        пояснице.</i></b></p><b>Упражнение 13</b><p>Исходное положение: ноги вместе, руки сжаты
                                        в кулаки у пояса. Плечи расслаблены, корпус естественно выпрямлен, но не напряжен.
                                        Взгляд направлен вперед (фото 92).</p><p>1.&nbsp;Перенести центр тяжести тела на левую
                                        ногу, правую ногу согнуть в колене и поднять на уровень пояса. Одновременно разжать
                                        кулаки обеих рук, левую руку выпрямить вверх, правую руку выпрямить вниз, ладонь левой
                                        руки направлена вверх, ладонь правой руки направлена вниз. Взгляд направлен на ладонь
                                        левой руки (фото 93).</p><p>2.&nbsp;Вернуться в исходное положение (фото 94).</p><img
                                        className="img-content"src={require("./img/works/i_121.jpg")}/><br/><p>3.&nbsp;Перенести центр
                                        тяжести тела на правую ногу, левую ногу согнуть в колене и поднять на уровень пояса.
                                        Одновременно разжать кулаки обеих рук, правую руку выпрямить вверх, левую руку выпрямить
                                        вниз, ладонь правой руки направлена вверх, ладонь левой руки направлена вниз. Взгляд
                                        направлен на ладонь правой руки (фото 95).</p><p>4.&nbsp;Вернуться в исходное положение
                                        (фото 96).</p><img className="img-content"src={require("./img/works/i_122.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при нервном переутомлении,
                                        одышке, болях в области брюшной полости.</i></b></p><p><b><i>При выполнении этого
                                        упражнения увеличивается амплитуда диафрагмы, улучшаются функции сердца и
                                        легких.</i></b></p><p><b><i>При выполнении упражнения следите, чтобы ноги были не
                                        согнуты, корпус не отклонялся вправо или влево. Дыхание должно быть спокойным и
                                        ровным.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны ощущать
                                        сильно выраженное чувство Ки в области груди и живота.</i></b></p><b>Упражнение 14</b>
                                        <p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч, руки
                                            свободно опущены вдоль тела. Плечи расслаблены, корпус естественно выпрямлен, но не
                                            напряжен. Взгляд направлен вперед (фото 97).</p><p>1.&nbsp;Соединить пальцы рук,
                                        поднять руки перед собой вверх (фото 98).</p><p>2.&nbsp;Пятки оторвать от пола, руки
                                        развести в стороны чуть выше плеч. Все тело вытянуть вверх. Движение сопровождать
                                        глубоким и спокойным вдохом (фото 99).</p><img className="img-content"
                                                                                       src={require("./img/works/i_123.jpg")}/><br/>
                                        <p>3.&nbsp;Соединить пальцы рук и опустить руки перед собой вниз, пятки опустить на пол,
                                            движение сопровождать глубоким медленным выдохом (фото 100).</p><p>4.&nbsp;Вернуться
                                        в исходное положение (фото 101).</p><img className="img-content" src={require("./img/works/i_124.jpg")}/><br/><p><i>Выполнить
                                        упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при нервозности, затруднения
                                        дыхания, заболеваниях органов дыхания и нарушениях системы кровообращения.</i></b></p>
                                        <p><b><i>При выполнении упражнения следите, чтобы дыхание было медленным и ровным, руки
                                            прямыми, движения плавными.</i></b></p><p><b><i>При правильном выполнении упражнения
                                        вы должны ощущать сильно выраженное чувство комфорта в груди.</i></b></p><b>Упражнение
                                        15</b><p>Исходное положение: ноги врозь, расстояние между стоп – 1,5 ширины плеч, руки
                                        свободно опущены вдоль тела. Плечи расслаблены, корпус естественно выпрямлен, но не
                                        напряжен. Взгляд направлен вперед (фото 102).</p><p>1.&nbsp;Поднять прямые руки перед
                                        собой вверх, ладони обращены друг к другу. Взгляд направлен вперед (фото 103).</p>
                                        <p>2.&nbsp;Кисти рук сжать в кулаки, согнуть руки в локтях и опустить руки вниз до
                                            уровня плеч. Взгляд направлен вперед (фото 104).</p><img className="img-content"
                                                                                                     src={require("./img/works/i_125.jpg")}/><br/>
                                        <p>3.&nbsp;Поднять голову вверх, свести лопатки. Взгляд направлен вверх (фото 105).</p>
                                        <p>4.&nbsp;Вернуться в исходное положение (фото 106).</p><img className="img-content" src={require("./img/works/i_126.jpg")}/><br/>
                                        <p><i>Выполнить упражнение 8 раз.</i></p><p><b><i>Упражнение рекомендуется при
                                        расстройствах нервной системы, бессоннице, головокружении.</i></b></p><p><b><i>Основная
                                        цель этого упражнения – нормализовать движение Ки и крови в организме.</i></b></p><p><b><i>При
                                        выполнении упражнения следите за соблюдением равновесия и прямым положением корпуса. При
                                        движении рук вниз ладони сжимать в кулаки синхронно с движением рук.</i></b></p><p>
                                        <b><i>При правильном выполнении упражнения вы должны ощущать чувство Ки в руках, в
                                            области лопаток и шее.</i></b></p><b>Упражнение 16</b><p>Исходное положение: ноги
                                        врозь, расстояние между стоп – 1,5 ширины плеч. Кисти рук сложены друг на друга в
                                        области живота, основание левой ладони оказывает давление на тыльную сторону правой
                                        кисти. Корпус естественно выпрямлен, но не напряжен. Взгляд направлен вперед (фото
                                        107).</p><img className="img-content" src={require("./img/works/i_127.jpg")}/><br/><p><i>Выполните 8
                                        круговых движений руками по часовой стрелке.</i></p><p><b><i>Упражнение рекомендуется
                                        при нарушении функций желудочно-кишечного тракта, плохом аппетите, желудочных болях.</i></b>
                                    </p><p><b><i>Во время выполнения упражнения ладони должны быть плотно прижаты к животу,
                                        взгляд направлен строго вперед, внимание должно быть сконцентрировано на области живота,
                                        корпус – прямой.</i></b></p><p><b><i>При правильном выполнении упражнения вы должны
                                        ощущать чувство Ки в области живота.</i></b></p><a name="metkadoc5">
                                        <h1><p>Муксан</p></h1>
                                    </a><p>Исходное положение: сидя на полу, колени врозь, голени скрестить. Глаза закрыты,
                                        подбородок опущен. Спина прямая, мышцы расслаблены. Кисти рук на коленях, ладони
                                        направлены вверх. Сконцентрируйте внимание на нижней области живота. Дыхание мягкое и
                                        ровное. Ваш ум ясен и чист, сердце безмятежно, дух спокоен. Находитесь в этом состоянии
                                        от трех до пяти минут (фото 108).</p><img className="img-content" src={require("./img/works/i_128.jpg")}/><br/><p><b><i>Задача
                                        этого упражнения добиться полного расслабления и погружения в состояние покоя.</i></b>
                                    </p><br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Tsigun;