import React from 'react';
import {bodyClick} from "../app/app";

const Friends = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Наші друзі</span>
                                            <span className="ru">Наши друзья</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-сакура-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                        <h5>Наші друзі</h5><br/>
                                    </div>

                                    <div className="service-desc ru">
                                        <h5>Наши друзья</h5><br/><br/>

                                        {/*<h5>Центр Развития «Цветок Жизни»</h5><br/><br/>
                                        <a href='https://flower-life.com.ua/' target='_blank'>https://flower-life.com.ua/</a><br/><br/>
                                        Телефон для записи на программы центра развития: +380(99)762-51-55<br/><br/>
                                        <br/><br/><img src={require("./img/friends/flower.jpg")}
                                                       className="img-content"/><br/><br/>*/}
                                        <h5>«Cat House»</h5><br/>
                                        Так радостно общаться со скунсом Монаной! Эта девочка любит яблочки, хотя и
                                        хищник.
                                        Приходите к нам в котокафе "Cathouse" по адресу: проспект Александра Поля
                                        135.<br/>
                                        <br/><br/><img src={require("./img/skuns.jpg")}
                                                       className="img-content"/><br/><br/>

                                        <h5>Marcus King - наш друг из США</h5> <img className="icon-center"
                                                                                    src={require("./img/icons/united-states-flag-48x48.png")}
                                                                                    alt=""/><br/>

                                        Клуб Kyong-Ki придерживается движения популяризации Taekwon-Do во всем мире и
                                        предпринимает активные действия в этом направлении!<br/>
                                        У нас занимаются представители не только нашей страны.<br/>
                                        Хотим познакомить вас с нашим другом из Филадельфии - Маркусом.<br/>

                                        <br/><br/><img src={require("./img/friends/photo_2019-05-18_11-45-19.jpg")}
                                                       className="img-content"/><br/><br/>
                                        My name is Marcus King, I am from the United States. Born and raised in
                                        Philadelphia Pennsylvania.<br/>
                                        I began training Taekwondo in 2004 under Sabumnim Miguel Montero and Sabumnim
                                        Andrew Smith of Montero’s Taekwondo in North Philadelphia.<br/>
                                        But in 2006, I had to stop training to focus more on graduating from the
                                        university.<br/>
                                        After graduating from the university in 2009,
                                        I had resumed training with Sahyunnym Marcello Cancelliere and Bosabum Mohammad
                                        J. Ali of Red Tiger Taekwondo. I had the pleasure to train under some world
                                        champions in Philadelphia.<br/>
                                        In 2015, I moved to Tucson, Arizona and began training under Grandmaster Brian
                                        Malm and Grandmaster Mark Plante of Desert Taekwondo Southwest.<br/>
                                        It was here that I had received my first degree black belt from Grandmaster Malm
                                        in 2016.<br/>
                                        After immigrating here in Ukraine to be with my wife, I am honored to join
                                        Taekwondo here in Dnipropetrovsk.<br/><br/>

                                        Bosabum Marcus King<br/><br/>
                                        <br/><br/><img src={require("./img/friends/photo_2019-05-18_11-43-57.jpg")}
                                                       className="img-content"/><br/><br/>

                                        <h5>«Cat House»</h5><br/>
                                        В Днепре на проспекте А. Поля, 135 появилось «Cat House». Это могло быть
                                        очередное антикафе, но в этом заведении есть одна особенность. Кафе не для
                                        людей, а для котов в первую очередь. В помещении живут
                                        Сноу, Арья, Лео, Барби, Игрит, Тирион, Дейенерис, Санса — все они коты редкой
                                        бенгальской породы. Есть еще и Фрози и Миша — породы Чаузи Ф2Лео. Они молоды и
                                        им до двух лет, но есть совсем маленький котенок 3-4 месяцев. Кафе заработало в
                                        ноябре.<br/>
                                        Есть два зала с котиками, где оплата идет за время пребывания. Отдельно — зал,
                                        где размещается кофейня. Делать в антикафе можно все, только при этом, не
                                        вредить котикам. Там можно выпить кофе, поработать за компьютером, а если
                                        устаешь — поиграться с котиками. Можно просто за ними наблюдать или погладить.
                                        Кафе рассчитано и на детей, но до 12 лет они могут находится с котами лишь под
                                        присмотром родителей. По выходным проводятся различные мастер-классы для
                                        деток.<br/>
                                        Следует помнить, что помещение для котов. Люди здесь лишь гости. Нарушать покой
                                        котов строго запрещено. Их нельзя брать на руки и переносить. Только если это
                                        свободолюбивое животное к вам само пришло, тогда его можно погладить. Строго
                                        запрещено в заведение приходить посетителям в состоянии алкогольного
                                        опьянения.<br/>
                                        <br/><br/><img
                                        src={require("./img/46624980_1894241750659236_5765595949627867136_n.jpg")}
                                        className="img-content"/><br/><br/>
                                        Котокафе — место, где любители кошек могут посмотреть на этих домашних животных
                                        и поиграть с ними. Иногда домовладельцы по разным причинам не разрешают жильцам
                                        содержать домашних животных, в частности кошек. Одна из целей создания котокафе
                                        — удовлетворить тягу людей к этим животным.<br/>
                                        Главная особенность заведения это — бенгальские кошки. Окрас от мраморного до
                                        снежно-белого. Пока в кафе 10 котиков, но в скором времени их будет уже 15.
                                        Съехались зверушки в Днепр из разных уголков Украины.<br/>
                                        Все котята в кафе элитного класса, и стоят от 500 и до 1500 евро. Кушают они два
                                        раза в день куриное мясо, но скоро их переведут на трехразовое питание.<br/>

                                        Характер бенгальских кошек объединяет темперамент дикого зверя и домашнего
                                        животного. У бенгалов хорошо развит охотничий инстинкт. В любом возрасте они
                                        признают игры «на охоту» — гонки за мячами и игрушками, пакетами, ловлю махалок,
                                        погони и преследования. Вопреки слухам, бенгалы не кровожадны и не агрессивны, а
                                        даже совсем наоборот. Никакой опасности для детей и домашних животных не
                                        представляют. В зале есть много различных игрушек, с помощью которых и можно
                                        играть с животными. Распорядок дня у котиков следующий:<br/>
                                        завтрак 10.30, а потом 3-4 часа игры. У кого-то — это пробежка в колесе.<br/>
                                        С 15.00 — 17.00 — сон.<br/>
                                        Затем снова период активности.<br/>
                                        Для людей, которые тоже не прочь поиграть, в кафе есть настольная игра Башня (с
                                        квадратным сечением брусков). Кстати, коты тоже в нее любят играть и с
                                        удовольствием составят вам компанию.<br/>
                                        <br/>
                                        Павел Швец, один из соучредителей и управляющий кафе, рассказал, что идея
                                        открыть заведение родилась у другого соучредителя — Светланы Золотухиной, до
                                        этого она открывала подобное кафе в Одессе.<br/>
                                        Светлана хотела открыть подобное заведение в Днепре, чтобы люди могли прийти и
                                        отдохнуть с кошечками и посмотреть на этих удивительных животных, провести с
                                        ними время, — отметил Павел.<br/>
                                        Перед тем как зайти в зоны, где обитают котики необходимо надеть бахилы, а в
                                        ближайшее время появятся домашние тапочки. Также продезинфицировать руки
                                        антисептиком. Все это — для того, чтобы не передавать инфекцию котикам, которые
                                        очень восприимчивы.<br/>
                                        Фасад кафе украсил художник Валентин Филоненко. Реализации проекта слегка мешала
                                        лишь погода, но Валентин все равно справился за полторы недели. Дождь смывал
                                        рисунок, но художник все восстанавливал. На третий день нанесения рисунка,
                                        кто-то написал рекламу Telegram канала, но ее тоже ликвидировали. Но на этом он
                                        не прекращает сотрудничество с антикафе. Теперь Валентин Филоненко готовит серию
                                        из семи картин, с изображением популярных персонажей котов.<br/>
                                        <br/><br/><img
                                        src={require("./img/46104942_312829789332881_8929175916769181696_n.jpg")}
                                        className="img-content"/><br/><br/>
                                        Кошачья тематика прослеживается во всем — и в названии кофе, и в сувенирах,
                                        которых много представлено от мастеров из Днепра. Те, кто творит на кошачью
                                        тематику, могут выставить произведения в заведении.<br/>
                                        Справка: первое в мире котокафе было открыто на Тайване в 1998 году и
                                        располагалось в городе Тайбэй. Оно стало известным местом и привлекало туристов
                                        из Японии. Первое такое кафе на Японских островах было открыто в Осаке в 2004
                                        году. В японских кафе за почасовую оплату можно поиграть с любой понравившейся
                                        кошкой или покормить её.<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Friends;
