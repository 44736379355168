import React from 'react';
import './spinner.css';

const Spinner = () => {
  return (
      <div className="loadingio-spinner-ripple-78lphliuwla">
          <div className="ldio-9yhdryxy226">
              <div></div>
              <div></div>
          </div>
      </div>
  );
};

export default Spinner;