import React from 'react';
import {bodyClick} from "../app/app";

const News = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Новини Таеквон-До</span>
                                            <span className="ru">Новости Таэквон-До</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-журнал-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>Новости Таэквон-До</h5><br/><br/>

                                        {/*<h5>Аттестация 19 декабря 2021 года.</h5>
                                        <br/>
                                        Роман Безбражный - 3 Дан
                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Roman 3 Dan/1.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Roman 3 Dan/2.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Roman 3 Dan/3.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>
*/}
                                        <h5>65-th Anniversary Of Taekwon-Do </h5><br/>

                                        <br/><br/>
                                        <img src={require("./img/news/65th Anniversary TKND/photo_2020-04-11_19-20-46.jpg")} alt=""
                                             className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>
                                        <h5>Club Kyong-Ki with the best regards to Vitalii Kryvulia!</h5>
                                        <br/>
                                        Together we are strong!
                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Vitaliy/photo_2020-10-02_10-48-48.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Вручение сертификатов по результатам январской
                                            аттестации клуба Kyong-Ki 25 января 2020 года.</h5>
                                        <br/>
                                        Вадим Лобода - 1 Дан
                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Attestation 25.01.2020/2.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>65-th Anniversary Of Taekwon-Do </h5><br/>

                                        <br/><br/>
                                        <img src={require("./img/news/65th Anniversary TKND/photo_2020-04-11_19-20-46.jpg")} alt=""
                                             className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Результаты клуба Kyong-Ki по итогам "Чемпионата Днепра по Таеквон-До ИТФ" 2
                                            февраля
                                            2020 года.</h5><br/>

                                        Гупка Никита - 1 место<br/>
                                        Бриштан Кристина - 1 место<br/>
                                        Коломоец Ульяна - 1 место<br/>
                                        Даценко Илья - 1 место<br/>
                                        Гусейнов Илчин - 2 место<br/>
                                        Половинка Катя - 2 место<br/>
                                        Гуляев Саша - 2 место<br/>
                                        Песанко Егор - 2 место<br/>
                                        Захарченко Олег - 3 место<br/>

                                        <br/><br/>
                                        <img src={require("./img/news/Чемпионат Днепра 2020 2 февраля/8360729.jpg")} alt=""
                                             className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Результаты аттестации клуба Kyong-Ki 25 января 2020 года.</h5><br/>

                                        Вадим Лобода - 1 Дан<br/>
                                        Гусейнов Илчин - 5 Куп<br/>
                                        Алексей Нарбутович - 8 Куп

                                        <br/><br/>
                                        <img src={require("./img/news/Attestation 25.01.2020/1.jpg")} alt=""
                                             className="img-content"/>
                                        <img
                                            src={require("./img/news/Attestation 25.01.2020/photo_2020-01-25_13-00-13.jpg")}
                                            alt="" className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Результаты клуба Kyong-Ki по итогам "Кубка Украины по Таеквон-До ИТФ" 28 октября
                                            2019 года.</h5><br/>

                                        Гупка Никита: спарринг - 2 место

                                        <br/><br/>
                                        <img src={require("./img/news/Кубок Украины 2019 Днепр/73358292.jpg")}
                                             className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>2019 ITF Taekwon-Do World Championship, Plovdiv, Bulgaria</h5><br/>
                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Plovdiv 2019 WC/68634183_10206239990756874_3942706817255079936_n.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Вручение сертификатов по результатам декабрьской
                                            аттестации клуба Kyong-Ki 08 июля 2019 года.</h5>
                                        <br/>

                                        Роман Безбражный - 2 Дан<br/>
                                        Константин Лобода - 1 Дан<br/>
                                        Виталий Кривуля - 1 Дан

                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Вручение сертификатов 08.07.2019/66173687_2236227859793955_7556672345138528256_n.jpg")}
                                            className="img-content"/>
                                        <img
                                            src={require("./img/news/Вручение сертификатов 08.07.2019/66791060_2236227943127280_2574369140563247104_n.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Результаты клуба Kyong-Ki по
                                            итогам "Чемпионата
                                            Сичеславской области по
                                            Таеквон-До ИТФ среди
                                            детей, юношей и взрослых" 03
                                            февраля 2019 года.</h5><br/>

                                        Мазуленко Максим: спарринг - 3
                                        место<br/>
                                        Харитонов Никита: спарринг - 3
                                        место<br/>
                                        Гусейнов Илчин: спарринг - 1
                                        место<br/>
                                        Гупка Никита: спарринг - 1 место

                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Чемпионат Днепровской области 2019/photo_2019-02-04_15-18-43.jpg")}
                                            className="img-content"/>
                                        <img
                                            src={require("./img/news/Чемпионат Днепровской области 2019/photo_2019-02-04_15-19-27.jpg")}
                                            className="img-content"/>
                                        <img
                                            src={require("./img/news/Чемпионат Днепровской области 2019/photo_2019-02-04_16-13-05.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Результаты
                                            клуба
                                            Kyong-Ki
                                            по
                                            итогам
                                            "Чемпионата
                                            Запорожской
                                            области
                                            по
                                            Таеквон-До
                                            ИТФ
                                            среди
                                            детей,
                                            юношей
                                            и
                                            взрослых"
                                            02
                                            февраля
                                            2019
                                            года.</h5>
                                        <br/>

                                        Дима
                                        Степаненков:
                                        спарринг
                                        - 1
                                        место,
                                        туль
                                        - 2
                                        место<br/>
                                        Тина
                                        Свирида:
                                        спарринг
                                        - 1
                                        место,
                                        туль
                                        - 3
                                        место<br/>
                                        Захар
                                        Петренко:
                                        спарринг
                                        - 1
                                        место<br/>

                                        <br/><br/>
                                        <img
                                            src={require("./img/news/Чемпионат Запорожской области 2019/photo_2019-02-04_17-11-02.jpg")}
                                            className="img-content"/>
                                        <img
                                            src={require("./img/news/Чемпионат Запорожской области 2019/photo_2019-02-04_17-13-12.jpg")}
                                            className="img-content"/>
                                        <img
                                            src={require("./img/news/Чемпионат Запорожской области 2019/photo_2019-02-04_17-14-37.jpg")}
                                            className="img-content"/>
                                        <img
                                            src={require("./img/news/Чемпионат Запорожской области 2019/photo_2019-02-04_17-15-00.jpg")}
                                            className="img-content"/>
                                        <br/><br/>
                                        <br/><br/>

                                        <h5>Чемпионат
                                            Сичеславской
                                            обл.</h5>
                                        <br/>

                                        03
                                        февраля
                                        2019
                                        года
                                        (воскресенье)
                                        состоится
                                        "Чемпионат
                                        области
                                        по
                                        Таеквон-До
                                        ИТФ
                                        среди
                                        детей,
                                        юношей
                                        и
                                        взрослых".

                                        <br/><br/><img
                                        src={require("./img/news/dnepr.jpg")}
                                        className="img-content"/><br/><br/>
                                        <br/><br/>

                                        <h5>2019
                                            New
                                            Year
                                            Message
                                            from
                                            ITF
                                            President</h5>
                                        <br/>
                                        2019
                                        New
                                        Year
                                        Message
                                        from
                                        ITF
                                        President

                                        (28th
                                        Dec.
                                        2018)

                                        Distinguished
                                        ITF
                                        EB
                                        members,
                                        Dear
                                        NGB
                                        Representatives
                                        and
                                        Grand
                                        Masters
                                        and
                                        Masters,
                                        Dear
                                        ITF
                                        Members,
                                        Taekwon-Do
                                        artists
                                        and
                                        practitioners,
                                        As
                                        the
                                        glorious
                                        year
                                        of
                                        2018
                                        comes
                                        to
                                        a
                                        close,
                                        we
                                        now
                                        welcome
                                        the
                                        New
                                        Year
                                        2019
                                        with
                                        great
                                        hopes.
                                        Availing
                                        this
                                        opportunity,
                                        I
                                        would
                                        like
                                        to
                                        send
                                        my
                                        sincerest
                                        New
                                        Year
                                        greetings
                                        to
                                        the
                                        Vice-Presidents
                                        and
                                        EB
                                        member
                                        of
                                        the
                                        ITF,
                                        the
                                        Presidents
                                        of
                                        the
                                        ITF
                                        Continental
                                        Federations,
                                        Presidents
                                        of
                                        the
                                        ITF
                                        NGBs,
                                        high-ranking
                                        black
                                        belt
                                        holders,
                                        Taekwon-Do
                                        practitioners
                                        and
                                        all
                                        the
                                        people
                                        concerned.
                                        The
                                        year
                                        2018
                                        was
                                        a
                                        year
                                        of
                                        great
                                        pride.
                                        Last
                                        year,
                                        we
                                        celebrated
                                        the
                                        100
                                        th
                                        birth
                                        anniversary
                                        of
                                        Gen.
                                        Choi
                                        Hong
                                        Hi,
                                        the
                                        father
                                        of
                                        Taekwon-
                                        Do
                                        and
                                        the
                                        1
                                        st
                                        President
                                        of
                                        the
                                        ITF
                                        in
                                        the
                                        TKD
                                        motherland
                                        where
                                        his
                                        remains
                                        rest
                                        in
                                        peace
                                        and
                                        the
                                        TKD
                                        Holy
                                        Center
                                        stands.
                                        Looking
                                        back
                                        upon
                                        the
                                        lifetime
                                        of
                                        the
                                        TKD
                                        founder
                                        and
                                        the
                                        art’s
                                        subsequent
                                        years,
                                        it
                                        was
                                        with
                                        great
                                        pride
                                        that
                                        the
                                        Taekwon-Do
                                        community
                                        looked
                                        upon
                                        the
                                        history
                                        of
                                        ITF
                                        development
                                        and
                                        made
                                        a
                                        decision
                                        to
                                        develop
                                        TKD
                                        true
                                        to
                                        the
                                        desire
                                        of
                                        Gen.
                                        Choi
                                        Hong
                                        Hi.
                                        Last
                                        year
                                        the
                                        13
                                        th
                                        Junior
                                        and
                                        8
                                        th
                                        Veteran
                                        TKD
                                        World
                                        Championships
                                        took
                                        place
                                        successfully
                                        in
                                        Minsk,
                                        the
                                        capital
                                        of
                                        Belarus.
                                        A
                                        vivid
                                        demonstration
                                        of
                                        a
                                        bright
                                        Taekwon-Do
                                        future
                                        was
                                        felt,
                                        and
                                        that
                                        the
                                        love
                                        for,
                                        the
                                        trust
                                        in,
                                        and
                                        the
                                        expectations
                                        for
                                        our
                                        Taekwon-Do
                                        is
                                        ever-
                                        increasing
                                        all
                                        around
                                        the
                                        world.
                                        Last
                                        year
                                        the
                                        ITF
                                        made
                                        a
                                        great
                                        success
                                        in
                                        the
                                        work
                                        with
                                        the
                                        international
                                        sports
                                        organizations
                                        as
                                        a
                                        prestigious
                                        martial
                                        arts
                                        organization.
                                        Especially
                                        in
                                        November
                                        last
                                        year,
                                        we
                                        had
                                        a
                                        talk
                                        with
                                        the
                                        World
                                        TKD
                                        Federation
                                        and
                                        signed
                                        a
                                        joint
                                        agreement
                                        with
                                        them.
                                        It

                                        showed
                                        the
                                        will
                                        of
                                        unification
                                        between
                                        the
                                        two
                                        TKD
                                        Federations.
                                        This
                                        is
                                        a
                                        significant
                                        event
                                        marking
                                        a
                                        new
                                        and
                                        historic
                                        milestone
                                        in
                                        the
                                        60
                                        years’
                                        history
                                        of
                                        the
                                        ITF.
                                        This
                                        year
                                        2019
                                        will
                                        be
                                        a
                                        significant
                                        year.
                                        We
                                        have
                                        to
                                        bring
                                        the
                                        seeds
                                        of
                                        last
                                        year
                                        into
                                        a
                                        full
                                        bloom
                                        in
                                        accordance
                                        with
                                        the
                                        new
                                        requirements
                                        of
                                        the
                                        ITF
                                        development.
                                        In
                                        August
                                        2019
                                        we
                                        are
                                        to
                                        open
                                        the
                                        Taekwon-Do
                                        World
                                        Championships
                                        in
                                        Bulgaria
                                        that
                                        will
                                        integrate
                                        the
                                        adult,
                                        veteran
                                        and
                                        junior
                                        tournaments
                                        together
                                        for
                                        the
                                        first
                                        time
                                        in
                                        championship
                                        history
                                        of
                                        ITF.
                                        The
                                        21
                                        st
                                        Taekwon-Do
                                        World
                                        Championships,
                                        where
                                        all
                                        the
                                        superstars
                                        of
                                        every
                                        division
                                        are
                                        to
                                        show
                                        up,
                                        will
                                        add
                                        luster
                                        to
                                        the
                                        ITF
                                        championships
                                        with
                                        full
                                        pomp
                                        and
                                        ceremony.
                                        It
                                        will
                                        open
                                        a
                                        new
                                        chapter
                                        in
                                        the
                                        chronicle
                                        of
                                        the
                                        international
                                        martial
                                        arts
                                        development
                                        along
                                        with
                                        the
                                        ITF
                                        development,
                                        further
                                        promoting
                                        the
                                        unity
                                        between
                                        the
                                        Taekwon-Do
                                        artists
                                        and
                                        the
                                        development
                                        of
                                        Taekwon-Do
                                        techniques
                                        all
                                        around
                                        the
                                        world.
                                        In
                                        this
                                        new
                                        year,
                                        ITF
                                        is
                                        to
                                        celebrate
                                        the
                                        40
                                        th
                                        birth
                                        anniversary
                                        of
                                        the
                                        European
                                        ITF
                                        and
                                        the
                                        10
                                        th
                                        birth
                                        anniversary
                                        of
                                        the
                                        Taekwon-Do
                                        Federation
                                        of
                                        Africa,
                                        both
                                        of
                                        which
                                        are
                                        the
                                        principal
                                        Continental
                                        Federations
                                        under
                                        the
                                        ITF.
                                        Taking
                                        this
                                        opportunity,
                                        we
                                        will
                                        review
                                        the
                                        history
                                        of
                                        the
                                        development
                                        of
                                        our
                                        Taekwon-Do
                                        in
                                        Europe
                                        and
                                        Africa
                                        once
                                        again
                                        and
                                        will
                                        set
                                        the
                                        new
                                        stages
                                        of
                                        development
                                        for
                                        both
                                        of
                                        the
                                        Federations.
                                        We
                                        will
                                        also
                                        strive
                                        for
                                        the
                                        fulfillment
                                        of
                                        the
                                        joint
                                        agreement
                                        that
                                        we
                                        had
                                        signed
                                        with
                                        the
                                        World
                                        TKD
                                        Federation
                                        last
                                        year,
                                        in
                                        2019
                                        to
                                        further
                                        relations
                                        with
                                        WT
                                        and
                                        the
                                        ITF
                                        future
                                        as
                                        a
                                        whole.
                                        The
                                        new
                                        year
                                        will
                                        turn
                                        out
                                        to
                                        be
                                        a
                                        year
                                        of
                                        changes
                                        by
                                        further
                                        consolidating
                                        the
                                        successes
                                        achieved
                                        in
                                        the
                                        past
                                        and
                                        making
                                        greater
                                        successes
                                        in
                                        the
                                        future.
                                        Hoping
                                        that
                                        all
                                        ITF
                                        Taekwon-Do
                                        artists
                                        and
                                        the
                                        people
                                        concerned
                                        will
                                        further
                                        strive
                                        for
                                        turning
                                        our
                                        worldwide
                                        family
                                        of
                                        ITF
                                        into
                                        a
                                        more

                                        friendly
                                        and
                                        lovable
                                        community
                                        with
                                        extraordinary
                                        willingness
                                        and
                                        enthusiasm.
                                        I,
                                        from
                                        the
                                        bottom
                                        of
                                        my
                                        heart,
                                        wish
                                        all
                                        the
                                        Taekwon-Do
                                        artists,
                                        Taekwon-Do
                                        lovers
                                        and
                                        the
                                        people
                                        concerned,
                                        love
                                        and
                                        good
                                        health
                                        and
                                        happiness
                                        in
                                        their
                                        families.
                                        Thank
                                        you.
                                        A
                                        Happy
                                        New
                                        Year.
                                        <br/><br/><img
                                        src="https://image.jimcdn.com/app/cms/image/transf/dimension=1920x1024:format=jpg/path/s6256580b5ff85867/image/ia8e58e59f5101a87/version/1490283437/image.jpg"
                                        className="img-content"/><br/><br/>
                                        <br/><br/>

                                        <h5>Ушел
                                            из
                                            жизни
                                            Радужан
                                            Юрий
                                            Михайлович</h5>
                                        <br/>
                                        Федерация
                                        таэквон-До
                                        ИТФ
                                        Украины
                                        сообщает,
                                        что
                                        на
                                        43
                                        году
                                        жизни,
                                        скоропостижно
                                        ушёл
                                        из
                                        жизни
                                        выдающийся
                                        спортсмен,
                                        легенда
                                        Украинского
                                        таэквон-до,
                                        Заслуженный
                                        тренер
                                        Украины,
                                        РАДУЖАН
                                        ЮРИЙ
                                        МИХАЙЛОВИЧ
                                        (20.07.1976
                                        -
                                        24.12.2018).
                                        Руководство
                                        Национальной
                                        Федерации
                                        в
                                        лице
                                        Президента
                                        Потернака
                                        Виктора
                                        Николаевича,
                                        весь
                                        тренерский
                                        состав,
                                        друзья
                                        и
                                        коллеги
                                        по
                                        спорту,
                                        приносят
                                        свои
                                        глубочайшие
                                        соболезнования
                                        родным
                                        и
                                        близким
                                        Юры.
                                        Он
                                        был
                                        душевным,
                                        широко
                                        любящим
                                        жизнь
                                        человеком,
                                        и
                                        в
                                        тоже
                                        время
                                        глубоко
                                        духовным
                                        христианином.
                                        Есть
                                        поверье
                                        в
                                        славянском
                                        народе,
                                        что
                                        христиане
                                        ушедшие
                                        в
                                        мир
                                        иной
                                        во
                                        время
                                        больших
                                        праздников
                                        (таких
                                        как
                                        Пасха,
                                        Рождество),
                                        удостаиваются
                                        Царствия
                                        Небесного.
                                        Так
                                        это
                                        или
                                        нет
                                        на
                                        самом
                                        деле,
                                        но
                                        уверен,
                                        что
                                        все
                                        кто
                                        его
                                        знал,
                                        желают
                                        ему
                                        сейчас
                                        именно
                                        этого
                                        последнего
                                        пристанища
                                        на
                                        Воинском
                                        Пути.
                                        Юрий
                                        Михайлович
                                        останется
                                        в
                                        вечной
                                        памяти
                                        друзей
                                        по
                                        жизни
                                        и
                                        спорту,
                                        и
                                        навсегда
                                        войдёт
                                        в
                                        Золотые
                                        страницы
                                        истории
                                        Украинского
                                        Таэквон-До
                                        ИТФ,
                                        как
                                        тренер
                                        многократных
                                        чемпионов
                                        и
                                        призёров
                                        Мира
                                        и
                                        Европы.
                                        Вечная
                                        память...
                                        <br/><br/><img
                                        src={require("./img/news/Радужан Ю.М..jpg")}
                                        className="img-content"/><br/><br/>
                                        <br/><br/>

                                        <h5>Agreement
                                            between
                                            International
                                            Taekwon-Do
                                            Federation
                                            &
                                            World
                                            Taekwon-do</h5>
                                        <br/>
                                        An
                                        Agreement
                                        between
                                        ITF
                                        &
                                        WT
                                        was
                                        adopted
                                        on
                                        the
                                        2nd
                                        November
                                        2018
                                        in
                                        Pyongyang,
                                        DPR
                                        of
                                        Korea
                                        following
                                        the
                                        bilateral
                                        consultations
                                        with
                                        regards
                                        to
                                        the
                                        integration
                                        between
                                        two
                                        Federations.
                                        Prof.
                                        Ri
                                        Yong
                                        Son,
                                        President
                                        of
                                        International
                                        Taekwon-Do
                                        Federation
                                        and
                                        Dr.
                                        Chungwon
                                        CHOUE,
                                        President
                                        of
                                        World
                                        Taekwon-do
                                        ratified
                                        the
                                        given
                                        Agreement.
                                        The
                                        signing
                                        ceremony
                                        was
                                        honoured
                                        by
                                        the
                                        presence
                                        of
                                        Prof.
                                        GM.
                                        Hwang
                                        Ho
                                        Yong,
                                        Senior
                                        Vice-President,
                                        Mr.
                                        Kim
                                        Chol
                                        Gyu,
                                        Director
                                        of
                                        Finance
                                        &
                                        Administration
                                        and
                                        Mr.
                                        Choi
                                        Hyong
                                        Chol,
                                        Deputy
                                        <br/><br/><img
                                        src={require("./img/image (1).jpg")}
                                        className="img-content"/><br/><br/>
                                        Chairman
                                        of
                                        Finance
                                        &
                                        Market
                                        Standing
                                        Committee
                                        on
                                        the
                                        side
                                        of
                                        ITF
                                        headed
                                        by
                                        its
                                        President
                                        Prof.
                                        Ri
                                        Yong
                                        Son
                                        and
                                        of
                                        Mr.
                                        Hossein
                                        Rafaty,
                                        Secretary
                                        General,
                                        Mr.
                                        Ahmed
                                        Fouly,
                                        president
                                        of
                                        Taekwon-do
                                        Federation
                                        of
                                        Africa,
                                        Mr.
                                        John
                                        Kotsifas,
                                        President
                                        of
                                        Taekwon-do
                                        Federation
                                        of
                                        Oceania,
                                        Mr.
                                        Angelo
                                        Cito,
                                        Member
                                        of
                                        the
                                        WT
                                        Executive
                                        Board
                                        &
                                        President
                                        of
                                        Taekwon-do
                                        Federation
                                        of
                                        Italy
                                        and
                                        Mr.
                                        Eil
                                        Chul
                                        Kim,
                                        Deputy
                                        General
                                        Secretary
                                        Manager
                                        on
                                        the
                                        side
                                        of
                                        WT
                                        headed
                                        by
                                        its
                                        President
                                        Dr.
                                        Chungwon
                                        CHOUE.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default News;