import React from 'react';
import {bodyClick} from "../app/app";

const History = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Історія Таеквон-До</span>
                                            <span className="ru">История Таэквон-До</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-книги-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>ИСТОРИЯ РАЗВИТИЯ ТАЭКВОН-ДО</h5>
                                        <h5>Генерал Чой Хон Хи и Таэквон-До</h5>

                                        Хотя источники военного мастерства за дальностью времён окутаны тайной,
                                        неоспоримым
                                        считается факт, что с незапамятных времён существовали физические действия,
                                        включающие
                                        использование рук и ног в целях самозащиты.<br/>
                                        Если определить такие физические действия как таэквон-до, то любая страна могла
                                        бы
                                        претендовать на то, чтобы считаться местом зарождения данного искусства.<br/>
                                        Однако, между таэквон-до, которым занимаются сегодня и жесткими формами
                                        поединков без
                                        оружия, разработанными в прошлом, мало общего.<br/>
                                        Современное таэквон-до сильно отличается от других видов воинского искусства.
                                        Действительно, ни один вид военного мастерства не обладает такой утонченностью и
                                        эффективностью техники и общефизической подготовки, которые даёт профессионалам
                                        таэквон-до.<br/>
                                        Так как теория, терминология, приёмы, системы, методы, правила, тренировочные
                                        комплексы,
                                        духовные основы были в значительной мере разработаны, систематизированы
                                        генералом Чой
                                        Хон Хи, ошибочно думать, что любые физические действия с применением рук и ног
                                        для
                                        самозащиты и есть таэквон-до.<br/>
                                        Только те, кто изучает технику, основанную на теории, принципах и философии
                                        автора,
                                        считаются учениками подлинного таэквон-до.<br/>
                                        После Второй Мировой войны возникла явная тенденция к созданию единого
                                        корейского
                                        боевого искусства, которое могло бы символизировать "дух нации".<br/>
                                        Такое боевое искусство было создано южнокорейским генералом Чой Хон Хи.<br/>
                                        Генерал Чой Хон Хи должен был практиковать японское каратэ, потому что Корея
                                        находилась
                                        под японской колониальной оккупацией более 35 лет.<br/>
                                        Корея была освобождена от японского колониального правления в 1945 году.<br/>
                                        С 1946 года он обучал солдат Каратэ, но в том же 1946 году он решил создать
                                        боевое
                                        искусство Кореи, которые затмит все другие боевые искусства как в духовных, так
                                        и в
                                        технических аспектах, чтобы продемонстрировать дух и мудрость корейской нации
                                        всему
                                        миру. Он посвятил почти десять лет изучению и разработке новых методов нападения
                                        и
                                        обороны по сравнению с другими боевыми искусствами на основе отличных
                                        технических
                                        движений «Су Бак Ги» и «Тэ Кён», традиционных боевых искусств Кореи, с их
                                        духовными и
                                        морально-этическими основами Востока, с помощью которых можно максимизировать
                                        свою силу
                                        в принципе современной науки, отличной от существующих технических
                                        движений.<br/>
                                        К концу 1954 года завершил и принёс в мир как современное боевое искусство
                                        собственную
                                        систему , которая 11 апреля 1955 года получила название
                                        "ТАЭКВОН-ДО" (TAEKWON-DO).<br/>
                                        <br/>
                                        <img className="img-content" src={require("./img/general.jpg")}
                                             style={{width: "95%"}}/><br/><br/>
                                        <h5>ИСТОРИЯ ТАЭКВОН-ДО В ЦИФРАХ</h5>

                                        1955 год_Сессия Совета по именованию, состоящая из преславутых историков и
                                        выдающихся общественных деятелей, глубоко разбирающихся в боевых искусствах,
                                        решила назвать боевые искусства, которые до сих пор изучались и дополнялись
                                        генералом Чой Хон Хи, после и на основе его предложения – «Taekwon-Do».<br/>
                                        1959 год_1-е издание книги по «ТАЭКВОН-ДО» на корейском языке.<br/>
                                        1961 год_Генерал Чой Хон Хи возглавил Корейскую Ассоциацию Таеквон-До.<br/>
                                        1965 год_1-е англоязычное издание книги «ТАЭКВОН-ДО – корейское искусство
                                        самообороны».<br/>
                                        1966 год_ Генерал Чой Хон Хи учредил в Сеуле, Южная Корея Международную
                                        Федерацию Таэквон-До ( ITF–International Taekwondo Federation).<br/>
                                        1969 год_1-й Азиатский турнир по таэквон-до, Гонконг.<br/>
                                        1972 год_Штаб-квартира ИФТ переехала в Торонто (Канада).<br/>
                                        1972 год_ Издание книги «ТАЭКВОН-ДО» (6 переизданий).<br/>
                                        1974 год_1-й Чемпионат Мира по таэквон-до (ИТФ), Монреаль (Канада).<br/>
                                        1976 год_1-й Чемпионат Европы по таэквон-до (ИТФ), Роттердам (Нидерланды).<br/>
                                        1979 год_1-й Тихоокеанский Чемпионат, Веллингтон, (Новая Зеландия).<br/>
                                        1979 год_Создана Всеевропейская Федерация таэквон-до.<br/>
                                        1982 год_Создана Североамериканская федерация.<br/>
                                        1983 год_Создана Центральноамериканская федерация.<br/>
                                        1983 год_1-е издание 15-томной англоязычной энциклопедии по «Таэквон-До» -
                                        результат научной деятельности всей его жизни (5 переизданий 1985-2008).<br/>
                                        1985 год_ Штаб-квартиру ИТФ перенесли в г.Вена (Австрия).<br/>
                                        1988 год_Первое англоязычное издание сжатой однотомной энциклопедии «ТАЭКВОН-ДО»
                                        (6 переизданий 1988-2004).<br/>
                                        2000 год_Издание 3-х томника «ТАЭКВОН-ДО и Я».<br/>
                                        2000 год_Издание книги «МОРАЛЬНЫЙ ПУТЕВОДИТЕЛЬ».<br/>

                                        2002 год (15 июня)_Создатель Tаэквон-До, основатель и первый президент ИТФ
                                        генерал Чой Хон Хи, до последнего момента своей жизни посвятивший все свои силы
                                        таэквон-до, скончался, к нашей большой скорби, из за рака желудка в городе
                                        Пхеньян, (КНДР).<br/>
                                        2002 год_После смерти основателя и на основании его ещё прижизненного завещания,
                                        Президентом ИТФ был избран профессор Чан Унг (член МОК).<br/>

                                        Все корейцы должны выразить благодарность генералу Чою за распространение
                                        таэквон-до во всем мире, который также поместил Корею на карту, а также повернул
                                        Корею теплым обликом миру.<br/>
                                        Все ученики таэквон-до обязаны генералу Чою, так как без него не было бы
                                        Корейского боевого искусства под названием «Таэквон-до».<br/>
                                        <br/>
                                        <h5>ИСТОРИЯ ПОСЛЕ СМЕРТИ ОСНОВАТЕЛЯ</h5>

                                        Перед смертью в июне 2002 года, когда генерал Чой был уже тяжело болен, он
                                        обратился к сообществу ИТФ с предсмертным посланием, в котором изъявил свою
                                        волю и просил, во исполнение его главной цели создания таэквон-до и его
                                        жизненного пути, назначить после своей смерти на пост Президента ИТФ
                                        господина Чан Унг (КНДР), поскольку он является Членом МОК и в его
                                        возможностях больше всего влиять на продвижение ИТФ в Олимпийскую семью.<br/>
                                        Он вступил в должность в сентябре 2002 года, после Внеочередного Конгресса
                                        ИТФ в Пхеньяне, где на Траурный митинг по случаю 100 дневной годовщины со
                                        дня смерти Основателя собралось большинство руководителей Национальных
                                        организаций ИТФ. Конгресс ИТФ хотя и был проведен спонтанно, но по
                                        письменным просьбам большинства членов ИТФ было принято решение использовать
                                        момент для проведения Внеочередного Конгресса ИТФ.<br/>
                                        С того момента руководителем Международной федерации, которой ранее
                                        руководил Генерал Чой Хон Хи, на основании его завещания и дальнейшего
                                        заключительного решения 14-го Конгресса ИТФ в июне 2003 года, который
                                        проходил во время проведения Чемпионата Мира в Греции, м.Тессалоники, был
                                        избран член МОК Профессор Чан Унг (КНДР).<br/>
                                        Конгресс был признан легитивным, то есть, оснований для признания его
                                        решений не законными нет, и не было.<br/>
                                        Однако, не все члены ИТФ там присутствовали и не все согласились с таким
                                        решением. В итоге произошло деление на несколько организаций.<br/>
                                        Уже 2003 году возникло объединение группы инструкторов и мастеров,
                                        которые<br/>
                                        не поддержали это решение.
                                        Предсмертное заявление Генерала Чой Хон Хи было сделано в присутствии
                                        мастеров, и запротоколировано. Протокол подписал и мастер Том МакКаллум -
                                        секретарь ИТФ, присутствующий там. Однако он решил сразу не оповещать всех
                                        членов ИТФ, и держал в тайне этот протокол приблизительно с месяц, обдумывая
                                        дальнейшие действия. Затем Том МакКаллум переметнулся в группу тех, кто счёл
                                        завещание весьма неожиданным и невероятным. Том Мак Каллум и группа его
                                        сторонников обвинили власти КНДР в том, что они возможно оказали воздействие
                                        на Основателя таэквон-до Генерала Чой, с тем, чтобы тот назначил своим
                                        правопреемником никому, на тот момент, не известного корейского чиновника
                                        Чан Унга.<br/>
                                        В предсмертном обращении Генерал пояснил, что он желает видеть на посту
                                        президента ИТФ именно этого человека, поскольку Чанг Унг является крупным
                                        деятелем в области спорта КНДР и членом МОК. У него огромный опыт, знание
                                        нескольких языков и много международных связей на самом высоком уровне. Это
                                        сулило огромные возможности воплотить давнюю мечту генерала о принятии
                                        таэквон-до ИТФ в Олимпийское движение, и впоследствии войти в программу
                                        Олимпийских игр.<br/>
                                        В 2003 году альтернативная группа ИТФ, под руководством мастеров Тома Мак
                                        Каллума (Австрия), Поля Веллера (Германия), Вим Боса (Италия) и Тадеуша
                                        Лобода (Польша), провела Конгресс в Варшаве, в то время как Официальный ИТФ
                                        Конгресс проходил в Греции. Сыграла не последнюю роль и ранее непредвиденная
                                        ситуация со сменой места проведения чемпионата Мира.<br/>
                                        Ранее, еще при жизни Основателя, планировалось провести чемпионат Мира 2003
                                        года в Варшаве, Польская федерация таэквон-до проделала не малую работу,
                                        заручилась поддержкой самого президента страны Леха Валенса, но позже весьма
                                        неожиданно генерал поменял свое решение и неожиданно назначил местом
                                        проведения чемпионата 2003 года Грецию. Поскольку, в 2004 году Олимпийские
                                        игры проходили в Афинах, это был тактический ход Генерала по продвижению ИТФ
                                        в Олимпийскую программу. Многие высокие чиновники присутствовали на
                                        чемпионате в Греции и могли повлиять на организационный комитет предстоящих
                                        Олимпийских Игр, с тем, чтобы Олимпийское Тхэквондо ВТФ объединить с ИТФ в
                                        рамках проведения одного общего Олимпийского турнира. Но руководство
                                        Польской федерации таэквон-до теряло свое лицо перед президентом страны, и
                                        такие решения сильно обидели польских лидеров. Воспользовавшись ситуацией,
                                        которая возникла после смерти Генерала, поляки активно поддержали группу
                                        мастеров пожелавших раскола ИТФ. В результате, польская сторона провела у
                                        себя альтернативный чемпионат мира с небольшим количеством участников и
                                        кучей медалей у польских спортсменов, а заодно и альтернативный Конгресс
                                        ИТФ, где лидером дублирующей организации был избран Мастер Тран Трю
                                        Кван.<br/>
                                        С 2005 года по инициативе этих деятелей прошло несколько судебных тяжб и
                                        заседаний против оригинальной ИТФ.<br/>
                                        В декабре 2007 года, пожалуй понимая, что у них нет достаточных законных
                                        оснований для того, чтобы претендовать на права возглавлять и последующую
                                        деятельность в составе бывшей Федерации ИТФ основанной генералом Чой Хон Хи,
                                        создают и уже легально регистрируют совершенно новую организацию таэквон-до
                                        с штаб квартирой в городе Бенидорм, Испания, но под тем же названием и
                                        используя прежние логотипы и официальную символику ИТФ.<br/>
                                        В конце 2010 года, во время землетрясения на Гаити, Мастер Тран Трю Кван
                                        погиб под обломками гостиницы. Сегодня (2018г.) его пост занимает Гранд
                                        Мастер из Аргентины Пабло Трахтенберг.<br/>

                                        Интерессным фактом является то что изначально, в Конституции ИТФ основанной
                                        южнокорейским генералом, для общения на соревнованиях, семинарах и прочих
                                        мероприятиях, установлены два официальных языка, английский как
                                        международный и корейский, язык терминологии техники таэквон-до, и очевидно
                                        абсурдным является тот факт, что в альтернативной ИТФ возглавляемой
                                        аргентинцем, установлены английский и испанский языки. Если в следующий раз
                                        будет избран новый президент, например из Голандии или арабской страны, то
                                        обязательными языками для всех занимающихся будут языки этих стран?!

                                        В 2011 году Международная Федерация Таэквон-До (ИТФ) под руководством члена
                                        МОК Профессора Чан Унга добились должного успеха в Высоком Международном
                                        суде в Вене против отколовшейся Группы, тем самым положив конец
                                        необоснованным и нелепым в правовом поле спорам.<br/>

                                        Существует еще одна, менее массовая, но все же претендующая тоже на название
                                        Международной Федерации Таэквон-до ИТФ, и на ту же эмблему с кулаком на фоне
                                        земного шара. Это ИТФ под руководством Чой Джун Хва.<br/>
                                        Эта организация был рождена по инициативе 45-летнего сына Генерала Чой Хон
                                        Хи, мастера Чой Джун Хва. Еще перед смертью отца, в семье произошел раздор.
                                        Очевидно, Чой Джун Хва не хватило обыкновенного терпения и таэквон-до
                                        заплатило за это не малую цену.<br/>

                                        Из воспоминаний мастера Вячеслава Семенкова (8-й дан ИТФ), родоначальника
                                        таэквон-до ИТФ в Латвии.<br/>
                                        В период с 1997-2000 года мне неоднократно приходилось принимать участие в
                                        официальных обсуждениях проблемы правопреемника Основателя Таэквон-до.<br/>
                                        Как-то, в 1999.г в ресторане гостиницы Москва, в Санкт-Петербурге, за
                                        обеденным столом, я был в окружении генерала Чой Хон Хи, его сына Чой Джун
                                        Хва, Мастера Ким Ен Хуна, Михаила Шмелева и еще нескольких важных
                                        персон.<br/>
                                        Обсуждался вопрос будущего развития ИТФ, и когда сам Основатель взял слово,
                                        он после небольшого вступления обратился ко мне и задал вопрос:<br/>
                                        " Я уже очень стар, - начал он неторопливо говорить, - в последнее время
                                        меня беспокоит мое здоровье, и мне приходится задумываться о том, в чьих
                                        руках будет мой ребенок". Мы, сидящие напротив, переглянулись, а генерал
                                        продолжал:<br/>
                                        "Я, имею в виду свое детище, Таэквон-до. Я всегда говорил вам о том, что у
                                        меня есть жена и дети, один из них сейчас сидит рядом со мной, и вы его
                                        знаете. Но я посвятил свою жизнь именно Таэквон-до, и я хочу сказать, что у
                                        меня есть вы, мои помощники, руководители организаций таэквон-до по всему
                                        миру, и вы для меня тоже сыновья, сыновья семьи таэквон-до.<br/>
                                        "Мой сын – Сэ-мэн-ков", как обычно, на корейский манер, произнося мою
                                        фамилию, он обратился ко мне, - "кто должен занять мое место после моей
                                        смерти?" –
                                        прямо посмотрев в глаза, спросил он меня.
                                        Для меня на тот момент был только один ответ - Ваш сын, Мастер Чой Джун Хва,
                                        и мне казалось что на тот момент и сам Основатель желал того же.<br/>
                                        Если бы Чой Джун Хва хватило выдержки, то через 2.5 года вряд ли мы бы имели
                                        ту ситуацию с клонами, которая есть сейчас.<br/>
                                        В 2001 году в Италии на чемпионате мира и Конгрессе ИТФ произошел разлад
                                        между сыном и отцом. Генерал заявил во всеуслышание, что Джун Хва немного
                                        спешит, и он желает иметь степень Гранд Мастера и 9 дан и возглавить ИТФ, но
                                        ему пока что еще рано присваивать это звание, нужно немного подучиться еще,
                                        а сам он еще в здравии, и в силах управлять федерацией.<br/>
                                        На тот момент на протяжении 46 лет существования таэквон-до, титул Гранд
                                        Мастера (9 дан) носил только сам Основатель, и никто не помышлял о
                                        возможности еще кому-либо иметь такой титул. Теперь же, в Италии, сам
                                        Основатель присвоил титул Гранд Мастера не своему сыну, а своему старшему
                                        ученику, который тут же вошел в историю, как первый мастер после самого
                                        Основателя Чой Хон Хи, получивший высший 9-й дан. Это был Мастер Ри Ки Ха. В
                                        настоящее время перед своим именем он носит аббревиатуру FGMR - что означает
                                        Первый Гранд Мастер Ри, и это так, если не считать самого генерала Чой,
                                        который носил это высшее звание на протяжении нескольких десятилетий как
                                        Основатель и Отец Таэквон-до.<br/>

                                        Конгресс 2001г, Италия (слева: В.Легай, М.Тиболо,
                                        Чой Джун Хва, Ри Ки Ха, ген. Чой Хон Хи, Р.Мак Леллан)<br/>

                                        Был еще один момент, когда сын Генерала, Мастер Чой Джун Хва, на том же
                                        Конгрессе 2001 года встал на защиту польской делегации, для того чтобы
                                        противостоять решению Отца о переносе чемпионата Мира 2003 года из Польши в
                                        Грецию. К слову сказать, Мастер Чой, когда-то жил и трудился в Польше,
                                        распространяя там таэквон-до и учитывая эмоциональный фон, склонить сына на
                                        свою сторону поляком не составило никакого труда.<br/>
                                        Генерал, через полгода собрал Внеочередной Конгресс ИТФ в Венне, 12 января
                                        2002 года, где мы все, руководители национальных федераций, рассматривали
                                        вопрос о месте проведения чемпионата, и о том, что сын Генерала, Мастер Чой
                                        Джун Хва на время отстранен от дел ИТФ. На Конгрессе даже случилась
                                        небольшая потасовка, Мастера Чой Джун Хва по решению его отца не допустили
                                        участвовать в заседании Конгресса. Он попытался пройти в зал заседания, но
                                        дорогу преградили охранявшие вход полицейские, которых вызвал оргкомитет
                                        Конгресса.<br/>
                                        Всему этому предшествовала ситуация, случившаяся этим же утром на семинаре,
                                        где сорвавшийся Мастер Чой Джун Хва, пнул под зад тогдашнего секретаря ИТФ
                                        Тома МакКаллума (Шотландия) - почтенного возраста персону работавшую в
                                        Штаб-квартире ИТФ. Надо отметить, что ситуация была спровоцирована самим
                                        "пострадавшим" секретарем. Он даже вызвал скорую и отправился в больницу,
                                        чтобы как-то зафиксировать случившееся. Хотя, пинок был чисто символический,
                                        удар ногой Долио Чаги, направленный в пятую точку, скользнул по опущенной
                                        руке мастера Мак Каллума и пришелся в мягкую часть. Но этого было
                                        достаточно, что бы запятнать репутацию и разорвать отношения с отцом, к тому
                                        же, был повод вызвать наряд полиции для охраны Конгресса и чтобы не
                                        допустить Чой Джун Хва со своими сторонниками на Конгресс.<br/>
                                        Генерал Чой объявил сына персоной "нон грата" для ИТФ, и пока тот не приедет
                                        в Корею, куда генерал собирался на лечение в ближайшее время, что бы просить
                                        прощения за свои ошибки, он будет отстранен от дел ИТФ.<br/>


                                        Через пару месяцев Основатель прилетит на свою историческую Родину в Пхеньян
                                        и ляжет в больницу на лечение, а еще через полтора месяца, 11 июня 2002
                                        года, он соберет у больничной койки десяток выдающихся мастеров и объявит
                                        предсмертное завещание, где укажет совершенно нового и никому из мира
                                        мастеров таэквон-до неизвестного правопреемника - Чан Унга.<br/>

                                        В заключительных словах генерал Чой сказал о том, что
                                        он человек, у которого есть большинство последователей в мире, и что он
                                        самый счастливый человек, который сделал все, что было возможно в его
                                        жизни.<br/>
                                        Его желанием было объединить таэквон-до в одно.<br/>
                                        Обращаясь ко всем своим последователям, генерал акцентировал на том, что
                                        таэквон-до никогда не существовали без помощи КНДР, все должны это
                                        знать.<br/>
                                        ИФТ является международной организацией, и нам не нужно спорить об
                                        идеологии. Принадлежит ли Организация Объединенных Наций к чернокожим,
                                        потому что их Генеральный секретарь чернокожий?<br/>
                                        Пожалуйста, не думайте так.<br/>
                                        Таэквон-До должен быть сосредоточен в Корее.<br/>


                                        При объявлении предсмертного завещания присутствовали:<br/>

                                        Госпожа Чой Джун Хи – Канада (жена)<br/>
                                        Грандмастер Хван Кван Сунг (К-9-1) – США (Помощник генерала, Пресс-секретарь
                                        ИТФ и Председатель Комитета по слияниям),<br/>
                                        Грандмастер Ри Ки Ха (GB-9-1) – Великобритания (Вице-президент ИТФ),<br/>
                                        Грандмастер Пак Джон Су (C-9-1) – Канада (Член Консультативного
                                        Комитета),<br/>
                                        Мастер Томас МакКаллум (GB-8-3) – Австрия (Генеральный секретарь),<br/>
                                        Мастер Хван Джин (J-8-1) –Япония (Член Консультативного комитета),<br/>
                                        Мастер Леон Вей Менг (GR-8-1) – Гренландия (Председатель Консультативного
                                        комитета),<br/>
                                        Мастер Фап Лу (С-8-4) – Канада (Член Исполнительного комитета),<br/>
                                        Господин Чан Унг – КНДР (член МОК),<br/>
                                        Господин Чон Дже Хун – КНДР (Генеральный секретарь Международного комитета
                                        боевых искусств),<br/>
                                        Господин Хван Бонг Йонг – КНДР (Председатель Корейского комитета
                                        таэквон-до),<br/>
                                        Господин Ранг Бонг Ман – КНДР (Генеральный секретарь Корейского комитета
                                        таэквон-до).<br/>

                                        15 июня 2002 года его не стало.<br/>

                                        Сын генерала Чой Джун Хва так и не сможет приехать в КНДР. Причины мне не
                                        известны, хотя существует две версии. По первой он не стремился туда
                                        попасть, поскольку боялся за свою жизнь, считая, что спецслужбы КНДР его не
                                        выпустят из страны. А вторая версия в том, что его попросту и не пустили в
                                        КНДР. Страна ведь закрытая, и если властям не угодно, просто так туда не
                                        попадешь.<br/>
                                        Нет ни каких свидетельств, что Чой Джун Хва пытался это сделать, как и нет
                                        доказательств обратного.<br/>
                                        После смерти отца, мастер Чой Джун Хва собрал своих сторонников из тех, кто
                                        не остался в ИТФ под руководством Чан Уна, и организовал свою организацию
                                        ИТФ,
                                        со штаб-квартирой в Торонто.<br/>
                                        Конечно число последовавших за мастером, скомпрометировавшим себя на
                                        Конгрессе тогда еще единой ИТФ, во многом по численности уступает основной
                                        федерации Чан Унга и Тран Квана, но все же, она имеет место быть, и проводит
                                        свои чемпионаты мира, как и ее сестры федерации таэквон-до ИТФ.<br/>

                                        В 2013 году и эта версия ИТФ тоже претерпит раскол на два лагеря. Один
                                        лагерь останется за Чой Джун Хва, а второй отколется и назовет себя "ИТФ со
                                        Штаб-квартирой в Корее" (имеется в виду Южная Корея) с главными
                                        специалистами по технике Рудольфом Каном (из Москвы) и Зибби Крюк
                                        (Австралия) поссорившимися с Чой Джун Хва. С ними же остался и Майкл Тиболо
                                        - юрист и адвокат, появившийся на горизонте ИТФ еще в 2001, в Италии на
                                        Конгрессе ИТФ, где был представлен как персона занимающаяся юридическими
                                        вопросами ИТФ. На фото выше он сидит по правую руку с Чой Джун Хва. Но как
                                        оказалось через 10 с небольшим лет их пути разойдутся и теперь он занимает
                                        руководящий пост в ИТФ № 4 со штаб-квартирой в Сеуле (Корея). Интервью с Мр.
                                        Тиболо выставлено на Ютюбе, интервьюирует юриста его соратник по ответвлению
                                        ИТФ Мр. З.Крюк Эту группу можно назвать ИТФ №4. ITF HQ Korea.<br/>

                                        Основатель таэквон-до южнокорейский генерал Чой был выше политических интриг
                                        и религиозных расприй. Созданием международной организации, он ставил целью,
                                        объеденить всех людей в гармоничное и развивающееся сообщество.<br/>
                                        В Уставе Федерации указано, что спорт вне политики и ИТФ не является только
                                        корейской организацией, она Международная, не смотря на то, что на
                                        сегодняшний день её возглавляет представитель Северной Кореи (КНДР) как
                                        одной из стран – Родины искусства корейского народа. Он всю жизнь мечтал об
                                        одном, это объеденить Корею и корейский народ, что подтверждает его самый
                                        последний и главный 24-й комплекс формальных упражнений (туль) «ТОН- ИЛЬ».
                                        Туль назван в четь идеи объединения Северной и Южной Кореи в единое
                                        государство, разделение которого произошло в 1945 г. Диаграмма движений
                                        символизирует единую нацию.<br/>
                                        На сегодняшний день мечты генерала осуществляются.<br/>
                                        Между самыми большими и основными Федерациями развивающими и практикующими
                                        таэквон-до в мире ИТФ и ВТФ, подписан меморандум (соглашение) о взаимном
                                        признании организаций, их деятельности, спортсменов, регалий. В Северной и
                                        Южной Корее создали Министерства по делам объединения культуры и спорта
                                        обеих стран. На Зимней Олимпиаде-2018 года, которая прошла в Южной Корее,
                                        спортсмены обеих стран выступили под одним флагом. Этот факт не может не
                                        радовать всех, кто является приверженцем и продолжателем мирных идей и
                                        жизненной философии генерала Чой Хон Хи.<br/>
                                        Все остальные организации, которые используют имя «таэквон-до», прежде
                                        всего, комерциализируют таэквон-до, убирают из него главный принцип
                                        таэквон-до о гуманизме, братстве, дружбе независимо от религии и политики.
                                        Всё это ведёт к упадку духовных ценностей и моральных устоев.<br/>


                                        <br/>
                                        <h5>Таэквон-До в Украине</h5>

                                        Реальную дату возникновения корейского единоборства в Советском Союзе
                                        (в т.ч. и Украине) можно указать лишь приблизительно - это середина
                                        семидесятых.<br/>
                                        В это время в Москве, Харькове, Прибалтике, на Дальнем востоке стали
                                        появляться группы, в которых преподавались различные варианты Таэквон-До
                                        (версии ИТФ, ВТФ и Джуна Ри).<br/>
                                        Советские граждане, работавшие за рубежом и занимавшиеся в клубах
                                        таэквон-до, привозили свои познания и литературу домой.
                                        Находились энтузиасты, самостоятельно изучавшие секреты восточного
                                        единоборства.<br/>
                                        Однако самым продуктивным явился путь обучения у корейских специалистов,
                                        приезжающих в Союз. Эти люди, в основном молодые специалисты или
                                        студенты, тренировали зачастую бескорыстно, оставили после себя сильные
                                        школы в Харькове, Ташкенте, Москве и других городах.<br/>
                                        В Харькове заслуженной популярностью пользовалась школа доминиканца
                                        Луиса Карвахала, откуда вышли многие известные ныне специалисты по
                                        таэквон-до на Украине, в Москве, Питере. В 1978 году Луис Карвахал
                                        прибыл из Воронежа в Харьков для учебы в Государственном Университете
                                        им. Каразина. Первыми его учениками были студенты этого же ВУЗа. Позже
                                        мы могли слышать их имена на различных соревнованиях среди тех, кто был
                                        приглашен на пьедестал для награждения. Это имена Валерия Сокола, Сергея
                                        Харитоненко, Виталия Олина, Сергея Сафонкина, Владимира Дубцова, Леонтия
                                        Пака и др.<br/>
                                        До 1978 года занятия восточными единоборствами в СССР существовали в
                                        виде самодеятельных секций и клубов,
                                        а школа Таэквон-До стала к этому времени очень популярной.<br/>
                                        К сожалению, таэквон-до не имело возможности развиваться как
                                        самостоятельный вид спорта, поскольку Спорткомитет СССР пытался сделать
                                        Таэквон-До разновидностью каратэ: таэквондистов заставляли участвовать в
                                        соревнованиях по каратэ.<br/>
                                        Чтобы взять под контроль ситуацию, 13 ноября 1978 года Спорткомитет СССР
                                        создал комиссию по развитию каратэ, а в декабре 1978 года была создана
                                        Федерация каратэ.
                                        10 августа 1979 года Комитетом по физической культуре и спорта при
                                        Совете Министров СССР издан Приказ № 894 об официальном развитии каратэ,
                                        утверждены правила соревнова-ний по каратэ и разрядные требования,
                                        введенные в Единую Всесоюзную спортивную классификацию с 1 января 1980
                                        г.<br/>
                                        10 ноября 1981 года выходит «Указ» Президиума Верховно-го Совета СССР за
                                        № 6/19 и № 6/24 «Об административной и уголовной ответственности за
                                        нарушение правил обучения кара-тэ» и вводится в Административный и
                                        Уголовный кодекс РСФСР и Союзных республик соответствующие статьи об
                                        от-ветственности за «Незаконное обучение каратэ» и за «Наруше-ние правил
                                        обучения каратэ».<br/>
                                        С конца 1981 года и начала 1982 года секции восточных единоборств
                                        постепенно стали закрываться.<br/>
                                        Тогдашнему руководству страны показалось, что идеология восточных
                                        единоборств якобы противоречит идеологии мар-ксизма-ленинизма. Это было
                                        непродуманное решение, которое отбросило отечественный спорт далеко
                                        назад. Были проведены показательные «судебные процессы» над некоторыми
                                        извест-ными мастерами. Часть инструкторов пострадали: по сфабрико-ванным
                                        уголовным делам они были осуждены на разные сроки тюремного
                                        заключения.<br/>
                                        Несмотря ни на что, восточные единоборства в нашей стра-не не прекратили
                                        свое существование, самые преданные этому делу энтузиасты ушли в
                                        подполье. С 1982 -го по 1989 год при-ходилось тренироваться нелегально
                                        под видом всевозможных секций ОФП и тому подобное, постоянно меняя
                                        помещения.<br/>

                                        Возрождение таэквон-до ИТФ на территории Украины тесно связано с
                                        историей развития таэтквон-до в СССР, в состав которого в те годы
                                        входила Украина.<br/>
                                        Официальной же датой появления таэквон-до ИТФ в нашей стране можно
                                        считать 1988 год - год создания Всесоюзной комиссии по таэквон-до при
                                        САВЕ (Советская Ассоциация Восточных Единоборств).<br/>
                                        В целях развития этого вида спорта в 1988 году при Советской Ассоциации
                                        Восточных Единоборств, затем и в структуре Госкомспорта СССР была
                                        создана Всесоюзная комиссия по таэквон-до, которая была признана
                                        Международной Федерацией таэквон-до ИТФ в качестве единственной
                                        полномочной организацией по развитию таэквон-до в СССР. Председатель
                                        Комиссии Михаил Степин и заместитель Председателя Игорь Соколов первыми
                                        в СССР были аттестованы международной аттестационной комиссией
                                        соответственно на 4 и 3 Дан. Экзамен у них принимал Президент ИТФ Чой
                                        Хон Хи и генеральный Секретарь, председатель совета инструкторов ИТФ,
                                        Грандмастер Пак Джун Тэ.<br/>
                                        Комиссия пригласила двух северокорейских инструкторов Ли Ен Сока (6 Дан)
                                        и Син Ил Уна (4 Дан) для работы в Москву. Специалистам Южной Кореи
                                        приехать было невозможно из-за абсолютного отсутствия дипломатических
                                        отношений между государствами.<br/>

                                        12-18 февраля 1989 года в г.Москве, прошел Первый Всесоюзный технический
                                        семинар по таэквон-до ИТФ под руководством Мастера Пак Джун Тэ (8 дан),
                                        а так же Ли Ен Сок (6 дан) и Син Ил Унг (4 дан).<br/>
                                        В семинаре приняло участие более сорока человек из разных регионов
                                        Советского Союза.<br/>
                                        Семинар проводился с целью единой стандартизации техники таэквон-до ИТФ
                                        в СССР. На семинаре большое внимание было уделено изучению базовой
                                        техники таэквон-до ИТФ , методики выполнения тылей от Чон-Джи до Ге-Бэк,
                                        а так же повышение уровня спортивного мастерства спортсменов, тренеров.
                                        Стимулирование учебно-тренировочной работы в СССР.<br/>
                                        По окончанию семинара Мастер Пак Джун Тэ провел аттестацию на Даны. 1-й
                                        дан, чёрный пояс получили - москвичи Сергей Бубнов, Юрий Семенов и
                                        Дмитрий Прогоровский, петербуржец Сергей Сафонкин, харьковчане Валерий
                                        Сокол и Виталий Олин, Наталья Бабакова и Ольга Гранько из Апшеронска,
                                        Сергей Ковалев из Туапсе, рижане Вячеслав Семенков и Зигмунд Кейшс,
                                        Ричардас Крисюнас и Эгидиус Балейшис из Клайпеды, одессит Юрий Бужужан,
                                        бакиней Сулейман Мамедов.<br/>
                                        Кроме того, были аттестованы А.Ребров на 2-й Дан, И.Соколов на 3-й Дан,
                                        и М.Степин на 4-й Дан. Экзамены у всех принимал Мастер Пак Джун Тэ.<br/>

                                        За неполные два года Комиссией было проведено три всесоюзных семинара,
                                        аттестовано значительное число спортсменов из России, Украины, Латвии,
                                        Литвы, Узбекистана, Казахстана, Молдавии, Белоруссии, Грузии,
                                        Азербайджана, Армении, Абхазии, Северной Осетии, Южной Осетии, Удмуртии,
                                        Татарстана, Черкессии. Одновременно создавались центры, школы
                                        таэквон-до, организовались региональные федерации.<br/>

                                        Начиная с весны 1988 года, проводились показательные выступления сборной
                                        команды КНДР на крупнейших стадионах и совместные тренировки корейских и
                                        наших спортсменов в разных городах СССР. 3 августа 1988 года
                                        демонстрационная команда Северокорейских инструкторов таэквон-до (ИТФ)
                                        провела показательное выступление в Москве, а в 1989 году, своё
                                        мастерство, красоту и мощь таэквон-до, демонстрировали в Киеве на
                                        стадионе «Динамо» (сейчас «им. В. Лобановского») и Харькове на стадионе
                                        «Металист». Одними из основных мастеров были международный инструктор,
                                        заложивший основы школы таэквон-до (ИТФ) в России, мастер Ли Ен Сок и в
                                        Украине, мастер Хан Чол. В 1992 году они знакомили с таэквон-до жителей
                                        Эстонии.<br/>

                                        С 1 по 8 июля 1989 года в Пхеньяне, КНДР прошел Кубок Мира по таэквон-до
                                        ИТФ, который проводился в рамках 8-го Всемирного фестиваля молодежи и
                                        студентов. Впервые фестиваль прошел в азиатской стране. Фестиваль прошел
                                        под лозунгом «За антиимпериалистическую солидарность, мир и дружбу».
                                        Количество участников 22 000 человек из 177 стран.<br/>
                                        Международные соревнования по таэквон-до прошли, как официально принятый
                                        вид спортивного мероприятия фестиваля.<br/>
                                        В первые команда Советских таэквондистов приняла участие в международном
                                        турнире. В состав команды входили: харьковчане Валерий Сокол и Виталий
                                        Олин. Перед началом соревнования спортсмены участвовали в международном
                                        техническом семинаре под руководством президента Международной федерации
                                        генерала Чой Хон Хи. Семинар проводил председатель технического комитета
                                        и председатель совета инструкторов, генеральный секретарь ИТФ,
                                        обладатель 8 дана Мастер Пак Джун Тэ.<br/>

                                        01-15 октября 1989 года в г.Туапсе прошел семинар под руководством
                                        мастера Ли Ен Сок (6 дан) и Инструктора Пак Пон Док (4 дан).<br/>
                                        Корейские мастера приехали учить настоящей школе традиционного
                                        таэквон-до, по полной программе, вплоть до черных поясов.<br/>
                                        Количество участников более 120 спортсменов и тренеров из разных
                                        регионов Советского Союза таких как: Россия, Украина, Латвия, Литва,
                                        Эстония, Узбекистан, Казахстан, Молдавия, Белоруссия, Грузия,
                                        Азербайджан, Армения, Абхазия, Северной Осетия. После семинара прошла
                                        конференция, 118 делегатов создали Всесоюзную Комиссию по Таэквон-до.
                                        Приступившая к работе по созданию Федерации таэквон-до СССР, федерация
                                        была создана на следующий год и объединяла спортсменов, развивающихся
                                        как ВТФ, так и ИТФ.Южной Осетия, Удмуртия, Татарстан, Черкессия.<br/>
                                        В 1990 году в Ташкенте прошел международный семинар с аттестацией под
                                        руководством инструктора международного класса, из Северной Кореи,
                                        чемпиона мира по таэквон-до ИТФ, обладателя черного пояса 4-й дана Кан
                                        Бён Му, приехавшего в Узбекистан по приглашению Центра развития
                                        таэквон-до ИТФ (1990 – 1991 гг.).<br/>
                                        От Украины обладателями черного пояса 1-го дана стали представители
                                        харьковской школы таэквон-до ИТФ – Владимир Радионов и Юрий
                                        Загревский.<br/>

                                        Всеукраинская федерация Таэквон-до ИТФ была создана в январе 1990 года
                                        Президентом стал Игорь Пай.<br/>

                                        18 февраля 1990 года Всеукраинская федерация Таэквон-до ИТФ была одной
                                        из организаций вошедших в состав Всесоюзной федерации Таэквон-до, а её
                                        руководители, приняли участие в Учредительной Конференции, которая
                                        прошла в Юрмале. На эту Конференцию собрались практически все ведущие в
                                        СССР специалисты по таэквон-до, не только ИТФ, но и ВТФ.<br/>

                                        В апреле 1990 года в Москве прошёл Всесоюзный семинар под руководством
                                        мастера Чой Джун Хва (7-й дан), сын основателя таэквон-до генерала Чой
                                        Хон Хи. Кроме него, тренировки и аттестации проводил международный
                                        инструктор из Северной Кореи, мастер Ли Ёнг Сок (6-й дан).<br/>
                                        Из Украины на семинаре принял участие и был аттестован на
                                        1-й дан Михаил Мизин из Днепропетровска. С того времени началось
                                        активное развитие таеквон-до в г.Днепропетровске.<br/>


                                        30 июня 1990 года представители Всеукраинской федерации таэквон-до
                                        приняли участие в образовании федерации таэквон-до ИТФ в СССР. Сейчас
                                        мало, кто помнит те давние времена, и те события, которые ставят
                                        Федерацию Таэквон-до ИТФ Украины у истоков развития этого единоборства
                                        на постсоветском пространстве. В основном благодаря энтузиазму пионеров
                                        таэквон-до в Украине, принимавших активное участие во всех конференциях,
                                        соревнованиях, тренировочных сборах и семинарах, которые проводили
                                        корейские инструктора в те годы на территории СССР. Такой быстрый и
                                        стремительный рост стал возможен только лишь потому, что за плечами
                                        наших первооткрывателей корейского единоборства, уже были годы
                                        тренировок и соревновательного опыта в каратэ и других видах
                                        единоборств.<br/>
                                        Всё это создавало замечательные предпосылки для хорошего старта в
                                        освоении нового тогда еще вида единоборств - таэквон-до ИТФ.<br/>

                                        Событием стал приезд в Украину, в 1990 году, Ли Гранита (Узбекистан),
                                        чемпиона Советского Союза по таэквон-до ИТФ.<br/>

                                        Осенью 1990 года прошёл 1-й аттестационный семинар в Крыму. На 1-е даны
                                        чёрного пояса были аттестованы Владимир Егиазарян и Алексей Золотавин.
                                        Семинар проводили и принимали на пояса представители ведущей на то время
                                        в СССР Узбекской школы таэквон-до (ИТФ) Лигай В.В. (5-й дан) и Югай Н.А.
                                        (4-й дан).<br/>

                                        16-20 апреля 1990 года в городе Харькове прошел 1-й Всесоюзный турнир по
                                        Таэквон-до ИТФ.<br/>

                                        В 1990 году состоялся 1-й Чемпионат УССР в г.Суммы.<br/>

                                        Весной 1991 года в сопровождении вице-президента мастера Ри Ки Ха
                                        основатель таэквон-до ИТФ генерал Чой Хон Хи посетил Государственный
                                        центральный ордена Ленина институт физкультуры (ГЦОЛИФК) в Москве, где в
                                        течении 4-х часов он читал историческую лекцию о таэквон-до. В
                                        результате институт принял таэквон-до ИТФ в качестве официального
                                        предмета преподавания студентам.
                                        После чего в 1991 году на кафедре борьбы начато обучение студентов
                                        специализации таэквон-до ИТФ - одной из ведущих спортивных версий
                                        корейского боевого искусства, имеющей широкое международное признание,
                                        специфические правила соревнований, своеобразные методики подготовки
                                        спортсменов. В настоящее время обучение на специализации таэквон-до ИТФ
                                        ведется в соответствии с программой дисциплины «Теория и методика
                                        таэквондо (ИТФ)», включающей и традиционные, и прикладные, и спортивные
                                        аспекты.<br/>

                                        С 5 мая по 1 июля 1991 года начинает работать специальный Международный
                                        семинар инструкторов в Москве, под руководством северо-корейского
                                        мастера Ли Ен Сок (6-й дан). Во время семинара состоялась аттестация
                                        инструкторов на присвоение высоких данов.<br/>
                                        Семинар собрал более 100 участников из 15 республик Советского Союза и
                                        двух городов Москвы и Ленинграда.<br/>

                                        В 1991 году прошёл 1-й Кубок Украины в г.Николаев.<br/>

                                        В 1991 году, с целью развития таэквон-до, в Украину из Северной Кореи,
                                        был направлен международный инструктор, мастер Хан Чол (6-й дан). Уже в
                                        1992 году сборная команда Украины под руководством мастера Хан Чол
                                        завоевала свою первую бронзовую медаль (киевлянин Игорь Тарасенко по
                                        спецтехнике) на VIII-м Чемпионате Мира в Пхеньяне (КНДР).<br/>

                                        В конце 1991 года, по приглашению Крымской федерации таэквон-до ИТФ в
                                        Украину приехал международный инструктор из Северной Кореи, Чой Сан Ён
                                        (5-й дан), который преподавал таэквон-до в городе Симферополь, АР Крым с
                                        1991 года по 1992 год.<br/>

                                        В 1991-1992 годах в Харькове преподавали таэквон-до сразу несколько
                                        международных инструктора из Северной Кореи, Чон Чол Джун (5-й дан),
                                        Чонг Мун Чоль (5-й дан), Ли Кван Хёк (5-й дан), Вон Се Чанг (5-й
                                        дан).<br/>

                                        В сентябре 1991 года, по приглашению местных поклонников восточных
                                        единоборств, в город Житомир из Харькова впервые с четырёхдневным
                                        семинаром приехал международный инструктор из Северной Кореи Чонг Мун
                                        Чоль (5-й дан), заложивший основы таэквон-до ИТФ в городе с 1991 по 1992
                                        гг.<br/>

                                        В январе 1992 года в городе Днепропетровске во время проведения 2-го
                                        Чемпионата Украины состоялся очередной Президиум Федерации таэквон-до
                                        ИТФ Украины и Конференция. Присутствовали представители из разных
                                        регионов страны. Во время заседания обсуждались вопросы: отчёт
                                        Президента Всеукраинской Федерации таэквон-до ИТФ, регистрация Федерации
                                        в официальных государственных учреждениях, информация о предстоящем
                                        Чемпионате Европы в Польше и Чемпионате Мира в Северной Корее в городе
                                        Пхеньяне.<br/>
                                        Были подведены итоги прошедших соревнований: 1-го Чемпионат Украины в
                                        городе Сумы, 1-го и последнего Чемпионата СССР в городе Ленинграде и
                                        1-го Кубка Украины в городе Николаеве. Приняты решения об утверждении
                                        плана проведения мандатной комиссии, списка квалифицированных судей,
                                        которых в дальнейшем официально приглашать на чемпионат и Кубок Украины.<br/>
                                        На Президиуме была официально объявлена информация о завершении срока
                                        полномочий Президента Всеукраинской Федерации таэквон-до ИТФ Игоря
                                        Константиновича Пая. На Конференции Федерации Игорь Константинович не
                                        изъявил желания выдвигать свою кандидатуру на новый срок. Таким образом
                                        Президентом Федерации таэквон-до ИТФ Украины был избран Денис Ефимович
                                        Ли, а вице – Президентом Федерации по Международным отношениям избран
                                        Владимир Вартанович Егиазарян. На Президиуме Федерации присутствовали
                                        представители таких регионов Украины, как Харьков – Игорь Пай, Александр
                                        Попов, Олег Петровский, Владимир Дубцов. Симферополь – Владимир
                                        Егиазарян, Владислав Ким, Алексей Золотавин. Днепропетровск – Михаил
                                        Мизин. Киев – Денис Ли, Виктор Потернак, Андрей Саленко, Сергей Синенко,
                                        Владимир Прядко, Валерий Бойко, Сергей Мельник. Луганск – Виктор Блех.
                                        Чернигов (Корюковка) Анатолий Скрипка, Житомир – Сергей Галкин, Олег
                                        Довгалюк, Полтава – Олег Коваленко. Севастополь – Александр Сытник.<br/>

                                        В том же году, ввиду сложившихся обстоятельств, внеочередной
                                        Конференцией учредителей был избран исполняющий обязанности Президента
                                        Федерации Таэквон-До ИТФ Украины и на сегодняшний день является
                                        Президентом ФТУ Потернак Виктор Николаевич, Заслуженный тренер Украины,
                                        Инструктор Международного класса, судья Международной категории “А”,
                                        Мастер 8-й дана Черного пояса.<br/>

                                        В марте 1994 года Украину с семинарами впервые посетил Основатель
                                        таэквон-до генерал Чой Хон Хи. Его ассистентом был мастер Хан Чол.<br/>

                                        На сегодняшний день деятельность Федерации распростра-няется на все 24
                                        региона Украины, регулярно проводятся сорев-нования всех уровней,
                                        аттестации и технические семинары под руководством ведущих международных
                                        инструкторов.<br/>
                                        Количество занимающихся в секциях Федерации превышает 20 тысяч человек и
                                        эта цифра неуклонно растёт.<br/>
                                        За более чем 25 лет существования, Федерация Таэквон-До ИТФ Украины
                                        добилась больших, значительных успехов в развитии популярного во всем
                                        мире спорта Таэквон-До ИТФ, тем самым прославляя Государство Украина в
                                        глазах многочисленных спортсменов, тренеров и судей из большинства стран
                                        Европы, Азии и Америки. Уже стал обыденным нормой успешное выступление
                                        украинской сборной на чемпионатах Европы и Мира. Все это стало благодаря
                                        постоянной слаженной многолетней работе и и добросовестном доверительном
                                        отношении Главного управления Национальной Федерации Таэквон-До ИТФ в
                                        лице президента мастера Потернака В.Н. с тренерским советом и судейской
                                        коллегией под эгидой Главного Управления по делам молодежи и спорта
                                        Украины. В результате чего, Федерация заслуженно приобрела безсрочный
                                        Статус Национального, а спортсмены и их тренеры за свою добросовестный
                                        труд и успехи имеют очевидную возможность быть вознаграждены спортивными
                                        званиями и денежными премиями от государства.<br/>
                                        Так, с 1992 по 2017 год подготовлено более 2500 обладателей Чёрных
                                        поясов различных мастерских степеней, около 50-и Международных
                                        инструкторов с правом проведения аттестаций и технических семинаров,
                                        около 40-а Международных судей с правом судейства на международных
                                        соревнованиях, более 300 Мастеров спорта, более 40 Мастеров спорта
                                        Международного класса, 15 Заслуженных мастеров спорта, более 10
                                        Заслуженных тренеров Украины. С каждым годом по итогам успешных
                                        выступлений на Национальных и Международных соревнованиях число
                                        заслуживших высокие спортивные звания неуклонно растёт.<br/>

                                        Но, на сегодня в Украине существуют несколько организаций, которые
                                        бессовестно, пользуясь неосведомленностью населения и спортивных
                                        чиновников, используясь достижениями нашей организации, привлекая
                                        известных государственных деятелей политики, силовых структур и спорта
                                        для реализации своих целей, пытаются подменить своей деятельностью
                                        Национальной Федерацию Таэквон-До ИТФ Украины, прикрываясь
                                        несущественными искажениями в названиях самого таэквон-до с добавлением
                                        сокращенной абривиатура или наоборот неуказывая ее в уставных документах
                                        при регистрации, незначительно изменяя правила соревнований, но развивая
                                        при этом фактически тот же самый вид спорта.<br/>
                                        Таковыми организациями являются:<br/>

                                        Всеукраинская Ассоциация Спортсменов Таэквон-До И.Т.Ф. – Президент
                                        Моргошия Онисе Лотиаевич. Зарегистрирована в 2004 году, которая входит в
                                        Международную Федерацию Таеквон-до, возглавляемую Грандмастером Чой Джун
                                        Хва (Канада), который был исключён из ИТФ по причине описанной выше.<br/>
                                        Одним из основателей этой Ассоциации был Егиазарян Владимир Вартанович
                                        (г.Симферополь), который с 2004 по 2014 год являлся её действующим
                                        президентом.<br/>

                                        Федерация Чан Хун ИТФ Таэквон-До Украина – Президент Дилигул Сергей
                                        Васильевич. Зарегистрирована в 2014 году, которая входит в Международную
                                        Федерацию Таеквон-до, возглавляемую Грандмастером Фап Лу (Канада),
                                        который в 2013 году ушёл из ИТФ в отставку после того, как был обвинен в
                                        неправомерном проведении аттестации на высокие даны по необоснованного
                                        завышеным ценам.<br/>

                                        В настоящее время наиболее активной подрывной деятельностью занимается
                                        созданный в Украине Всеукраинский Союз Таэквондо. Главный иннициатор
                                        этого Союза Соловей О.Я., не сумев самостоятельно добиться своей цели,
                                        привлек к руководству своей организации высоких важных чиновников из
                                        силовых структур, которыми был генерал армии Игнатенко А.С., а сейчас
                                        подполковник милиции Зорба В.П., чтобы они лоббировали его интересы и
                                        продвигали различные решения Союза в государственных стуктуре. Целью
                                        этой деятельности является внедрение своего псевдо таэквон-до в реестр
                                        видов спорта официально развиваются в Украине, согласно получения
                                        спортивных званий и денежных премий. Получается абсурдная ситуация,
                                        государство вынуждено будет выплачивать и присваивать дважды за одно и
                                        тоже.<br/>
                                        Министерство Украины по делам семьи, молодежи и спорта внимательно
                                        рассмотрело письмо Всеукраинского Союза Таэквондо от 26.07.2010 № 64/10
                                        по поводу просьбы о признании вида спорта «таэквондо».<br/>
                                        Согласно приказу Госкомспорта Украины от 07.02.2001 № 261 «О перечне
                                        видов спорта, признании в Украине», зарегистрированного в Министерстве
                                        юстиции Украины от 22 февраля 2001 года под № 155/5346, вид спорта
                                        «Таэквондо (ИТФ)» уже признано в Украине.<br/>
                                        Учитывая вышеизложенное, повторное признание одного и того вида спорта
                                        невозможно (Письмо Министерства Украины по делам семьи, молодежи и
                                        спорта от 19.08.2010 № 62/9915 за подписью заместителя Министра
                                        А.В.Домашенко).<br/>

                                        В основе традиционных и характерных знаний таэквон-до заложена техника
                                        защиты и ударов, которые изучаются по созданному мастерами таэквон-до
                                        шаблону комплексов формальных упражнений, условно спарринг или бой с
                                        тенью (по кор. Туль). Эти формальные комплексы и является визитной
                                        карточкой и особенностью таэквон-до, что отличает его от других видов
                                        единоборств. Если две или более спортивных организации практикуют именно
                                        эти виды формальных комплексов, как основу знаний таэквон-до (ИТФ), по
                                        сути, они занимаются одним и тем же видом спортивных единоборств.
                                        Изменения в правилах соревнований, в эскизах формы одежды и даже
                                        терминологии не является законным поводом называться другим видом
                                        спорта, если в основе изучения лежат знания таэквон-до, каковыми
                                        являются комплексы формальных упражнений (туль), созданные и
                                        утвержденные основателем таэквон-до генералом Чой Хон Хи.<br/>

                                        Например, Всемирная федерация олимпийского таэквондо (ВТФ) регулярно,
                                        для повышения эффективности и зрелищности своего вида спорта, а так же
                                        большей объективности судейства на соревнованиях, вносит изменения и
                                        поправки в турнирные правила, спортивную экипировку и судейское
                                        электронное оборудование, а так же форму одежды и символику спортсменов
                                        и судей. Но, основой их отличных знаний так же есть свои комплексы
                                        формальных упражнений, в которых заложены знания выполнения техники
                                        таэквондо ВТФ.<br/>

                                        В мире есть только две разновидности таэквон-до, которые имеют
                                        распространенный интерес и большую популярность среди населения во всем
                                        мире, а также большие и значительные различия друг от друга:<br/>
                                        неолимпийский - в мире - TAEKWONDO (ITF), а в украинском реестре
                                        признанных видов спорта - ТАЭКВОНДО (ИТФ), со штаб-квартирой в Вене,
                                        Австрия, Президент Профессор Ри Йонг Сон (КНДР) и олимпийский - в мире -
                                        TAEKWONDO ( WTF), а в украинском реестре признанных видов спорта -
                                        тхэквондо (ВТФ), со штаб-квартирой в Сеуле, Южная Корея. Все другие
                                        международные организации развивают или тоже самое, занимаясь плагиатом,
                                        или псевдо таэквон-до с совсем другой техникой, правилами,
                                        терминологией, униформой и экипировка, и не имеют такого большого
                                        распространения и признание во всем мире. Их руководство прежде всего
                                        преследуют свои личные интересы путем признания со стороны.<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default History;